import React, { useEffect, useState } from "react";
import LaddaButton from "../../../components/buttons/laddaButton";
import FormTextField from "../../../components/textField/formTextField";
import ModalComponent from "../../../components/modals";
import ToastNotification from "../../../components/toastNotification";
import { Grid } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import "../styles.css";
import { callApiAsync, postPaiEvidence } from "../../../services/apiService";

yup.addMethod(yup.string, "serverOrUrl", function (message) {
  return this.test("serverOrUrl", message, function (value) {
    const { path, createError } = this;

    if (!value) {
      return createError({ path, message: "Este campo es obligatorio" });
    }

    const combinedRegex =
      /^(https?:\/\/[\w\-]+(\.[\w\-]+)+[/#?]?.*)|(^\\\\[\w.\-]+(\\[^\\/:*?"<>|]+)+$)/u;

    if (combinedRegex.test(value)) {
      return true;
    }

    return createError({
      path,
      message:
        "Debe ser un enlace válido o una ruta UNC (\\Servidor\\Carpeta\\Archivo)"
    });
  });
});

const validationSchema = yup.object().shape({
  action: yup.string().required("Este campo es obligatorio"),
  evidence: yup
    .string()
    .serverOrUrl("Por favor ingresa una URL válida")
    .required("Este campo es obligatorio")
});

const EvidenceModal = ({
  open,
  handleClose,
  data,
  getPaiData,
  evidenceData
}) => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [SelectOption, setSelectOption] = useState([]);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  useEffect(() => {
    if (open) {
      if (evidenceData) {
        loadData();
      }
      setSelectOption(getActionsAsSelectOptions(data));
    }
  }, [open]);

  const handleCloseModal = () => {
    clearData();
    reset();
    handleClose();
  };

  const loadData = () => {
    setValue("action", evidenceData.action);
    setValue("evidence", evidenceData.evidenceUrl);
  };

  const clearData = () => {
    setValue("action", "");
    setValue("evidence", "");
  };

  const updateForm = async (formData) => {
    const objRequest = {
      id: evidenceData?.id ? evidenceData.id : 0,
      competencyPaiId: data.id,
      action: formData.action,
      evidence: formData.evidence
    };

    callApiAsync(
      () => postPaiEvidence(objRequest),
      () => {
        getPaiData();
      }
    );
  };

  const onSubmit = async (formData) => {
    await updateForm(formData);
    handleClose();
  };

  const getActionsAsSelectOptions = (data) => {
    const options = [];
    const truncateText = (text, maxLength = 50) =>
      text.length > maxLength ? text.substring(0, maxLength) + "..." : text;

    if (data.action1) {
      options.push({
        value: data.action1,
        name: `Acción 1 - ${truncateText(data.action1)}`
      });
    }

    if (data.action2) {
      options.push({
        value: data.action2,
        name: `Acción 2 - ${truncateText(data.action2)}`
      });
    }

    return options;
  };

  return (
    <div>
      <ToastNotification
        severity="success"
        summary={toastMessage}
        detail=""
        position="top-center"
        showToast={showToast}
        setShowToast={setShowToast}
      />
      <ModalComponent
        open={open}
        handleClose={handleCloseModal}
        title={""}
        actions={
          <div className="HContainer j-center w-complete">
            <LaddaButton variant="contained" onClick={handleSubmit(onSubmit)}>
              <div className="HContainer align-center">
                <p className="button_text">Guardar Cambios</p>
              </div>
            </LaddaButton>
          </div>
        }
      >
        <form className="VContainer">
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12} md={12}>
              <p className="detail-label mt-25">Acciones:</p>
              <FormTextField
                name="action"
                control={control}
                isTextArea={true}
                error={errors.action}
                maxLength={500}
                rows={3}
                margin={"0"}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <p className="detail-label">Agregar URL de evidencia:</p>
              <FormTextField
                name="evidence"
                control={control}
                formLabel=""
                label=""
                error={errors.evidence}
                margin={"0"}
              />
            </Grid>
          </Grid>
        </form>
      </ModalComponent>
    </div>
  );
};

export default EvidenceModal;
