import { useMediaQuery } from "@mui/material";
import React from "react";

const PowerBiComponent = ({ infoPowerBi }) => {
  const isLargeScreen = useMediaQuery("(min-width:1200px)");
  const isMediumScreen = useMediaQuery(
    "(max-width:1200px) and (min-width:900px)"
  );
  const isSmallScreen = useMediaQuery("(max-width:590px)");

  let iframeHeight = "850px";

  if (isLargeScreen) {
    iframeHeight = "800px";
  } else if (isMediumScreen) {
    iframeHeight = "600px";
  } else if (isSmallScreen) {
    iframeHeight = "400px";
  }

  return (
    <div className="VContainer j-center">
      <iframe
        title={infoPowerBi?.title}
        width="100%"
        height={iframeHeight}
        src={infoPowerBi?.path}
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default PowerBiComponent;
