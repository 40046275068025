import React, { useState } from "react";
import LaddaButton from "../../../components/buttons/laddaButton";
import { icons } from "../../../constants";
import { treatmentAction } from "../../../constants/selectData";
import { useForm } from "react-hook-form";
import { CustomTabPanel } from "../../../components/tabComponent";
import TreatmenDetailModal from "./treatmentDetailsModal";
import { formatMonthYear } from "../../../utils/dateUtils";
import { Tooltip, styled, tooltipClasses } from "@mui/material";
import { StyledTooltip } from "../../../components/toolltipComponent";

const TreatmentDetailsTab = ({ data, getRisk, isEditionAvailable, updateRiskupdateDate }) => {
  const [treatmentDetailModal, setTreatmentDetailModal] = useState(false);

  const handleClose = () => {
    setTreatmentDetailModal(false);
  };

  const { setValue } = useForm({
    defaultValues: {},
  });

  const getRiskLevel = (levelItem) => {
    switch (levelItem) {
      case 1:
        return {
          class: "low-level",
          textClass: "low-level-text",
          text: "Nivel Bajo",
        };
      case 2:
        return {
          class: "medium-level",
          textClass: "medium-level-text",
          text: "Medio",
        };
      case 3:
        return {
          class: "high-level",
          textClass: "high-level-text",
          text: "Nivel Alto",
        };
      case 4:
        return {
          class: "high-level",
          textClass: "high-level-text",
          text: "Nivel Alto",
        };
      default:
        return {
          class: "default-level",
          textClass: "default-level-text",
          text: "Sin Nivel",
        };
    }
  };

  const getProbability = (residualRiskLevel) => {
    switch (residualRiskLevel) {
      case 1:
        return "Raro";
      case 2:
        return "Improbable";
      case 3:
        return "Posible";
      case 4:
        return "Probable";
      case 5:
        return "Casi seguro";
      default:
        return "";
    }
  };

  const getImpact = (residualRiskLevel) => {
    switch (residualRiskLevel) {
      case 1:
        return "Insignificante";
      case 2:
        return "Menor";
      case 3:
        return "Moderado";
      case 4:
        return "Mayor";
      case 5:
        return "Catastrófico";
      default:
        return "";
    }
  };

  const RiskLevelComponent = (levelItem) => {
    const {
      class: levelClass,
      textClass,
      text,
    } = getRiskLevel(levelItem.levelItem);

    return (
      <StyledTooltip
        title={
          <>
            <div className="HContainer">
              <p className="toolkit-title">Probabilidad:</p>
              <p className="toolkit-content">
                {getProbability(data.probability)}
              </p>
            </div>
            <div className="HContainer">
              <p className="toolkit-title">Impacto:</p>
              <p className="toolkit-content">{getImpact(data.impact)}</p>
            </div>
          </>
        }
        followCursor
      >
        <div className={`level ${levelClass}`}>
          <p className={textClass}>{text}</p>
        </div>
      </StyledTooltip>
    );
  };

  const openTreatmentDetailModal = () => {
    setTreatmentDetailModal(true);
    setValue("treatmentAction", data?.treatmentAction);
    setValue("activityStartDate", data?.activityStartDate);
    setValue("actionState", data?.actionState);
    setValue("responsible", data?.responsible);
  };

  return (
    <div>
      <CustomTabPanel value="1">
        <div className="header-titles">
          <div className="header-titles-item">
            <h2 className="gm-title" style={{ marginRight: "15px" }}>
              Fecha de Identificación:
            </h2>
            <p className="content-text">
              {data?.creationDate ? formatMonthYear(data?.creationDate) : ""}
            </p>
          </div>
          <div className="header-titles-item">
          </div>
          <div className="header-titles-item">
            <h2 className="gm-title" style={{ marginRight: "15px" }}>
              Valoración Global:
            </h2>
            <RiskLevelComponent levelItem={data?.riskLevel} />
          </div>
        </div>
        <h2 className="gm-title">Acción de Tratamiento:</h2>
        <p className="content-text">
          {data?.treatmentAction
            ? treatmentAction.find(
                (item) => item.value === data?.treatmentAction
              ).name
            : ""}
        </p>
        <div className="header-titles-item">
          <h2 className="gm-title">Fecha de Inicio de Actividades:</h2>
          <p className="content-text">
            {data?.activityStartDate
              ? formatMonthYear(data?.activityStartDate)
              : ""}
          </p>
        </div>
        <h2 className="gm-title">Responsable:</h2>
        <p className="content-text">{data?.responsible}</p>

        <div className="IHContainer align-center">
          <div style={{ margin: 0 }} className="tab-pill">
            Estado de la Acción:
          </div>
          <p style={{ marginLeft: "20px" }}>
            {data?.actionState == 1
              ? "Abierta"
              : data?.actionState == 2
              ? "Cerrada"
              : ""}
          </p>
        </div>

        <div className="j-center mt-20">
          {isEditionAvailable && (
            <LaddaButton
              variant="contained"
              onClick={() => {
                openTreatmentDetailModal();
              }}
            >
              <div className="IHContainer align-rigth">
                <img
                  className="btn-icon"
                  src={icons.editWhite}
                  alt="icon"
                  style={{ marginRight: "10px" }}
                />
                <p className="button_text">Editar</p>
              </div>
            </LaddaButton>
          )}
        </div>
      </CustomTabPanel>
      <TreatmenDetailModal
        open={treatmentDetailModal}
        handleClose={handleClose}
        data={data}
        getRisk={getRisk}
        updateRiskupdateDate={updateRiskupdateDate}
      />
    </div>
  );
};

export default TreatmentDetailsTab;
