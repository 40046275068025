import React, { useEffect, useState } from "react";
import LaddaButton from "../../../components/buttons/laddaButton";
import FormTextField from "../../../components/textField/formTextField";
import ModalComponent from "../../../components/modals";
import { Grid } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { YesNoOptions, riskStatus } from "../../../constants/selectData";
import FormRadioButton from "../../../components/formRadioButton";
import SimpleSelect from "../../../components/selects/simpleSelect";
import ToastNotification from "../../../components/toastNotification";
import {
  callApiAsync,
  postUpdateStatusRisk
} from "../../../services/apiService";

const validationSchema = yup.object().shape({
  actionState: yup.string().required("Este campo es obligatorio"),
  statusRisk: yup.string().required("Este campo es obligatorio"),
  evidenceResidualRisk: yup.string().required("Este campo es obligatorio")
});

const RiskStatusModal = ({
  open,
  handleClose,
  data,
  getRisk,
  isEditionAvailable,
  updateRiskupdateDate
}) => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {}
  });

  useEffect(() => {
    if (!open) {
      reset();
    }
    loadData();
  }, [open]);

  const handleCloseModal = () => {
    reset({
      actionState: data.state || "",
      statusRisk: data.statusRisk || "",
      evidenceResidualRisk: data.evidenceResidualRisk || ""
    });
    clearData();
    handleClose();
  };

  const loadData = () => {
    setValue("actionState", data?.state);
    setValue("statusRisk", data?.statusRisk);
    setValue("evidenceResidualRisk", data?.evidenceResidualRisk);
  };

  const clearData = () => {
    setValue("actionState", "");
    setValue("statusRisk", "");
    setValue("evidenceResidualRisk", "");
  };

  const updateRiskstatus = async (formData) => {
    const objRequest = {
      id: data.riskMonthId,
      state: formData.actionState,
      statusRisk: formData.statusRisk,
      evidenceResidualRisk: formData.evidenceResidualRisk
    };

    callApiAsync(
      () => postUpdateStatusRisk(objRequest),
      () => {
        updateRiskupdateDate();
        getRisk();
        isEditionAvailable();
      }
    );
  };

  useEffect(() => {
    if (data) {
      reset({
        actionState: data.state || "",
        statusRisk: data.statusRisk || "",
        evidenceResidualRisk: data.evidenceResidualRisk || ""
      });
    }
  }, [data, reset]);

  const onSubmitRiskStatusDetail = async (formData) => {
    await updateRiskstatus(formData);
    handleCloseModal();
  };

  return (
    <div>
      <ToastNotification
        severity="success"
        summary={toastMessage}
        detail=""
        position="top-center"
        showToast={showToast}
        setShowToast={setShowToast}
      />
      <ModalComponent
        open={open}
        handleClose={handleClose}
        title={""}
        width={"600px"}
        actions={
          <div className="w-complete mv-20">
            <div className="j-center">
              <LaddaButton
                variant="contained"
                onClick={handleSubmit(onSubmitRiskStatusDetail)}
              >
                <div className="HContainer align-center">
                  <p className="button_text">Guardar Cambios</p>
                </div>
              </LaddaButton>
            </div>
          </div>
        }
      >
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <p className="detail-label ml-0 mt-20">Estado del Riesgo:</p>
              <SimpleSelect
                name="actionState"
                control={control}
                hideLabel={true}
                options={riskStatus}
                margin={"0"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <p className="detail-label ml-0">Estatus del Riesgo:</p>
              <FormTextField
                name="statusRisk"
                control={control}
                error={errors.statusRisk}
                rows={1}
                margin={"0"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <p className="detail-label ml-0">Evidencia del Riesgo Residual:</p>
              <FormTextField
                name="evidenceResidualRisk"
                control={control}
                error={errors.evidenceResidualRisk}
                rows={1}
                margin={"0"}
              />
            </Grid>
          </Grid>
        </form>
      </ModalComponent>
    </div>
  );
};

export default RiskStatusModal;
