import React, { useEffect, useState } from "react";
import { bannerAdminColumns } from "./columns";
import { icons } from "../../../constants";
import SimpleTable from "../../../components/tables/simpleTable";
import EditBannerModal from "./BannerModal";
import { callApiAsync, getAdminBannerData } from "../../../services/apiService";
import { Grid, useMediaQuery } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import SimpleSelect from "../../../components/selects/simpleSelect";
import FormTextField from "../../../components/textField/formTextField";
import LaddaButton from "../../../components/buttons/laddaButton";
import { headerBannerType } from "../../../constants/enums";

const validationSchema = {};

const BannerTableAdmin = () => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);

  const isMobile = useMediaQuery("(max-width:600px)");
  const isMediumScreen = useMediaQuery("(max-width:900px)");

  const SelectOptions = [
    { value: 0, name: "Pendiente" },
    { value: 1, name: "Completada" }
  ];

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const getAdminTableBannerData = async () => {
    await callApiAsync(
      () => getAdminBannerData(),
      (response) => {
        setTableData(response);
        setFilteredTableData(response);
      }
    );
  };

  const normalizeString = (str) => {
    return str
      ? str
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
      : "";
  };

  const handleFilter = () => {
    const filters = getValues();

    const filtered = tableData.filter((banner) => {
      let moduleName;

      switch (banner.id) {
        case headerBannerType.dofa:
          moduleName = "DOFA";
          break;
        case headerBannerType.riskAndOportunity:
          moduleName = "Riesgos y Oportunidades";
          break;
        case headerBannerType.accompaniment:
          moduleName = "Acompañamiento";
          break;
        default:
          moduleName = "Módulo desconocido";
      }

      const normalizedModule = normalizeString(moduleName);
      const normalizedFilter = normalizeString(filters.module || "");

      const state = banner.state !== null ? banner.state : 0;

      return (
        (filters.module === "" ||
          normalizedModule.includes(normalizedFilter)) &&
        (filters.state === "" || state === parseInt(filters.state))
      );
    });

    setFilteredTableData(filtered);
  };
  const handleClearFilters = () => {
    reset();
    setFilteredTableData(tableData);
  };

  useEffect(() => {
    getAdminTableBannerData();
  }, []);

  return (
    <div className="App">
      <div className={`${isMediumScreen ? "w-complete" : " w-complete j-end"}`}>
        <form
          className={`HContainer mb-10  ${
            isMediumScreen ? "" : "w-50-percent"
          }`}
        >
          <Grid justifyContent="flex-end" container spacing={1}>
            <Grid item xs={12} md={4}>
              <div>
                <FormTextField
                  styledlabel={"Buscar"}
                  name="module"
                  control={control}
                  error={errors.module}
                  margin={"0"}
                />
              </div>
            </Grid>

            <Grid item xs={12} md={4}>
              <div className={`${isMobile && "mt-15"}`}>
                <SimpleSelect
                  name="state"
                  control={control}
                  label="Estatus"
                  options={SelectOptions}
                  margin={"0"}
                />
              </div>
            </Grid>

            <Grid item xs={12} md={3}>
              <div className={`IHContainer ${isMobile && "mt-10 mb-10"}`}>
                <LaddaButton
                  variant="contained"
                  onClick={handleFilter}
                  margin={"0"}
                >
                  <img
                    className="btn-icon"
                    src={icons.filterWhite}
                    alt="icon"
                  />
                </LaddaButton>
                <LaddaButton
                  variant="contained"
                  onClick={handleClearFilters}
                  margin={"0 0 0 10px"}
                >
                  <img
                    className="btn-icon"
                    src={icons.clearFilterWhite}
                    alt="icon"
                  />
                </LaddaButton>
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
      <SimpleTable
        data={filteredTableData}
        columns={bannerAdminColumns}
        text={""}
        buttons={[
          {
            type: "icon",
            icon: icons.editBlue,
            onClick: (data) => {
              setSelectedData(data);
              setOpenEditModal(true);
            }
          }
        ]}
      />

      <EditBannerModal
        open={openEditModal}
        close={() => {
          setSelectedData(null);
          setOpenEditModal(false);
        }}
        updateTable={getAdminTableBannerData}
        data={selectedData}
      ></EditBannerModal>
    </div>
  );
};

export default BannerTableAdmin;
