import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";

HighchartsMore(Highcharts);

export const columnRangeChartOptions = (
  title,
  subtitle,
  yAxisText,
  series,
  categories,
  umbral
) => {

  const allValues = series.flatMap((serie) => serie.data || []);
  const maxValue = Math.max(...allValues, umbral?.value || 0);
  const yAxisMax = maxValue > 8 ? maxValue : 8;

  return {
    chart: {
      type: "columnrange",
      inverted: true
    },

    accessibility: {
      description: ""
    },

    title: {
      text: title
    },

    subtitle: {
      text: subtitle
    },

    xAxis: {
      categories: categories,
    },
    
    yAxis: {
      min: 0,
      title: {
        text: yAxisText
      },
      plotLines: umbral != null
      ? [
          {
            color: "red",
            width: 2,
            value: umbral.value,
            zIndex: 5,
            label: {
              text: "Umbral",
              align: "right",
              x: -20,
            },
          },
        ]
      : [],
    },

    tooltip: {
      valueSuffix: ""
    },

    plotOptions: {
      columnrange: {
        borderRadius: "50%",
        dataLabels: {
          enabled: true,
          format: "{y}"
        }
      }
    },

    legend: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    series: [
      {
        name: "",
        data: series
      }
    ]
  };
};
