import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import { Calendar } from "primereact/calendar";
import { styled } from "@mui/material/styles";
import { locale, addLocale } from "primereact/api"; 


addLocale("es", {
  firstDayOfWeek: 1,
  dayNames: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
  dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
  dayNamesMin: ["D", "L", "Ma", "Mi", "J", "V", "S"],
  monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
  monthNamesShort: ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"],
  today: "Hoy",
  clear: "Limpiar"
});

const FormCalendar = ({
  name,
  control,
  label,
  defaultValue,
  width,
  onChange,
  formLabel,
  disabled,
  error,
  minDate = null,
  maxDate = null,
  margin
}) => {
  useEffect(() => {
    locale("es");
  }, []);

  const StyledCalendar = styled(({ width, error, ...props }) => (
    <Calendar {...props} baseZIndex={2000} />
  ))(() => ({
    width: width ? width : "100%",
    margin: margin ? margin : "0 10px",
    borderRadius: "6px",
    height: "40px",
    borderColor: error ? "red" : undefined,
    border: error ? "1px solid red" : `1px solid #c4c4c4`,
    "& .p-inputtext": {
      padding: "4.5px",
    }
  }));

  return (
    <div style={{ minWidth: 120 }}>
      {label ? <p className="text-form-label">{label}</p> : null}

      <Controller
          name={name}
          control={control}
          defaultValue={defaultValue || null}
          render={({ field }) => (
            <>
              <StyledCalendar
                {...field}
                value={field.value || null}
                onChange={(e) => {
                  field.onChange(e.value);
                  onChange && onChange(e.value);
                }}
                placeholder={formLabel}
                dateFormat="dd/M/yy"
                locale="es" 
                disabled={disabled}
                showIcon
                minDate={minDate || undefined}
                maxDate={maxDate || undefined}
              />
              {error && <p className="error-message">{error.message}</p>}
            </>
          )}
        />
    </div>
  );
};

export default FormCalendar;
