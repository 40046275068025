import React, { useEffect, useState } from "react";
import LaddaButton from "../../../components/buttons/laddaButton";
import FormTextField from "../../../components/textField/formTextField";
import ModalComponent from "../../../components/modals";
import { Grid } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { actionState, treatmentAction } from "../../../constants/selectData";
import FormCalendar from "../../../components/calendar/formCalendar";
import SimpleSelect from "../../../components/selects/simpleSelect";
import { parseISO } from "date-fns";
import ToastNotification from "../../../components/toastNotification";
import {
  callApiAsync,
  postUpdateRiskTreatment
} from "../../../services/apiService";

const BURN_DALTA_SELECT = [{ id: 1, name: "name" }];

const validationSchema = yup.object().shape({
  treatmentAction: yup.string().required("Este campo es obligatorio"),
  activityStartDate: yup
    .date()
    .required("Este campo es obligatorio")
    .nullable(),
  actionState: yup.string().required("Este campo es obligatorio"),
  responsible: yup.string().required("Este campo es obligatorio")
});

const TreatmenDetailModal = ({
  open,
  handleClose,
  data,
  getRisk,
  updateRiskupdateDate
}) => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {}
  });

  useEffect(() => {
    loadData();
  }, [open]);

  const handleCloseModal = () => {
    clearData();
    reset({
      treatmentAction: data.treatmentAction || "",
      activityStartDate: data.activityStartDate
        ? parseISO(data.activityStartDate)
        : null,
      actionState: data.actionState || "",
      responsible: data.responsible || ""
    });
    handleClose();
  };

  const loadData = () => {
    setValue("treatmentAction", data?.treatmentAction);
    setValue("activityStartDate", data?.benefit);
    setValue("actionState", data?.actionPlan);
    setValue("responsible", data?.responsible);
  };

  const clearData = () => {
    setValue("treatmentAction", "");
    setValue("activityStartDate", "");
    setValue("actionState", "");
    setValue("responsible", "");
  };

  const updateRiskTreatment = async (formData) => {
    const objRequest = {
      id: data.riskMonthId,
      treatmentAction: formData.treatmentAction,
      date: formData.activityStartDate,
      actionState: formData.actionState,
      responsible: formData.responsible
    };
    callApiAsync(
      () => postUpdateRiskTreatment(objRequest),
      () => {
        updateRiskupdateDate();
        getRisk();
      }
    );
  };

  useEffect(() => {
    if (data) {
      reset({
        treatmentAction: data.treatmentAction || "",
        activityStartDate: data.activityStartDate
          ? parseISO(data.activityStartDate)
          : null,
        actionState: data.actionState || "",
        responsible: data.responsible || ""
      });
    }
  }, [data, reset]);

  const onSubmitTreatmentDetail = async (formData) => {
    const activityStartDate = new Date(formData.activityStartDate);
    activityStartDate.setHours(10, 0, 0, 0);
    formData.activityStartDate = activityStartDate.toISOString();
    await updateRiskTreatment(formData);
    handleClose();
  };

  return (
    <div>
      <ToastNotification
        severity="success"
        summary={toastMessage}
        detail=""
        position="top-center"
        showToast={showToast}
        setShowToast={setShowToast}
      />
      <ModalComponent
        open={open}
        handleClose={handleCloseModal}
        title={""}
        width={"600px"}
        actions={
          <div className="w-complete mv-20">
            <div className="j-center">
              <LaddaButton
                variant="contained"
                onClick={handleSubmit(onSubmitTreatmentDetail)}
              >
                <div className="HContainer align-center">
                  <p className="button_text">Guardar Cambios</p>
                </div>
              </LaddaButton>
            </div>
          </div>
        }
      >
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <p className="detail-label ml-0 mt-20">Acción del Tratamiento:</p>
              <SimpleSelect
                name="treatmentAction"
                control={control}
                options={treatmentAction}
                error={errors.treatmentAction}
                hideLabel={true}
                margin={"0"}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <p className="detail-label ml-0">
                Fecha de Inicio de Actividades:
              </p>
              <FormCalendar
                name="activityStartDate"
                control={control}
                width={"100%"}
                formLabel=""
                error={errors.activityStartDate}
                margin={"0"}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <p className="detail-label ml-0">Estado de la Acción:</p>
              <SimpleSelect
                name="actionState"
                control={control}
                options={actionState}
                error={errors.actionState}
                hideLabel={true}
                margin={"0"}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <p className="detail-label ml-0">Responsable:</p>
              <FormTextField
                name="responsible"
                control={control}
                error={errors.responsible}
                rows={1}
                margin={"0"}
              />
            </Grid>
          </Grid>
        </form>
      </ModalComponent>
    </div>
  );
};

export default TreatmenDetailModal;
