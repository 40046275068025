import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import SimpleSignComponent from "../../../components/signComponent/simpleSignComponent";
import { acompanimentStatus as acompanimentStatusEnum } from "../../../constants/enums";
import { callApiAsync, getCallCurrentUser } from "../../../services/apiService";

const SignComponentAccompaniment = ({
  data,
  setSignature,
  signature,
  accompanimentStatus,
  selectedUser
}) => {
  let isDisabled = false;
  const [UserData, setUserData] = useState({});

  isDisabled =
    accompanimentStatus === acompanimentStatusEnum.ended ? false : true;

  const getSelectedUserData = async (id) => {
    await callApiAsync(
      () => getCallCurrentUser(id),
      (data) => {
        setUserData(data);
      }
    );
  };

  useEffect(() => {
    getSelectedUserData(selectedUser);
  }, [selectedUser]);

  return (
    <div>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} md={12}>
          <SimpleSignComponent
            setSignature={setSignature}
            signature={signature}
            hideButtons={isDisabled}
          />
          <div
            className="IHContainer align-center"
            style={{ marginTop: `${isDisabled ? "-30px" : "0px"}` }}
          >
            <p className="request-title-calendar">Nombre: </p>
            <p className="ml-10">{UserData?.nombre}</p>
          </div>
          <div
            className="IHContainer align-center"
            style={{ marginTop: "-20px" }}
          >
            <p className="request-title-calendar">Cargo: </p>
            <p className="ml-10">{UserData?.cargo}</p>
          </div>
        </Grid>
        <h1 className="request-title-calendar">Seranest Pharma LTDA</h1>
      </Grid>
    </div>
  );
};

export default SignComponentAccompaniment;
