import React, { useEffect, useState } from "react";
import PowerBiComponent from "../powerBiComponent";
import { getCurrentMonthYear, getCurrentYear } from "../../../utils/dateUtils";
import { getCookie } from "../../../utils/getCookie";
import { useLocation, useNavigate } from "react-router-dom";
import FormComponent from "../formComponent";
import {
  callApiAsync,
  getCallPowerBiById,
  getCallPowerBiProcessList,
  getCallPowerBiDetailsByIdAndUserId
} from "../../../services/apiService";

const PowerBiTab = ({ powerBiData }) => {
  const [currentTab, setCurrentTab] = useState(null);
  const [infoPowerBi, setInfoPowerBi] = useState("");

  const [infoPowerBiDetail, setInfoPowerBiDetail] = useState([]);
  const [valueTab, setValueTab] = useState(0);
  const [powerBiReport, setPowerBiReport] = useState([]);

  let { state } = useLocation();
  const navigate = useNavigate();
  const userSesion = getCookie("sesion");

  const menuItemId = powerBiData?.id;
  const currentYear = getCurrentYear();
  const currentMonth = getCurrentMonthYear();

  useEffect(() => {
    getPowerBiById();
  }, []);

  useEffect(() => {
    if (infoPowerBiDetail) {
      setCurrentTab(infoPowerBiDetail.find((x) => x.id === valueTab));
    }
  }, [infoPowerBiDetail, valueTab]);

  const getPowerBiById = async () => {
    callApiAsync(
      () => getCallPowerBiById(menuItemId),
      (data) => {
        setInfoPowerBi(data[0]);
        getPowerBiList(data[0].id);
      }
    );
  };

  const getPowerBiList = async (id) => {
    callApiAsync(
      () => getCallPowerBiProcessList(id),
      (data) => {
        setInfoPowerBiDetail(data);
      }
    );
  };

  const getPowerBiDetailsByIdAndUserId = async (month, year, valueTab) => {
    try {
      const idPowerBI = infoPowerBi && infoPowerBi.id ? infoPowerBi.id : 0;
      if (idPowerBI == 0) return;
      callApiAsync(
        () =>
          getCallPowerBiDetailsByIdAndUserId(
            idPowerBI,
            valueTab,
            userSesion.id_usuario,
            month,
            year
          ),
        (data) => {
          setPowerBiReport(data);
        }
      );
    } catch (error) {
      console.error("Error fetching Power BI details:", error);
    }
  };

  return (
    <div>
      <PowerBiComponent infoPowerBi={infoPowerBi} />
      <br className="br-white-space" />
      <FormComponent
        currentTab={currentTab}
        currentYear={currentYear}
        currentMonth={currentMonth}
        infoPowerBiDetail={infoPowerBiDetail}
        getPowerBiDetailsByIdAndUserId={getPowerBiDetailsByIdAndUserId}
        userId={userSesion.id_usuario}
        valueTab={valueTab}
        setValueTab={setValueTab}
        powerBiReport={powerBiReport}
      />
    </div>
  );
};

export default PowerBiTab;
