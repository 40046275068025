import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  previewId: null
};

const acompanimentSlice = createSlice({
  name: "acompanimentSlice",
  initialState,
  reducers: {
    setPreviewId: (state, action) => {
      state.previewId = action.payload;
    },
    resetPreviewId: (state) => {
      state.previewId = null;
    }
  }
});

export const { setPreviewId, resetPreviewId } = acompanimentSlice.actions;
export default acompanimentSlice.reducer;
