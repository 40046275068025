import React, { useEffect, useState } from "react";
import LaddaButton from "../../../components/buttons/laddaButton";
import FormTextField from "../../../components/textField/formTextField";
import ModalComponent from "../../../components/modals";
import ToastNotification from "../../../components/toastNotification";
import { Grid } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import "../styles.css";
import {
  callApiAsync,
  postUpdateRiskDetail
} from "../../../services/apiService";

const validationSchema = yup.object().shape({
  riskTitle: yup.string().required("Este campo es obligatorio"),
  description: yup.string().required("Este campo es obligatorio"),
  causes: yup.string().required("Este campo es obligatorio"),
  consequences: yup.string().required("Este campo es obligatorio")
});

const RiskDetailModal = ({
  open,
  handleClose,
  data,
  getRisk,
  updateRiskupdateDate
}) => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {}
  });

  useEffect(() => {
    loadData();
  }, [open]);

  const handleCloseModal = () => {
    clearData();
    reset();
    handleClose();
  };

  const loadData = () => {
    setValue("riskTitle", data?.title);
    setValue("description", data?.description);
    setValue("causes", data?.causes);
    setValue("consequences", data?.consequences);
  };

  const clearData = () => {
    setValue("riskTitle", "");
    setValue("description", "");
    setValue("causes", "");
    setValue("consequences", "");
  };

  const updateRiskDetail = async (formData) => {
    const objRequest = {
      id: data.riskMonthId,
      description: formData.description,
      causes: formData.causes,
      consequences: formData.consequences,
      title: formData.riskTitle
    };

    callApiAsync(
      () => postUpdateRiskDetail(objRequest),
      () => {
        updateRiskupdateDate();
        getRisk();
      }
    );
  };

  const onSubmitRiskDetail = async (formData) => {
    await updateRiskDetail(formData);
    handleClose();
  };

  return (
    <div>
      <ToastNotification
        severity="success"
        summary={toastMessage}
        detail=""
        position="top-center"
        showToast={showToast}
        setShowToast={setShowToast}
      />
      <ModalComponent
        open={open}
        handleClose={handleCloseModal}
        title={""}
        width={"600px"}
        actions={
          <div className="w-complete mv-20">
            <div className="j-center">
              <LaddaButton
                variant="contained"
                onClick={handleSubmit(onSubmitRiskDetail)}
              >
                <div className="HContainer align-center">
                  <p className="button_text">Guardar Cambios</p>
                </div>
              </LaddaButton>
            </div>
          </div>
        }
      >
        <form>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <p className="detail-label ml-0 mt-20">Titulo del Riesgo:</p>
              <FormTextField
                name="riskTitle"
                control={control}
                error={errors.riskTitle}
                maxLength={500}
                margin={"0"}
              />
            </Grid>
            <Grid item xs={12}>
              <p className="detail-label mt-25 ml-0">Descripción:</p>
              <FormTextField
                name="description"
                control={control}
                isTextArea={true}
                error={errors.description}
                maxLength={500}
                rows={2}
                margin={"0"}
              />
            </Grid>
            <Grid item xs={12}>
              <p className="detail-label ml-0">Causas:</p>
              <FormTextField
                name="causes"
                control={control}
                isTextArea={true}
                error={errors.causes}
                maxLength={500}
                rows={2}
                margin={"0"}
              />
            </Grid>
            <Grid item xs={12}>
              <p className="detail-label ml-0">Consecuencias:</p>
              <FormTextField
                name="consequences"
                control={control}
                isTextArea={true}
                error={errors.consequences}
                maxLength={500}
                rows={2}
                margin={"0"}
              />
            </Grid>
          </Grid>
        </form>
      </ModalComponent>
    </div>
  );
};

export default RiskDetailModal;
