import { useDispatch } from "react-redux";
import AcompanimentPillComponent from "../../../components/pill/acompanimentPill";
import { icons } from "../../../constants";
import { acompanimentStatus } from "../../../constants/enums";
import {
  resetPreviewId,
  setPreviewId
} from "../../../redux/slices/accompanimentSlice";
import { formatCustomDate } from "../../../services/dateService";

export const accompanimentResumePageAndMenuColumns = (navigate) => {
  return [
    {
      field: "accompaniment_date",
      header: "Fecha de realización",
      columnType: "custom",
      render: (rowData) => (
        <p className="table-default-column">
          {formatCustomDate(rowData.accompaniment_date)}
        </p>
      ),
      columnWidth: "135px"
    },
    { field: "userExecutiveName", header: "Ejecutivo" },
    { field: "userLeaderName", header: "Gerente" },
    { field: "keyAccompaniment", header: "ID", columnWidth: "20px" },
    {
      field: "actionButtons",
      header: "",
      columnType: "custom",
      render: (rowData) => (
        <div>
          {acompanimentStatus.ended === rowData.state ? (
            <div
              className="eye-btn"
              onClick={() =>
                navigate(
                  "/marketingPromotionManagement/accompaniment_Module/accompaniment",
                  { state: { ...rowData, onlyRead: true } }
                )
              }
            >
              <img className="btn-icon-table" src={icons.eye} alt="icon" />
            </div>
          ) : (
            <div
              className="eye-btn"
              onClick={() =>
                navigate(
                  "/marketingPromotionManagement/accompaniment_Module/accompaniment",
                  { state: rowData }
                )
              }
            >
              <img
                className="btn-icon-table"
                src={icons.editYellow}
                alt="icon"
              />
            </div>
          )}
        </div>
      ),
      columnWidth: "20px"
    }
  ];
};

export const acompanimentSurveyTable = [
  { field: "topic", header: "", columnWidth: "350px" },
  {
    field: "yesNo",
    header: "Si/No",
    columnType: "custom",
    render: (rowData) => {
      let yesNo =
        rowData?.presentedOnTime === 1
          ? "Si"
          : rowData?.presentedOnTime === 0
          ? "No"
          : null;
      return (
        <div className="IHContainer">
          <p className="table-default-column w-max-content">{yesNo}</p>
        </div>
      );
    },
    columnWidth: "70px"
  },
  {
    field: "weightedWeight",
    header: "Peso ponderado",
    columnType: "custom",
    render: (rowData) => {
      let color = getPillColors(rowData.weightedWeight);
      return (
        <AcompanimentPillComponent
          text={rowData.weightedWeight ? rowData.weightedWeight + "%" : ""}
          color={color.backgroundColor}
          textColor={color.textColor}
        />
      );
    }
  },
  { field: "assessment", header: "Evaluación" },
  { field: "observation", header: "Observaciones" },
  { field: "actionButtons", header: "", columnType: "actionButtons" }
];

const getPillColors = (value) => {
  if (value >= 0 && value <= 25) {
    return { backgroundColor: "#F7FEAA", textColor: "#404040" };
  } else if (value > 25 && value <= 50) {
    return { backgroundColor: "#F7FEAA", textColor: "#404040" };
  } else if (value > 50 && value <= 75) {
    return { backgroundColor: "#F7FEAA", textColor: "#404040" };
  } else if (value > 75 && value <= 100) {
    return { backgroundColor: "#F7FEAA", textColor: "white" };
  } else {
    return { backgroundColor: "#F7FEAA" };
  }
};

export const commentsSurveyTable = (dispatch) => {
  return [
    { field: "date", header: "Fecha" },
    {
      field: "observation",
      header: "Observaciones y compromiso de mejora",
      columnType: "tooltip"
    },
    {
      field: "actionButtons",
      header: "",
      columnType: "custom",
      render: (rowData) => (
        <div
          className="eye-btn"
          onClick={() => {
            dispatch(resetPreviewId());
            dispatch(setPreviewId(rowData.id));

            window.open(
              `/marketingPromotionManagement/accompaniment_Module/accompanimentPreview`,
              "_blank"
            );
          }}
        >
          <img className="btn-icon-table" src={icons.eye} alt="icon" />
        </div>
      )
    }
  ];
};
