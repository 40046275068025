import React, { useEffect, useState } from "react";
import { CustomTabPanel } from "../../../components/tabComponent";
import LaddaButton from "../../../components/buttons/laddaButton";
import SimpleTable from "../../../components/tables/simpleTable/index";
import { icons } from "../../../constants";
import {
  getCurrentMonth,
  getCurrentMonthYearWithDate,
  getMonthName
} from "../../../utils/dateUtils";
import MonthRegisterModal from "./monthRegisterModal";
import {
  callApiAsync,
  getCallRiskEvidenceList,
  getRiskActionsByRiskMonthId
} from "../../../services/apiService";
import { riskLevelState } from "../../../constants/enums";

const MonthRegisterTab = ({ data, getRisk, isEditionAvailable, updateRiskupdateDate }) => {
  const [registerPerMonthModal, setRegisterPerMonthModal] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [monthSelected, setMonthSelected] = useState("");
  const [tableData, setTableData] = useState([]);
  const [registrationData, setRegistrationData] = useState([]);

  const getRiskEvidence = async () => {
    callApiAsync(
      () => getRiskActionsByRiskMonthId(data.riskMonthId),
      (data) => {
        const updatedData = data.map((item) => ({
          ...item,
          monthName: getMonthName(item.month),
          stateName: getStateLevel(item.state),
          riskLevelName: getRiskLevel(item.risk_level)
        }));
        setTableData(updatedData);
      }
    );
  };

  const columns = [
    { field: "monthName", header: "Mes" },
    { field: "month", header: "", columnType: "hide" },
    { field: "id", header: "", columnType: "hide" },
    { field: "action", header: "Acción del Tratamiento" },
    { field: "state", header: "", columnType: "hide" },
    { field: "stateName", header: "Estado" },
    { field: "riskLevel", header: "", columnType: "hide" },
    { field: "riskLevelName", header: "Nivel de Riesgo", columnType: "pills" },
    { field: "evidence", header: "Evidencias", columnType: "url" },
    { field: "actionButtons", header: "", columnType: "actionButtons" }
  ];

  const getStateLevel = (state) => {
    switch (state) {
      case riskLevelState.materialized:
        return "Materializado";
      case riskLevelState.closedWithoutMitigation:
        return "Cerrado sin Mitigación";
      case riskLevelState.accepted:
        return "Aceptado";
      case riskLevelState.mitigated:
        return "Mitigado";
      case riskLevelState.improved:
        return "Mejorado";
      case riskLevelState.latent:
        return "Latente";
      default:
        return "";
    }
  };

  const getRiskLevel = (state) => {
    switch (state) {
      case 1:
        return "Bajo";
      case 2:
        return "Medio";
      case 3:
        return "Alto";
      default:
        return "";
    }
  };

  const handleClose = () => {
    setMonthSelected("");
    setRegistrationData([]);
    setIsEditable(false);
    setRegisterPerMonthModal(false);
    updateTable();
  };

  const openModalRegistrationModal = () => {
    setRegisterPerMonthModal(true);
  };

  const updateTable = () => {
    const updatedData = tableData.map((item) => ({
      ...item,
      monthName: getMonthName(item.month),
      stateName: getStateLevel(item.state),
      riskLevelName: getRiskLevel(item.risk_level)
    }));
    setTableData(updatedData);
  };

  useEffect(() => {
    getRiskEvidence();
  }, []);

  return (
    <div>
      <CustomTabPanel value="3">
        <div className="j-end">
          {isEditionAvailable && (
            <LaddaButton
              variant="contained"
              onClick={() => {
                setIsEditable(true);
                openModalRegistrationModal();
              }}
            >
              <div className="IHContainer align-rigth">
                <img
                  className="btn-icon"
                  src={icons.addPlus}
                  alt="icon"
                  style={{ marginRight: "10px" }}
                />
                <p className="button_text">Agregar</p>
              </div>
            </LaddaButton>
          )}
        </div>

        <SimpleTable
          text="Evaluar"
          tableStyle={{ minWidth: "870px" }}
          buttons={[
            {
              type: "iconSwitch",
              icon: icons.editBlue,
              icon2: icons.eye,
              onClick: (rowData) => {
                var isEditable = getCurrentMonthYearWithDate(
                  rowData.creation_date
                );
                setRegistrationData(rowData);
                setIsEditable(isEditable);
                setMonthSelected(rowData.monthName);
                openModalRegistrationModal();
              }
            }
          ]}
          data={tableData}
          columns={columns}
        />

        <MonthRegisterModal
          riskId={data.id}
          riskData={data}
          open={registerPerMonthModal}
          handleClose={handleClose}
          isEditable={isEditable}
          selectedData={registrationData}
          monthSelected={
            monthSelected == ""
              ? getMonthName(getCurrentMonth())
              : monthSelected
          }
          registrationData={registrationData}
          getRisk={getRisk}
          getRiskEvidence={getRiskEvidence}
          updateRiskupdateDate={updateRiskupdateDate}
        />
      </CustomTabPanel>
    </div>
  );
};

export default MonthRegisterTab;
