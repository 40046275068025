import React, { useEffect, useState } from "react";
import { CustomTabPanel } from "../../../components/tabComponent";
import ActionPaiModal from "../firstActionTab/actionModal";
import LaddaButton from "../../../components/buttons/laddaButton";
import { icons } from "../../../constants";
import { formatTextWithLineBreaks } from "../../../utils/utilsFunctions";
import SimpleTable from "../../../components/tables/simpleTable";
import EvidenceModal from "./evidenceModal";
import { callApiAsync, getPaiEvidencebyId } from "../../../services/apiService";

const EvidenceTab = ({ data, getPaiData }) => {
  const [openModal, setOpenModal] = useState(false);
  const [table, setTable] = useState([]);
  const [evidenceData, setEvidenceData] = useState({});

  const handleClose = () => {
    setOpenModal(false);
    setEvidenceData({});
    loadTable();
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const tableColumns = [
    { field: "date", header: "Fecha" },
    { field: "action", header: "Acción o actividad" },
    { field: "evidenceUrl", header: "Evidencia", columnType: "url" },
    { field: "actions", header: "", columnType: "actionButtons" }
  ];

  const loadTable = async () => {
    await callApiAsync(
      () => getPaiEvidencebyId(data.id),
      (data) => {
        setTable(data);
      }
    );
  };

  useEffect(() => {
    loadTable();
  }, []);

  return (
    <div>
      <CustomTabPanel value="4">
        <div className="j-end">
          <LaddaButton variant="contained" onClick={() => handleOpenModal()} width={'110px'}>
            <div className="IHContainer align-center">
              <img className="btn-icon" src={icons.addPlus} alt="icon" />
              <p className="button_text ml-5">Agregar</p>
            </div>
          </LaddaButton>
        </div>
        <div>
          <SimpleTable
            data={table}
            columns={tableColumns}
            buttons={[
              {
                type: "dateHideButton",
                icon: icons.editBlue,
                onClick: (data) => {
                  setEvidenceData(data);
                  handleOpenModal();
                }
              }
            ]}
          />
        </div>
      </CustomTabPanel>
      <EvidenceModal
        open={openModal}
        handleClose={handleClose}
        data={data}
        evidenceData={evidenceData}
        getPaiData={getPaiData}
      />
    </div>
  );
};

export default EvidenceTab;
