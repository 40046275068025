import React, { useEffect, useRef, useState } from "react";
import BreadCrumbComp from "../../../components/breadCrumb";
import HeaderBanner from "../../../components/headerBanner";
import { acompanimentStatus, headerBannerType } from "../../../constants/enums";
import SimpleSelect from "../../../components/selects/simpleSelect";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Grid } from "@mui/material";
import FormTextField from "../../../components/textField/formTextField";
import FormCalendar from "../../../components/calendar/formCalendar";
import CharacterCounter from "../../../components/characterCounter";
import LaddaButton from "../../../components/buttons/laddaButton";
import { icons } from "../../../constants";
import { useNavigate } from "react-router-dom";
import SurveyTable from "./surveyTable";
import FormRadioButton from "../../../components/formRadioButton";
import { YesNoOptions } from "../../../constants/selectData";
import SignComponentAccompaniment from "./signComponent";
import {
  getAccompanimentBusinessSelect,
  getAccompanimentSelectsData,
  getPlannedOrganizedTable,
  getPromotionalAspectsTable,
  getSpinTechniqueTable,
  saveAccompaniment,
  savePlannedOrganized,
  savePromotionalAspects,
  saveSpinTechnique
} from "./acompanimentCreationApiCall";
import {
  callApiAsync,
  getAccompaniment,
  getPriorCommitment
} from "../../../services/apiService";
import CommentModal from "./commentModal";
import ToastNotification from "../../../components/toastNotification";

const validationSchema = yup.object().shape({
  userExecutiveId: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("El ejecutivo es obligatorio"),
  userLeaderId: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("El líder es obligatorio"),
  cityId: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("La ciudad es obligatoria"),
  medicalPanel: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("El panel médico es obligatorio"),
  doctor_Visited_Platform: yup
    .string()
    .required("El médico visitado es obligatorio"),
  visitNextCycle: yup
    .boolean()
    .typeError("El campo debe ser si o no")
    .required("Este campo es obligatorio"),
  observation: yup.string().required("Las observaciones son obligatorias"),
  businessLineId: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("La línea de negocio es obligatoria")
});

const AccompanimentPage = ({ onlyRead, accompanimentItemId }) => {
  const navigate = useNavigate();

  const [selectsList, setSelectsList] = useState([]);
  const [businessLineSelect, setBusinessLineSelect] = useState([]);
  const [signatureA, setSignatureA] = useState(null);
  const [signatureB, setSignatureB] = useState(null);
  const [openCommentModal, setOpenCommentModal] = useState(false);
  const [PlannedOrganizedTable, setPlannedOrganizedTablet] = useState([]);
  const [PromotionalAspectsTable, setPromotionalAspectsTable] = useState([]);
  const [SpinTechniqueTable, setSpinTechniqueTable] = useState([]);
  const [commentTable, setCommentTable] = useState([{ observation: "" }]);
  const [accompanimentStatus, setAccompanimentStatus] = useState(null);
  const [keyAccompaniment, setKeyAccompaniment] = useState(null);
  const [accompanimentId, setAccompanimentId] = useState(
    accompanimentItemId || 0
  );
  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    severity: "warning",
    summary: "",
    detail: ""
  });
  const [firstReloadFlag, setFirstReloadFlag] = useState(true);
  const [executiveFlag, setExecutiveFlag] = useState(null);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    reset,
    trigger,
    watch,
    clearErrors
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const timeoutRef = useRef(null);

  const debouncedClearErrors = (fieldName) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      clearErrors(fieldName);
    }, 200);
  };

  const handleChange = (fieldName) => {
    debouncedClearErrors(fieldName);
  };

  const watchUserExecutiveId = watch("userExecutiveId");
  const watchAccompanimentDate = watch("accompanimentDate");
  const watchUserLeaderId = watch("userLeaderId");
  const watchBusinessLineId = watch("businessLineId");

  const handleOpenCommentModal = () => {
    if (
      watchUserExecutiveId === undefined ||
      watchUserExecutiveId === null ||
      watchUserExecutiveId === "" ||
      watchBusinessLineId === undefined ||
      watchBusinessLineId === null ||
      watchBusinessLineId === ""
    ) {
      setToastConfig({
        severity: "warn",
        summary: "Compromiso previo",
        detail: `Debe tener seleccionado ejecutivo y línea de negocio.`
      });
      setShowToast(true);
      return;
    }
    setOpenCommentModal(true);
  };

  const checkTable = (dataList, tableName) => {
    const missingTopics = dataList
      .filter((item) => item.presentedOnTime === null)
      .map((item) => item.topic);

    if (missingTopics.length > 0) {
      setToastConfig({
        severity: "error",
        summary: "Tabla incompleta.",
        detail: `Hacen falta preguntas de la tabla "${tableName}".`
      });
      setShowToast(true);
      return false;
    }
    return true;
  };

  const handleSaveProgress = () => {
    const accompanimentDateValue = getValues().accompanimentDate;
    const formattedDate = accompanimentDateValue
      .toLocaleString("sv-SE")
      .replace("T", " ");

    const formData = {
      businessLineId:
        getValues().businessLineId === "" ? null : getValues().businessLineId,
      userExecutiveId:
        getValues().userExecutiveId === "" ? null : getValues().userExecutiveId,
      userLeaderId:
        getValues().userLeaderId === "" ? null : getValues().userLeaderId,
      cityId: getValues().cityId === "" ? null : getValues().cityId,
      medicalPanel:
        getValues().medicalPanel === "" ? null : getValues().medicalPanel,
      doctor_Visited_Platform:
        getValues().doctor_Visited_Platform === ""
          ? null
          : getValues().doctor_Visited_Platform,
      observation:
        getValues().observation === "" ? null : getValues().observation,
      accompanimentDate: formattedDate,
      sign1: signatureA,
      sign2: signatureB,
      accompanimentId: accompanimentId,
      state: acompanimentStatus.inProcess,
      visitNextCycle:
        getValues().visitNextCycle === true
          ? 1
          : getValues().visitNextCycle === false
          ? 0
          : null
    };
    saveAccompaniment(formData, navigate);
  };

  const handleFinishAccompaniment = async () => {
    const isValid = await trigger();

    if (!isValid) {
      setToastConfig({
        severity: "error",
        summary: "Errores en el formulario",
        detail: "Hacen falta datos por ingresar del formulario."
      });
      setShowToast(true);
      return;
    }

    if (!signatureA) {
      setToastConfig({
        severity: "error",
        summary: "Firma requerida",
        detail: "Es obligatoria la firma del ejecutivo(a)."
      });
      setShowToast(true);
      return;
    }

    if (!signatureB) {
      setToastConfig({
        severity: "error",
        summary: "Firma requerida",
        detail: "Es obligatoria la firma de líder que acompaña."
      });
      setShowToast(true);
      return;
    }

    const isPlannedOrganizedComplete = checkTable(
      PlannedOrganizedTable,
      "Planeando y organizando"
    );
    const isPromotionalAspectsComplete = checkTable(
      PromotionalAspectsTable,
      "Aspectos promocionales"
    );
    const isSpinTechniqueComplete = checkTable(
      SpinTechniqueTable,
      "Uso la técnica Spin adecuadamente"
    );

    if (
      !isPlannedOrganizedComplete ||
      !isPromotionalAspectsComplete ||
      !isSpinTechniqueComplete
    ) {
      return;
    }

    const accompanimentDateValue = getValues().accompanimentDate;
    const formattedDate = accompanimentDateValue
      .toLocaleString("sv-SE")
      .replace("T", " ");

    const formData = {
      businessLineId: getValues().businessLineId,
      userExecutiveId: getValues().userExecutiveId,
      userLeaderId: getValues().userLeaderId,
      cityId: getValues().cityId,
      medicalPanel: getValues().medicalPanel,
      doctor_Visited_Platform: getValues().doctor_Visited_Platform,
      observation: getValues().observation,
      accompanimentDate: formattedDate,
      sign1: signatureA,
      sign2: signatureB,
      accompanimentId: accompanimentId,
      state: acompanimentStatus.ended,
      visitNextCycle:
        getValues().visitNextCycle === true
          ? 1
          : getValues().visitNextCycle === false
          ? 0
          : null
    };
    saveAccompaniment(formData, navigate);
  };

  const getAccompanimentData = async (id) => {
    await callApiAsync(
      () => getAccompaniment(id),
      (data) => {
        const dataItem = data[0];

        setAccompanimentStatus(dataItem.state);
        setKeyAccompaniment(dataItem.keyAccompaniment);
        setValue("userExecutiveId", dataItem.userExecutiveId);
        setExecutiveFlag(dataItem.userExecutiveId);
        setFirstReloadFlag(true);
        setValue("userLeaderId", dataItem.userLeaderId);
        setValue("cityId", dataItem.cityId);
        setValue(
          "visitNextCycle",
          dataItem.visitNextCycle === 1
            ? true
            : dataItem.visitNextCycle === 0
            ? false
            : null
        );

        setValue("medicalPanel", dataItem.medicalPanel);
        setValue("businessLineId", dataItem.businessLineId);
        setValue("doctor_Visited_Platform", dataItem.doctor_Visited_Platform);
        setValue("observation", dataItem.observations);

        if (dataItem.state === acompanimentStatus.ended) {
          const dateValue = dataItem.accompanimentDate
            ? new Date(dataItem.accompanimentDate)
            : null;

          setValue("accompanimentDate", dateValue);
        }

        setSignatureA(dataItem.sign1);
        setSignatureB(dataItem.sign2);
      }
    );
  };

  const getCommentTableData = async () => {
    const dataToSend = {
      lineId: watchBusinessLineId,
      userId: watchUserExecutiveId,
      accompanimentId: accompanimentId
    };

    await callApiAsync(
      () =>
        getPriorCommitment(
          dataToSend.userId,
          dataToSend.lineId,
          dataToSend.accompanimentId
        ),
      (data) => {
        setCommentTable(data.lastCommitment);
      }
    );
  };

  useEffect(() => {
    if (accompanimentId) {
      getAccompanimentData(accompanimentId);
    }
    getCommentTableData();
  }, []);

  useEffect(() => {
    Promise.all([
      getAccompanimentSelectsData(setSelectsList),
      getPromotionalAspectsTable(accompanimentId, setPromotionalAspectsTable),
      getPlannedOrganizedTable(accompanimentId, setPlannedOrganizedTablet),
      getSpinTechniqueTable(accompanimentId, setSpinTechniqueTable)
    ]).catch((error) => console.error("Error al cargar datos:", error));
  }, [accompanimentId]);

  useEffect(() => {
    if (watchUserExecutiveId) {
      Promise.all([
        getAccompanimentBusinessSelect(
          watchUserExecutiveId,
          setBusinessLineSelect
        )
      ]).catch((error) => console.error("Error al cargar datos:", error));
    }
  }, [watchUserExecutiveId]);

  useEffect(() => {
    if (watchBusinessLineId === null || watchUserExecutiveId == null) return;
    getCommentTableData();
  }, [watchBusinessLineId]);

  useEffect(() => {
    if (firstReloadFlag == true && watchUserExecutiveId == executiveFlag) {
      setFirstReloadFlag(false);
      return;
    }
    setValue("businessLineId", null);
    setCommentTable([{ observation: "" }]);
  }, [watchUserExecutiveId]);

  return (
    <div className="App">
      <BreadCrumbComp />
      <HeaderBanner type={headerBannerType.accompaniment} />

      <div className="searchTitle">
        {keyAccompaniment ? (
          <h1>{`ACOMPAÑAMIENTO Y MEJORA - ID ${keyAccompaniment}`}</h1>
        ) : (
          <h1>CREAR NUEVO ACOMPAÑAMIENTO Y MEJORA </h1>
        )}
      </div>

      <div className="table-container">
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <p className="create-label ml-0">Nombre del ejecutivo(a):</p>
              <SimpleSelect
                name="userExecutiveId"
                control={control}
                options={selectsList?.executiveList || []}
                hideLabel={true}
                onChange={() => {
                  clearErrors("userExecutiveId");
                }}
                error={errors.userExecutiveId}
                disabled={onlyRead}
                margin={"0"}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <p className="create-label ml-0">Linea de negocio:</p>
              <SimpleSelect
                name="businessLineId"
                control={control}
                options={businessLineSelect || []}
                hideLabel={true}
                error={errors.businessLineId}
                onChange={() => {
                  clearErrors("businessLineId");
                }}
                disabled={onlyRead}
                margin={"0"}
              />
            </Grid>

            {commentTable[0].observation.length > 0 && (
              <Grid item xs={12} md={3}>
                <div className="align-end" style={{ height: "84px" }}>
                  <LaddaButton
                    variant="contained"
                    height={"40px"}
                    onClick={() => handleOpenCommentModal()}
                  >
                    <div className="HContainer align-center">
                      <p className="button_text">Compromiso previo</p>
                    </div>
                  </LaddaButton>
                </div>
              </Grid>
            )}

            <Grid item xs={12} md={4}>
              <p className="create-label ml-0">Líder que acompaña:</p>
              <SimpleSelect
                name="userLeaderId"
                control={control}
                options={selectsList?.leaderList || []}
                hideLabel={true}
                onChange={() => clearErrors("userLeaderId")}
                error={errors.userLeaderId}
                disabled={onlyRead}
                margin={"0"}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <p className="create-label ml-0">Panel médico asignado:</p>
              <FormTextField
                name="medicalPanel"
                control={control}
                formLabel=""
                label=""
                error={errors.medicalPanel}
                disabled={onlyRead}
                onBlur={() => handleChange("medicalPanel")}
                margin={"0"}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <p className="create-label ml-0">Fecha:</p>
              <FormCalendar
                name="accompanimentDate"
                control={control}
                width={"100%"}
                formLabel=""
                error={errors.accompanimentDate}
                disabled={true}
                defaultValue={new Date()}
                margin={"0"}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <p className="create-label ml-0">Ciudad:</p>
              <SimpleSelect
                name="cityId"
                control={control}
                options={selectsList?.cityList || []}
                hideLabel={true}
                onChange={() => clearErrors("cityId")}
                error={errors.cityId}
                disabled={onlyRead}
                margin={"0"}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <div>
                <p className="create-label ml-0">
                  Médicos visitados y Plataforma:
                </p>
                <div>
                  <CharacterCounter
                    name={"doctor_Visited_Platform"}
                    control={control}
                    maxLength={1000}
                  />
                </div>
                {/* <div> */}
                <FormTextField
                  name={"doctor_Visited_Platform"}
                  control={control}
                  isTextArea={true}
                  error={errors.doctor_Visited_Platform}
                  maxLength={1000}
                  rows={4}
                  disabled={onlyRead}
                  onBlur={() => handleChange("doctor_Visited_Platform")}
                  margin={"0"}
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <p
                className="create-label ml-0"
                style={{ margin: "20px 0 5px 10px" }}
              >
                ¿Planeó la visita del próximo ciclo?
              </p>
              <div
                style={{ marginTop: "20px", marginLeft: "5px", width: "100%%" }}
              >
                <FormRadioButton
                  name="visitNextCycle"
                  control={control}
                  onChange={() => clearErrors("visitNextCycle")}
                  options={YesNoOptions}
                  error={errors.visitNextCycle}
                  disabled={onlyRead}
                  margin={"0"}
                />
              </div>
            </Grid>
          </Grid>
          <h1 class="title-bio-details-chard">Power BI</h1>
          <div className="HContainer">
            <LaddaButton
              fontSize={"13px"}
              height={"32px"}
              variant={"outlined"}
              backgroundColor={"#FFFF"}
              color={"#6A6AD8"}
              borderColor={"#6A6AD8"}
              onClick={() =>
                window.open(
                  `/marketingPromotionManagement?powerBi=3`,
                  "_blank",
                  "noopener,noreferrer"
                )
              }
            >
              <img
                className="btn-material-icon"
                src={icons.linkBlue}
                alt="icon"
              />
              <p className="button_text ml-10">{"Plataforma médicos"}</p>
            </LaddaButton>

            <LaddaButton
              fontSize={"13px"}
              height={"32px"}
              variant={"outlined"}
              backgroundColor={"#FFFF"}
              color={"#6A6AD8"}
              borderColor={"#6A6AD8"}
              onClick={() =>
                window.open(
                  `/marketingPromotionManagement?powerBi=1`,
                  "_blank",
                  "noopener,noreferrer"
                )
              }
            >
              <img
                className="btn-material-icon"
                src={icons.linkBlue}
                alt="icon"
              />
              <p className="button_text ml-10">{"Urología"}</p>
            </LaddaButton>
          </div>

          <h1 class="title-bio-details-chard">Planeando y organizando</h1>
          <SurveyTable
            data={PlannedOrganizedTable}
            editable={true}
            total={100}
            accompanimentStatus={accompanimentStatus}
            getTable={getPlannedOrganizedTable}
            saveData={savePlannedOrganized}
            setTable={setPlannedOrganizedTablet}
            accompanimentDate={watchAccompanimentDate}
            accompanimentId={accompanimentId}
            setAccompanimentId={setAccompanimentId}
          />
          <h1 class="title-bio-details-chard">Aspectos promocionales</h1>
          <SurveyTable
            data={PromotionalAspectsTable}
            onlyRead={onlyRead}
            total={30}
            accompanimentStatus={accompanimentStatus}
            getTable={getPromotionalAspectsTable}
            saveData={savePromotionalAspects}
            setTable={setPromotionalAspectsTable}
            accompanimentDate={watchAccompanimentDate}
            accompanimentId={accompanimentId}
            setAccompanimentId={setAccompanimentId}
          />
          <h1 class="title-bio-details-chard">
            Uso la técnica Spin adecuadamente
          </h1>
          <SurveyTable
            data={SpinTechniqueTable}
            onlyRead={onlyRead}
            total={50}
            accompanimentStatus={accompanimentStatus}
            getTable={getSpinTechniqueTable}
            saveData={saveSpinTechnique}
            setTable={setSpinTechniqueTable}
            accompanimentDate={watchAccompanimentDate}
            accompanimentId={accompanimentId}
            setAccompanimentId={setAccompanimentId}
          />
          <Grid item xs={12} md={12}>
            <div>
              <p className="create-label ml-0">
                Observaciones y compromiso de mejora:
              </p>
              <div>
                <CharacterCounter
                  name={"observation"}
                  control={control}
                  maxLength={500}
                />
              </div>
              {/* <div> */}
              <FormTextField
                name={"observation"}
                control={control}
                isTextArea={true}
                error={errors.observation}
                maxLength={500}
                rows={4}
                disabled={onlyRead}
                onBlur={() => handleChange("observation")}
                margin={"0"}
              />
            </div>
          </Grid>
          <div className="mt-20">
            <Grid container>
              <Grid item xs={12} md={6}>
                <SignComponentAccompaniment
                  setSignature={setSignatureA}
                  signature={signatureA}
                  data={{ id: 1, name: "Juan Perez", charge: "Representante" }}
                  accompanimentStatus={accompanimentStatus}
                  selectedUser={watchUserExecutiveId}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SignComponentAccompaniment
                  setSignature={setSignatureB}
                  signature={signatureB}
                  data={{ id: 1, name: "Javier Fernández", charge: "Gerente" }}
                  accompanimentStatus={accompanimentStatus}
                  selectedUser={watchUserLeaderId}
                />
              </Grid>
            </Grid>
          </div>

          {accompanimentStatus !== acompanimentStatus.ended && (
            <div className="HContainer j-center w-complete mt-40">
              <LaddaButton
                variant="contained"
                height={"60px"}
                onClick={handleSaveProgress}
              >
                <div className="HContainer align-center">
                  <p className="button_text">Guardar progreso</p>
                </div>
              </LaddaButton>

              <LaddaButton
                variant="contained"
                height={"60px"}
                onClick={handleFinishAccompaniment}
              >
                <div className="HContainer align-center">
                  <p className="button_text">Finalizar Acompañamiento</p>
                </div>
              </LaddaButton>
            </div>
          )}
        </form>
      </div>

      <CommentModal
        tableData={commentTable}
        open={openCommentModal}
        handleClose={() => setOpenCommentModal(false)}
        lineId={watchBusinessLineId}
        userExecutiveId={watchUserExecutiveId}
        accompanimentId={accompanimentId}
      />

      <ToastNotification
        severity={toastConfig.severity}
        summary={toastConfig.summary}
        detail={toastConfig.detail}
        showToast={showToast}
        setShowToast={setShowToast}
        position="top-right"
      />
    </div>
  );
};

export default AccompanimentPage;
