import React, { useEffect, useState } from "react";
import BreadCrumbComp from "../../components/breadCrumb";
import SimpleSelect from "../../components/selects/simpleSelect";
import { months } from "../../constants/selectData";

import { useForm } from "react-hook-form";
import { icons } from "../../constants";
import SimpleTable from "../../components/tables/simpleTable";
import { useLocation } from "react-router-dom";
import { getDateObj } from "../../utils/dateUtils";
import { getCookie } from "../../utils/getCookie";
import "./styles.css";
import { useSelector } from "react-redux";
import { callApiAsync, getCallRisksList } from "../../services/apiService";
import { actualYearSelect } from "../../services/dateService";
import HeaderBanner from "../../components/headerBanner";
import { headerBannerType } from "../../constants/enums";
import { Grid, useMediaQuery } from "@mui/material";

const RiskAdminGestion = () => {
  const [tableData, setTableData] = useState([]);
  const userSesion = getCookie("sesion");
  const userId = userSesion.id_usuario;
  const { control, getValues, setValue } = useForm();
  const isSmallScreen = useMediaQuery("(max-width:590px)");
  const isLargeScreen = useMediaQuery("(min-width:1200px)");
  const location = useLocation();
  const renderData = useSelector(
    (state) => state.breadCrumbData.breadCrumbData
  );

  const { rowData } = location.state || renderData || {};

  const handleChange = () => {
    const formData = getValues();
    getRisksList(formData.year, formData.month);
  };

  const columns = [
    { field: "riskNumber", header: "Nro de Riesgo", columnType: "titles" },
    { field: "title", header: "Título", columnType: "titles" },
    { field: "description", header: "Descripción", columnType: "tooltip" },
    { field: "process", header: "", columnType: "hide" },
    { field: "processName", header: "Proceso al que pertenece" },
    { field: "levelRisk", header: "", columnType: "hide" },
    { field: "riskLevelName", header: "Nivel de Riesgo", columnType: "pills" },
    { field: "rmId", header: "rmId", columnType: "hide" },
    { field: "actions", header: "", columnType: "actionButtons" }
  ];

  const getRisksList = async (year, month) => {
    callApiAsync(
      () => getCallRisksList(userId, year, month, rowData.processId),
      (data) => {
        const updatedData = data.map((item) => ({
          ...item,
          riskLevelName: getRiskLevel(item.levelRisk)
        }));
        setTableData(updatedData);
      }
    );
  };

  const getRiskLevel = (state) => {
    switch (state) {
      case 1:
        return "Bajo";
      case 2:
        return "Medio";
      case 3:
        return "Alto";
      default:
        return "";
    }
  };

  useEffect(() => {
    setValue("month", rowData ? rowData.month : getDateObj().month);
    setValue("year", rowData ? rowData.year : getDateObj().year);
    getRisksList(rowData.year, rowData.month);
  }, []);

  const ToolTipDescription = (text) => {
    return (
      <div>
        <p className="tooltip-title">Descripción:</p>
        <div>
          <p className="tooltip-text">{text}</p>
        </div>
      </div>
    );
  };

  return (
    <div>
      <BreadCrumbComp />
      <HeaderBanner type={headerBannerType.riskAndOportunity} />
      <h1
        className="gm-header-title-grey mb-25"
        style={{ marginRight: "15px" }}
      >
        RIESGOS - {rowData.processName}
      </h1>
      <div className={`w-complete ${isLargeScreen ? "j-end" : ""}`}>
        <form className={`${isLargeScreen ? "w-50-percent" : ""}`}>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12} md={6}>
              <SimpleSelect
                name="month"
                control={control}
                label="Mes"
                options={months}
                onChange={handleChange}
                margin={"0"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SimpleSelect
                name="year"
                control={control}
                label="Año"
                options={actualYearSelect}
                onChange={handleChange}
                margin={"0"}
              />
            </Grid>
          </Grid>
        </form>
      </div>
      <div className="simple-table-container mt-10">
        <SimpleTable
          data={tableData}
          columns={columns}
          tableStyle={{ minWidth: "100%" }}
          buttons={[
            {
              type: "redirectData",
              icon: icons.eye,
              url: "/riskAndOpportunityManagement/riskAdminGestion/riskDetails"
            }
          ]}
          tooltipFragment={ToolTipDescription}
        />
      </div>
    </div>
  );
};

export default RiskAdminGestion;
