import { getYear, subYears, format, parseISO } from "date-fns";

const monthsMini = [
  "ENE",
  "FEB",
  "MAR",
  "ABR",
  "MAY",
  "JUN",
  "JUL",
  "AGO",
  "SEP",
  "OCT",
  "NOV",
  "DIC",
];

const currentYear = getYear(new Date());

export const actualYearSelect = Array.from({ length: 6 }, (_, index) => {
  const year = getYear(subYears(new Date(), index));
  return { name: year.toString(), value: year };
});

export function formatWithoutHour(fechaCompleta) {
  if (!fechaCompleta) return;

  const fecha = parseISO(fechaCompleta);
  const fechaSinHora = format(fecha, "yyyy-MM-dd");

  return fechaSinHora;
}

export function formatCustomDate(fechaCompleta) {
  if (!fechaCompleta) return "";

  const fecha = parseISO(fechaCompleta);
  const day = format(fecha, "dd"); 
  const monthIndex = format(fecha, "MM") - 1; 
  const year = format(fecha, "yyyy"); 

  return `${day}/${monthsMini[monthIndex]}/${year}`;
}