import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import FormTextField from "../../../components/textField/formTextField";
import ModalComponent from "../../../components/modals";
import { Grid } from "@mui/material";
import CharacterCounter from "../../../components/characterCounter";
import FormRadioButton from "../../../components/formRadioButton";
import { YesNoOptions } from "../../../constants/selectData";
import LaddaButton from "../../../components/buttons/laddaButton";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { acompanimentStatus } from "../../../constants/enums";

const SurveyTableModal = ({
  open,
  handleClose,
  selectedData = {},
  onlyRead = false,
  setTable,
  getTable,
  accompanimentDate,
  accompanimentId,
  saveData,
  setAccompanimentId,
  accompanimentStatusData
}) => {
  const validationSchema = yup.object().shape({
    answer: yup
      .mixed()
      .oneOf(["true", "false", true, false], "Debe seleccionar una respuesta")
      .required("Debe seleccionar una respuesta"),

    assessment: yup.string().when("answer", {
      is: (val) => val === "false" || val === false,
      then: (schema) => schema.required("La Evaluación es obligatoria"),
      otherwise: (schema) => schema.notRequired()
    }),

    observation: yup.string().when("answer", {
      is: (val) => val === "false" || val === false,
      then: (schema) => schema.required("Las Observaciones son obligatorias"),
      otherwise: (schema) => schema.notRequired()
    })
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const answerValue = watch("answer");

  const closeModal = () => {
    reset();
    handleClose();
  };

  const statusEnd = accompanimentStatusData === acompanimentStatus.ended;

  const onSubmit = (formData) => {

    const dataToSend = {
      topicId: selectedData.id,
      idRelation: selectedData.idRelation,
      presentedOnTime: answerValue,
      accompanimentId,
      accompanimentDate,
      ...formData
    };

    saveData(dataToSend, setAccompanimentId, getTable, setTable);
    closeModal();
  };

  useEffect(() => {
    if (selectedData) {
      setValue("assessment", selectedData.assessment || "");
      setValue("observation", selectedData.observation || "");
      setValue(
        "answer",
        selectedData.presentedOnTime === 0
          ? false
          : selectedData.presentedOnTime === 1
          ? true
          : ""
      );
    } else {
      setValue("assessment", "");
      setValue("observation", "");
      setValue("presentedOnTime", "");
    }
  }, [selectedData]);

  return (
    <ModalComponent
      actions={[]}
      open={open}
      handleClose={closeModal}
      title={""}
    >
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <p className="create-label ml-0 mt-25">
              {selectedData?.topic}
            </p>
            <div
              className="mt-25"
            >
              <FormRadioButton
                name="answer"
                control={control}
                // label="Eficaz:"
                options={YesNoOptions}
                error={errors.answer}
                disabled={statusEnd}
                margin={'0'}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <p className="create-label ml-0">
              Evaluación:
            </p>
            <div >
              <CharacterCounter
                name={"assessment"}
                control={control}
                maxLength={200}
              />
            </div>
            <FormTextField
              name="assessment"
              control={control}
              isTextArea={true}
              error={errors.assessment}
              maxLength={200}
              rows={2}
              disabled={statusEnd}
              margin={'0'}
            />
          </Grid>
          <Grid item xs={12}>
            <p className="create-label ml-0">
              Observaciones:
            </p>
            <div >
              <CharacterCounter
                name={"observation"}
                control={control}
                maxLength={200}
              />
            </div>
            <FormTextField
              name="observation"
              control={control}
              isTextArea={true}
              error={errors.observation}
              maxLength={200}
              rows={2}
              disabled={statusEnd}
              margin={'0'}
            />
          </Grid>
          {statusEnd ? (
            <></>
          ) : (
            <div className="j-center w-complete mt-20">
              <LaddaButton
                width={"180px"}
                fontSize={"12px"}
                height={"32px"}
                variant="contained"
                onClick={handleSubmit(onSubmit)}
              >
                <p className="button_text">Guardar</p>
              </LaddaButton>
            </div>
          )}
        </Grid>
      </form>
    </ModalComponent>
  );
};

export default SurveyTableModal;
