import React from "react";
import Box from "@mui/material/Box";
import { Controller } from "react-hook-form";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import "../styles.css";
import { useMediaQuery } from "@mui/material";

const SimpleSelect = ({
  name,
  control,
  label,
  options,
  onChange,
  defaultValue,
  width,
  error,
  required,
  hideLabel,
  disabled,
  margin,
  marginTop
}) => {
  const isSmallScreen = useMediaQuery("(max-width:590px)");
  const isMediumScreen = useMediaQuery("(max-width:900px)");

  const StyledSelect = styled(Select)(() => ({
    width: width ? width : "100%",
    height: "40px",
    margin: margin ? margin : "0 10px 0 10px",
    borderRadius: "6px",
    marginTop: isMediumScreen ? "13px" : ""
  }));

  const StyledInputLabel = styled(InputLabel)(() => ({
    marginTop: marginTop ? marginTop : "0",
    top: "-10px",
    marginTop: isMediumScreen ? "13px" : "",
    color: hideLabel ? "white" : "",
    "&.Mui-error": {
      color: hideLabel ? "white" : ""
    },
    "&.Mui-focused": {
      color: hideLabel ? "white" : ""
    }
  }));

  const StyledMenuItem = styled(MenuItem)(() => ({
    fontSize: "14px",
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: width ? width : "100%",
    lineHeight: "1.2",
    padding: "8px 10px"
  }));

  return (
    <Box>
      <FormControl fullWidth error={Boolean(error)}>
        <StyledInputLabel
          shrink={true}
          required={required}
          id={`${name}-label`}
        >
          {label}
        </StyledInputLabel>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue || ""}
          render={({ field }) => (
            <StyledSelect
              {...field}
              value={field.value}
              labelId={`${name}-label`}
              id={name}
              onChange={(e) => {
                field.onChange(e);
                if (onChange) {
                  onChange(e);
                }
              }}
              disabled={disabled}
            >
              {options.map((option, index) => (
                <StyledMenuItem key={option.name} value={option.value}>
                  {option.name}
                </StyledMenuItem>
              ))}
            </StyledSelect>
          )}
        />
        {error && (
          <p
            className="error-message"
            style={{ marginTop: "5px", color: "red" }}
          >
            {error.message}
          </p>
        )}
      </FormControl>
    </Box>
  );
};

export default SimpleSelect;
