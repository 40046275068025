import React, { useEffect, useState } from "react";
import LaddaButton from "../../../components/buttons/laddaButton";
import { icons } from "../../../constants";
import SimpleTable from "../../../components/tables/simpleTable";
import { Grid } from "@mui/material";
import RegistrationModal from "./registrationModal";
import {
  getCurrentMonth,
  getCurrentMonthYearWithDate,
  getMonthName
} from "../../../utils/dateUtils";
import {
  callApiAsync,
  getOpportunityAction
} from "../../../services/apiService";

const TabRegistrationMonth = ({
  dataOpportunity,
  getOpportunityData,
  isEditionAvailable,
  updateRiskupdateDate
}) => {
  const [openRegistrationModal, setOpenRegistrationModal] = useState(false);
  const [isEditableModal, setIsEditableModal] = useState(false);
  const [monthSelected, setMonthSelected] = useState("");
  const [tableData, setTableData] = useState([]);
  const [registrationData, setRegistrationData] = useState([]);

  const columns = [
    { field: "monthName", header: "Mes" },
    { field: "month", header: "", columnType: "hide" },
    { field: "id", header: "", columnType: "hide" },
    { field: "action", header: "Acción del Tratamiento" },
    { field: "evidence", header: "Evidencias", columnType: "url" },
    { field: "actions", header: "", columnType: "actionButtons" }
  ];

  const handleCloseRegistrationModal = () => {
    setMonthSelected("");
    setRegistrationData([]);
    setIsEditableModal(false);
    setOpenRegistrationModal(false);
    updateTable();
  };

  const openModalRegistrationModal = () => {
    setOpenRegistrationModal(true);
  };

  useEffect(() => {
    getOpportunityEvidence();
  }, []);

  const updateTable = () => {
    const updatedData = tableData.map((item) => ({
      ...item,
      monthName: getMonthName(item.month)
    }));
    setTableData(updatedData);
  };

  const getOpportunityEvidence = async () => {
    callApiAsync(
      () => getOpportunityAction(dataOpportunity.opportunityMonthId),
      (data) => {
        const updatedData = data.map((item) => ({
          ...item,
          monthName: getMonthName(item.month)
        }));
        setTableData(updatedData);
      }
    );
  };

  return (
    <div>
      <div className="j-end mr-10">
        {isEditionAvailable && (
          <LaddaButton
            variant="contained"
            onClick={() => {
              setIsEditableModal(true);
              openModalRegistrationModal();
            }}
          >
            <div className="IHContainer align-rigth">
              <img className="btn-icon mr-10" src={icons.addPlus} alt="icon" />
              <p className="button_text">Agregar</p>
            </div>
          </LaddaButton>
        )}
      </div>

      <div className="simple-table-container">
        <SimpleTable
          text="Evaluar"
          tableStyle={{ minWidth: "100%" }}
          buttons={[
            {
              type: "iconSwitch",
              icon: icons.editBlue,
              icon2: icons.eye,
              onClick: (rowData) => {
                var isEditable = getCurrentMonthYearWithDate(
                  rowData.creation_date
                );
                setRegistrationData(rowData);
                setIsEditableModal(isEditable);
                setMonthSelected(rowData.monthName);
                openModalRegistrationModal();
              }
            }
          ]}
          data={tableData}
          columns={columns}
        />
      </div>
      <RegistrationModal
        opportunityId={dataOpportunity.id}
        opportunityData={dataOpportunity}
        openRegistrationModal={openRegistrationModal}
        handleCloseRegistrationModal={handleCloseRegistrationModal}
        isEditableModal={isEditableModal}
        monthSelected={
          monthSelected == "" ? getMonthName(getCurrentMonth()) : monthSelected
        }
        registrationData={registrationData}
        updateRiskupdateDate={updateRiskupdateDate}
        getOpportunityData={getOpportunityData}
      />
    </div>
  );
};

export default TabRegistrationMonth;
