import React from "react";

import AccompanimentPage from "./accompanimentPage";
import { useSelector } from "react-redux";

const AccompanimentPreview = () => {
  const previewId = useSelector((state) => state.acompaniment.previewId);

  return (
    <AccompanimentPage onlyRead={true} accompanimentItemId={previewId || 0} />
  );
};

export default AccompanimentPreview;
