import React, { useEffect, useState } from "react";
import { Grid, styled } from "@mui/material";
import LaddaButton from "../../../components/buttons/laddaButton";
import ModalComponent from "../../../components/modals";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormTextField from "../../../components/textField/formTextField";
import ToastNotification from "../../../components/toastNotification";
import {
  callApiAsync,
  postUpdateOpportunityAction
} from "../../../services/apiService";

yup.addMethod(yup.string, "serverOrUrl", function (message) {
  return this.test("serverOrUrl", message, function (value) {
    const { path, createError } = this;

    if (!value) {
      return createError({ path, message: "Este campo es obligatorio" });
    }

    const combinedRegex =
      /^(https?:\/\/[\w\-]+(\.[\w\-]+)+[/#?]?.*)|(^\\\\[\w.\-]+(\\[^\\/:*?"<>|]+)+$)/u;

    if (combinedRegex.test(value)) {
      return true;
    }

    return createError({
      path,
      message:
        "Debe ser un enlace válido o una ruta UNC (\\Servidor\\Carpeta\\Archivo)"
    });
  });
});

const validationSchema = yup.object().shape({
  treatmentAction: yup.string().required("Este campo es obligatorio"),
  evidences: yup.string().serverOrUrl().required("Este campo es obligatorio")
});

const RegistrationModal = ({
  opportunityId,
  opportunityData,
  openRegistrationModal,
  handleCloseRegistrationModal,
  registrationId,
  isEditableModal,
  monthSelected,
  registrationData,
  getOpportunityData,
  updateRiskupdateDate
}) => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);

  useEffect(() => {
    if (registrationId == 0) return;
    loadData();
  }, [registrationData]);

  const handleClose = () => {
    clearData();
    reset();
    handleCloseRegistrationModal();
  };

  const loadData = () => {
    setValue("treatmentAction", registrationData.action);
    setValue("evidences", registrationData.evidence);
  };

  const clearData = () => {
    setValue("treatmentAction", "");
    setValue("evidences", "");
  };

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {}
  });

  const onSubmit = async (formData) => {
    const dataToSave = {
      opportunityId: opportunityId,
      treatmentAction: formData.treatmentAction,
      evidences: formData.evidences
    };
    await updateEvidence(dataToSave);
  };

  const updateEvidence = async (dataToSave) => {
    const objRequest = {
      id: registrationData?.id ? registrationData.id : 0,
      opportunity_month_id: opportunityData.opportunityMonthId,
      month: opportunityData.month,
      action: dataToSave.treatmentAction,
      evidence: dataToSave.evidences
    };
    callApiAsync(
      () => postUpdateOpportunityAction(objRequest),
      () => {
        updateRiskupdateDate();
        getOpportunityData();
        handleClose();
      }
    );
  };

  return (
    <div>
      <ToastNotification
        severity="success"
        summary={toastMessage}
        detail=""
        position="top-center"
        showToast={showToast}
        setShowToast={setShowToast}
      />
      <ModalComponent
        open={openRegistrationModal}
        handleClose={handleClose}
        title={""}
        actions={
          <div className="w-complete mv-20">
            <div className="j-center">
              {isEditableModal && (
                <LaddaButton
                  variant="contained"
                  onClick={handleSubmit(onSubmit)}
                >
                  <div className="HContainer align-center">
                    <p className="button_text">Guardar Cambios</p>
                  </div>
                </LaddaButton>
              )}
            </div>
          </div>
        }
      >
        <form>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <p className="detail-label mt-20">Acción de Tratamiento:</p>
              <FormTextField
                name="treatmentAction"
                control={control}
                isTextArea={true}
                error={errors.treatmentAction}
                maxLength={500}
                rows={1}
                padding={"6.5px"}
                disabled={!isEditableModal}
                margin={"0"}
              />
            </Grid>
            <Grid item xs={12}>
              <p className="detail-label">Evidencias:</p>
              <FormTextField
                name="evidences"
                control={control}
                isTextArea={true}
                error={errors.evidences}
                maxLength={500}
                rows={1}
                padding={"6.5px"}
                margin={"0"}
                disabled={!isEditableModal}
              />
            </Grid>
          </Grid>
        </form>
      </ModalComponent>
    </div>
  );
};

export default RegistrationModal;
