import React, { useEffect, useState } from "react";
import LaddaButton from "../../../components/buttons/laddaButton";
import FormTextField from "../../../components/textField/formTextField";
import ModalComponent from "../../../components/modals";
import ToastNotification from "../../../components/toastNotification";
import { Grid } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import "../styles.css";
import { callApiAsync, postUpdateActions } from "../../../services/apiService";

const validationSchema = yup.object().shape({
  action: yup.string().required("Este campo es obligatorio"),
  indicator: yup.string().required("Este campo es obligatorio")
});

const ActionPaiModal = ({
  open,
  handleClose,
  data,
  actionNumber,
  getPaiData
}) => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {}
  });

  useEffect(() => {
    loadData();
  }, [open]);

  const handleCloseModal = () => {
    clearData();
    reset();
    handleClose();
  };

  const loadData = () => {
    setValue("action", actionNumber === 1 ? data?.action1 : data?.action2);
    setValue(
      "indicator",
      actionNumber === 1 ? data?.indicator1 : data?.indicator2
    );
  };

  const clearData = () => {
    setValue("action", "");
    setValue("indicator", "");
  };

  const updateForm = async (formData) => {
    const objRequest = {
      id: data.id,
      actionNumber: actionNumber,
      indicator: formData.indicator,
      action: formData.action
    };

    callApiAsync(
      () => postUpdateActions(objRequest),
      () => {
        getPaiData();
      }
    );
  };

  const onSubmit = async (formData) => {
    await updateForm(formData);
    handleClose();
  };

  return (
    <div>
      <ToastNotification
        severity="success"
        summary={toastMessage}
        detail=""
        position="top-center"
        showToast={showToast}
        setShowToast={setShowToast}
      />
      <ModalComponent
        open={open}
        handleClose={handleCloseModal}
        title={""}
        actions={
          <div className="HContainer j-center w-complete">
            <LaddaButton variant="contained" onClick={handleSubmit(onSubmit)}>
              <div className="HContainer align-center">
                <p className="button_text">Guardar Cambios</p>
              </div>
            </LaddaButton>
          </div>
        }
      >
        <form className="VContainer">
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <p className="detail-label mt-25">Acciones:</p>
              <FormTextField
                name="action"
                control={control}
                isTextArea={true}
                error={errors.action}
                maxLength={500}
                rows={2}
                margin={'0'}
              />
            </Grid>

            <Grid item xs={12}>
              <p className="detail-label">Indicador de resultado:</p>
              <FormTextField
                style={{ marginBottom: "20px" }}
                name={"indicator"}
                control={control}
                error={errors.indicator}
                maxLength={500}
                margin={'0'}

              />
            </Grid>
          </Grid>
        </form>
      </ModalComponent>
    </div>
  );
};

export default ActionPaiModal;
