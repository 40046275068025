import React, { useEffect, useRef, useState } from "react";
import LaddaButton from "../buttons/laddaButton";
import { useMediaQuery } from "@mui/material";

const SimpleSignComponent = ({
  setSignature,
  signature,
  hideButtons = false
}) => {
  const isSmallScreen = useMediaQuery("(max-width:590px)");
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);

  useEffect(() => {
    if (!canvasRef.current) return;

    const context = canvasRef.current.getContext("2d");
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

    if (signature) {
      const img = new Image();
      img.src = signature;
      img.onload = () => {
        context.drawImage(
          img,
          0,
          0,
          canvasRef.current.width,
          canvasRef.current.height
        );
      };
      img.onerror = () =>
        console.error("Error al cargar la imagen de la firma.");
    }
  }, [signature]);

  const handleClear = () => {
    const context = canvasRef.current.getContext("2d");
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    setSignature(null);
  };

  const handleSaveSignature = () => {
    const dataUrl = canvasRef.current.toDataURL("image/png");
    setSignature(dataUrl);
  };

  const startDrawing = (e) => {
    const context = canvasRef.current.getContext("2d");
    context.beginPath();
    context.moveTo(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
    setIsDrawing(true);
  };

  const draw = (e) => {
    if (!isDrawing) return;
    const context = canvasRef.current.getContext("2d");
    context.lineTo(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
    context.stroke();
  };

  const stopDrawing = () => setIsDrawing(false);

  return (
    <div className="column-center">
      <div className="hContainer j-center">
        <canvas
          ref={canvasRef}
          width={isSmallScreen ? 300 : 450}
          height={300}
          style={{ border: "1px solid #ccc", borderRadius: "4px" }}
          onMouseDown={startDrawing}
          onMouseMove={draw}
          onMouseUp={stopDrawing}
          onMouseLeave={stopDrawing}
        />
      </div>
      {hideButtons && (
        <div
          className="container-inputs"
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "center",
            paddingBottom: "30px"
          }}
        >
          <LaddaButton
            fontSize="12px"
            height="40px"
            variant="contained"
            onClick={handleClear}
          >
            Limpiar
          </LaddaButton>
          <LaddaButton
            width="160px"
            fontSize="12px"
            height="40px"
            variant="contained"
            onClick={handleSaveSignature}
          >
            Guardar
          </LaddaButton>
        </div>
      )}
    </div>
  );
};

export default SimpleSignComponent;
