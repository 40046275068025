import React from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import "./styles.css";

const VerticalTab = ({
  children,
  panels,
  value,
  setValue,
  currentTab,
  setCurrentTab
}) => {
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const isLargeScreen = useMediaQuery("(min-width:1200px)");
  const isMediumScreen = useMediaQuery(
    "(max-width:1200px) and (min-width:900px)"
  );
  const isSmallScreen = useMediaQuery("(max-width:590px)");
  const isMiniScreen = useMediaQuery("(max-width:450px)");

  let fontSize = "16px";
  let paddingSize = "20px";

  if (isLargeScreen) {
    fontSize = "14px";
    paddingSize = "20px";
  } else if (isMediumScreen) {
    fontSize = "12px";
    paddingSize = "15px";
  } else if (isSmallScreen) {
    fontSize = "10px";
    paddingSize = "10px";
  } else if (isMiniScreen) {
    fontSize = "8px";
    paddingSize = "5px";
  }

  const CustomTabList = styled(TabList)(({ theme }) => ({
    backgroundColor: "#ffffff",
    border: `1px solid ${theme.palette.divider}`,
    borderTopLeftRadius: "15px",
    borderBottomLeftRadius: "15px",

    display: "flex",
    flexDirection: "column",
    height: "100%"
  }));

  const TabContextStyled = styled(TabContext)(() => ({
    display: "flex",
    flexDirection: "row"
  }));

  const CustomTab = styled(Tab)(() => ({
    minWidth: "auto",
    padding: "15px 10px",
    textTransform: "initial",
    fontSize: fontSize,
    fontWeight: "900",
    fontFamily: "Lato",
    color: "#6A6AD8",
    "&.Mui-selected": {
      color: "#FFFFFF",
      backgroundColor: "#6A6AD8"
    }
  }));

  const CustomTabPanel = styled(TabPanel)(({ theme }) => ({
    padding: paddingSize,
    border: `1px solid ${theme.palette.divider}`,
    borderBottomRightRadius: "15px",
    borderTopRightRadius: "15px",
    fontFamily: "Lato",
    flexGrow: 1
  }));

  return (
    <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
      <TabContextStyled value={value}>
        <Box sx={{ borderRight: 1, borderColor: "divider" }}>
          <CustomTabList
            onChange={handleChange}
            aria-label="Vertical Tabs Example"
            orientation="vertical"
          >
            {panels.map((item) => (
              <CustomTab label={item.name} value={item.id} key={item.id} />
            ))}
          </CustomTabList>
        </Box>

        {
          <CustomTabPanel value={value} key={value}>
            {children}
          </CustomTabPanel>
        }
      </TabContextStyled>
    </Box>
  );
};

export default VerticalTab;
