import React, { useEffect, useState } from "react";
import ModalComponent from "../../../components/modals";
import LaddaButton from "../../../components/buttons/laddaButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Grid } from "@mui/material";
import FormTextField from "../../../components/textField/formTextField";
import {
  callApiAsync,
  getCallCities,
  getCallInstitutionById,
  postSaveInstitution
} from "../../../services/apiService";
import SimpleSelect from "../../../components/selects/simpleSelect";
import { technologyModalityOption } from "../../../constants/selectData";

const validationSchema = yup.object().shape({});

const AddInstitution = ({
  open,
  close,
  departmentOptions,
  getInstitutionSelect,
  institutionId,
  setInstitutionId,
  isNew,
  onSave
}) => {
  const [cityOptions, setCityOptions] = useState([]);

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors, isValid }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const handleClose = () => {
    reset();
    close();
  };

  const getCities = () => {
    const departmentId = getValues("department");
    if (departmentId != undefined) {
      callApiAsync(
        () => getCallCities(departmentId),
        (data) => {
          setCityOptions(data);
        }
      );
    }
  };

  const departmentValue = watch("department");

  const getInstitutionData = () => {
    callApiAsync(
      () => getCallInstitutionById(institutionId),
      (data) => {
        setValue("institutionName", data.institution.name);
        setValue("technologyModality", data.institution.technologyModality);
        setValue("department", data.institution.departmentId);
        setValue("city", data.institution.cityId);
        setValue("phone", data.institution.phone);
        setValue("address", data.institution.address);
      }
    );
  };

  useEffect(() => {
    if (isNew) {
      setInstitutionId(null);
      reset({
        institutionName: "",
        technologyModality: "",
        department: "",
        city: "",
        phone: "",
        address: ""
      });
    } else if (institutionId) {
      getInstitutionData();
    }
  }, [isNew, institutionId]);

  useEffect(() => {
    getCities(departmentValue);
  }, [departmentValue]);

  const onSubmit = () => {
    var objToSend = {
      id: institutionId ?? null,
      institutionName: getValues("institutionName"),
      name: getValues("institutionName"),
      technologyModality: getValues("technologyModality"),
      departmentId: getValues("department"),
      cityId: getValues("city"),
      phone: getValues("phone"),
      address: getValues("address")
    };

    callApiAsync(
      () => postSaveInstitution(objToSend),
      (data) => {
        if (onSave) {
          onSave(objToSend);
        }
        getInstitutionSelect();
        handleClose();
      }
    );
  };

  return (
    <div>
      <ModalComponent
        open={open}
        handleClose={() => {
          handleClose();
        }}
        title={institutionId ? "Editar Institución" : "Crear Institución"}
        actions={<div className="HContainer j-center w-complete"></div>}
      >
        <div className="HContainer">
          <form onSubmit={handleSubmit((formData) => onSubmit(formData))}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12} md={6}>
                <p className="create-label ml-0">Nombre de la Institución:</p>
                <FormTextField
                  name="institutionName"
                  control={control}
                  label=""
                  error={errors.institutionName}
                  margin={"0"}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <p className="create-label ml-0">Modalidad de la Tecnología:</p>

                <SimpleSelect
                  name="technologyModality"
                  control={control}
                  options={technologyModalityOption}
                  hideLabel={true}
                  margin={"0"}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <p className="create-label ml-0">Departamento:</p>
                <SimpleSelect
                  name="department"
                  control={control}
                  options={departmentOptions}
                  hideLabel={true}
                  onChange={getCities}
                  margin={"0"}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <p className="create-label ml-0">Ciudad:</p>
                <SimpleSelect
                  name="city"
                  control={control}
                  options={cityOptions}
                  hideLabel={true}
                  margin={"0"}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <p className="create-label ml-0">Teléfono:</p>
                <FormTextField
                  name="phone"
                  control={control}
                  label=""
                  error={errors.phone}
                  margin={"0"}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <p className="create-label ml-0">Dirección:</p>
                <FormTextField
                  name="address"
                  control={control}
                  label=""
                  error={errors.address}
                  margin={"0"}
                />
              </Grid>
            </Grid>
            <div className="w-complete j-center">
              <LaddaButton
                width={"180px"}
                fontSize={"12px"}
                height={"32px"}
                variant="contained"
                margin={"20px 0"}
                type="submit"
                disabled={!isValid}
              >
                <p className="button_text">
                  {institutionId ? "Editar Institución" : "Crear Institución"}
                </p>
              </LaddaButton>
            </div>
          </form>
        </div>
      </ModalComponent>
    </div>
  );
};

export default AddInstitution;
