import http from "./httpService";
import { API_URL } from "../config";

export const callActionApiAsync = async (
  apiFunc,
  setData,
  handleError,
  setLoader
) => {
  if (setLoader) setLoader(true);
  try {
    await apiFunc()
      .then(async (response) => {
        if (response) {
          if (setData.constructor.name === "AsyncFunction")
            await setData(response.data);
          else setData(response.data);
        }
      })
      .catch((err) => {
        var error = "";
        if (err.response) {
          if (err.response.data) {
            error = err.response.data.error;
            console.log("error-response: ", err.response.data.error);
          } else {
            error = err.response;
            console.log("error-response: ", err.response);
          }
        } else if (err.request) {
          error = err.request;
          console.log("error-request: ", err.request);
        } else {
          error = err;
          console.log("error-error: ", err);
        }

        if (handleError) {
          console.log("handleError: -> ", error);
          handleError(error);
        }
      })
      .then(() => {
        if (setLoader) setLoader(false);
      });
  } catch (e) {
    console.log("error: ", e.message);
  }
};

export const callApiAsync = async (apiFunc, setData, setLoader) => {
  await callActionApiAsync(
    apiFunc,
    setData,
    (error) => {
      //   if (typeof error === "string") NotificationManager.error(error);
    }, //, (data) => dispatch(setLoader(data))
    (data) => {
      if (setLoader) setLoader(data);
    }
  );
};

export const getAuthorityByUser = (userId) =>
  http.get(`${API_URL}/getAuthorityByUser/${userId}`);
export const getCallTimeFrameState = () =>
  http.get(`${API_URL}/getTimeFrameState/`);
export const getCallProcess = () => http.get(`${API_URL}/getProcess`);
export const getCallRiskType = () => http.get(`${API_URL}/getRiskType/1`);
export const postUpdateRisksAndOpportunities = (userId) =>
  http.post(`${API_URL}/updateRisksAndOpportunities`, userId);

export const postUpdateRiskTreatment = (data) =>
  http.post(`${API_URL}/updateRiskTreatment`, data);

export const postCreateOpportunity = (data) =>
  http.post(`${API_URL}/createOpportunity`, data);

export const postCreateDofa = (data) =>
  http.post(`${API_URL}/createDofa`, data);
export const postUpdateDofa = (data) =>
  http.post(`${API_URL}/updateDofa`, data);

export const postUpdateField = (data) =>
  http.post(`${API_URL}/updatefield`, data);
export const postUpdatePaiGoal = (data) =>
  http.post(`${API_URL}/updatePaiGoal`, data);
export const postUpdateActions = (data) =>
  http.post(`${API_URL}/updateActions`, data);
export const postSavePaiObservation = (data) =>
  http.post(`${API_URL}/savePaiObservation`, data);
export const postSaveEmployeeComment = (data) =>
  http.post(`${API_URL}/saveEmployeeComment`, data);
export const postAddAnswers = (data) =>
  http.post(`${API_URL}/addAnswers`, data);
export const postCreateUpdateReport = (data) =>
  http.post(`${API_URL}/createUpdateReport`, data);

export const postCreateRisk = (data) =>
  http.post(`${API_URL}/createRisk`, data);
export const postUpdateRiskDetail = (data) =>
  http.post(`${API_URL}/updateRiskDetail`, data);
export const postUpdateStatusRisk = (data) =>
  http.post(`${API_URL}/updateStatusRisk`, data);

export const postUpdateEvidence = (data) =>
  http.post(`${API_URL}/updateEvidence`, data);

export const postUpdateOpportunityDetail = (data) =>
  http.post(`${API_URL}/updateOpportunityDetail`, data);

export const postUpdateTimeFrameState = () =>
  http.post(`${API_URL}/updateTimeFrameState`);
export const postCreateDashboard = (data) =>
  http.post(`${API_URL}/createDashboard`, data);

export const postUpdateReport360Observations = (data) =>
  http.post(`${API_URL}/updateReport360Observations`, data);

export const getCallDofaReport = (userId, year, month) =>
  http.get(`${API_URL}/getDofaReport/${userId}/${year}/${month}`);
export const getCallJsonSettingVersion = () =>
  http.get(
    `${API_URL}/getJsonSettingVersion/RISK_OPPORTUNITY_MANAGEMENT_VERSION`
  );
export const getSettingById = (settingId) =>
  http.get(`${API_URL}/getSettingById/${settingId}`);

export const getCallDofaByUser = (userId, month, year, processId, state) =>
  http.get(
    `${API_URL}/getDofaByUser/${userId}/${month}/${year}/${processId}/${state}`
  );

export const getCallPaiObservationData = (id) =>
  http.get(`${API_URL}/getPaiObservationData/${id}`);

export const getCallPaiData = (userId) =>
  http.get(`${API_URL}/getPaiData/${userId}`);

export const getCallAutoEvaluation = (userId) =>
  http.get(`${API_URL}/getAutoEvaluation/${userId}`);

export const getCallPairSurvey = (userId) =>
  http.get(`${API_URL}/getPairSurvey/${userId}`);

export const getCallColabSurvey = (userId, year) =>
  http.get(`${API_URL}/getColabSurvey/${userId}/${year}`);

export const getCallManagerSurvey = (userId, year) =>
  http.get(`${API_URL}/getManagerSurvey/${userId}/${year}`);

export const getCallSurvey = (surveyId) =>
  http.get(`${API_URL}/getSurvey/${surveyId}`);

export const getCallActivitiesByCompetency = (userId) =>
  http.get(`${API_URL}/getActivitiesByCompetency/${userId}`);

export const getCallPositionList = () => http.get(`${API_URL}/getPositionList`);

export const getCallPowerBiById = (menuItemId) =>
  http.get(`${API_URL}/getPowerBiById/${menuItemId}`);

export const getCallPowerBiProcessList = (id) =>
  http.get(`${API_URL}/getPowerBiProcessList/${id}`);

export const getCallRisksList = (userId, year, month, processId) =>
  http.get(`${API_URL}/getRisksList/${userId}/${year}/${month}/${processId}`);

export const getCallRiskEvidenceList = (id) =>
  http.get(`${API_URL}/getRiskEvidenceList/${id}`);

export const getCallRisksResume = (userId, year, month) =>
  http.get(`${API_URL}/getRisksResume/${userId}/${year}/${month}`);

export const getCallOpportunitiesResume = (userId, year, month) =>
  http.get(`${API_URL}/getOpportunitiesResume/${userId}/${year}/${month}`);

export const getCallOpportunity = (opportunityId) =>
  http.get(`${API_URL}/getOpportunity/${opportunityId}`);

export const getCallRisk = (opportunityId) =>
  http.get(`${API_URL}/getRisk/${opportunityId}`);

export const getCallOpportunityEvidenceList = (id) =>
  http.get(`${API_URL}/getOpportunityEvidenceList/${id}`);

export const getCallOpportunityList = (userId, year, month, processId) =>
  http.get(
    `${API_URL}/getOpportunityList/${userId}/${year}/${month}/${processId}`
  );

export const getCallSelfAppraisal = (currentUserId) =>
  http.get(`${API_URL}/getSelfAppraisal/${currentUserId}`);

export const getCallEmployeesInMonitoring = (currentUserId, year) =>
  http.get(`${API_URL}/getEmployeesInMonitoring/${currentUserId}/${year}`);

export const getCallAllEmployeesInMonitoring = (userId) =>
  http.get(`${API_URL}/getAllEmployees/${userId}`);

export const getCallCurrentUser = (userId) =>
  http.get(`${API_URL}/getCurrentUser/${userId}`);

export const getCallManagerReport360GraphData = (userId) =>
  http.get(`${API_URL}/getManagerReport360GraphData/${userId}`);

export const getCallManagerTotal360GraphData = (userId) =>
  http.get(`${API_URL}/getManagerTotal360GraphData/${userId}`);

export const getCallSpecifyReport360GraphData = (userId) =>
  http.get(`${API_URL}/getSpecifyReport360GraphData/${userId}`);

export const getCallReport360Observations = (userId) =>
  http.get(`${API_URL}/getReport360Observations/${userId}`);

export const getCallOrganizationalReport360GraphData = (userId) =>
  http.get(`${API_URL}/getOrganizationalReport360GraphData/${userId}`);

export const getCallPowerBiDetailsByIdAndUserId = (
  idPowerBI,
  valueTab,
  userId,
  month,
  year
) =>
  http.get(
    `${API_URL}/getPowerBiDetailsByIdAndUserId/${idPowerBI}/${valueTab}/${userId}/${month}/${year}`
  );

export const postUpdateEvidenceOpportunity = (data) =>
  http.post(`${API_URL}/updateEvidenceOpportunity`, data);

export const getCallDofaGraph = (userId, yearFilter, fourMonthPeriodFilter) =>
  http.get(
    `${API_URL}/getDofaGraph/${userId}/${yearFilter}/${fourMonthPeriodFilter}`
  );

export const getCallRiskResumeGraph = (
  userId,
  yearFilter,
  fourMonthPeriodFilter
) =>
  http.get(
    `${API_URL}/getRiskResumeGraph/${userId}/${yearFilter}/${fourMonthPeriodFilter}`
  );

export const getCallOpportunityGraph = (userId, yearFilter, monthFilter) =>
  http.get(
    `${API_URL}/getOpportunityGraph/${userId}/${yearFilter}/${monthFilter}`
  );

export const getCallSelectsBiomedicalEquipment = () =>
  http.get(`${API_URL}/getSelects`);

export const getCallSeries = () => http.get(`${API_URL}/getSeries`);

export const getCallBiomedicalEquipmentBySerie = (serieId) =>
  http.get(`${API_URL}/getBiomedicalEquipmentBySerie/${serieId}`);

export const getCallVerifySerie = (serieName) =>
  http.get(`${API_URL}/getVerifySerie/${serieName}`);

export const postSaveSerie = (data) => http.post(`${API_URL}/saveSerie`, data);

export const postAddAccessory = (data) =>
  http.post(`${API_URL}/addAccessory`, data);

export const postEquipementResume = (data) =>
  postLoadFile(`${API_URL}/postEquipementResume`, data);

export const getCallResumeSelects = () =>
  http.get(`${API_URL}/getResumeSelects`);

export const getCallCities = (departmentId) =>
  http.get(`${API_URL}/getCities/${departmentId}`);

export const getCallAccessoryData = (brandId, biomedicalEquipmentId) =>
  http.get(`${API_URL}/getAccessoryData/${brandId}/${biomedicalEquipmentId}`);

export const postAddAccessoryData = (data) =>
  http.post(`${API_URL}/addAccessoryData`, data);

export const postSaveResumeFeature = (data) =>
  http.post(`${API_URL}/saveResumeFeature`, data);

export const getCallResumeFeatureData = (bioEquipmentId) =>
  http.get(`${API_URL}/getResumeFeatureData/${bioEquipmentId}`);

export const postSaveBiomedicalFeature = (data) =>
  http.post(`${API_URL}/saveBiomedicalFeature`, data);

const postLoadFile = (url, formData) =>
  http.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });

export const postSaveBiomedicalEquipment = (data) =>
  postLoadFile(`${API_URL}/saveBiomedicalEquipment`, data);

export const getSelectsBiomedical = () =>
  http.get(`${API_URL}/getBiomedicalSelects`);

export const postAddBiomedicalUbication = (data) =>
  http.post(`${API_URL}/addBiomedicalUbication`, data);

export const postBiomedicaAditions = (data) =>
  http.post(`${API_URL}/postBiomedicaAditions`, data);

export const postSaveInstitution = (data) =>
  http.post(`${API_URL}/saveInstitution`, data);

export const getCallInstitutionById = (institutionId) =>
  http.get(`${API_URL}/getInstitutionById/${institutionId}`);

export const getCallInstitutionList = () =>
  http.get(`${API_URL}/getInstitutionList`);

export const getCallResumeByBioId = (biomedicalId) =>
  http.get(`${API_URL}/getResumeByBioId/${biomedicalId}`);

export const getCallBioEquipmentById = (bioEquipmentId) =>
  http.get(`${API_URL}/getBioEquipmentById/${bioEquipmentId}`);

export const getCallInspectionProtocolData = (bioEquipmentId) =>
  http.get(`${API_URL}/getInspectionProtocolData/${bioEquipmentId}`);

export const postSaveInspectionProtocol = (data) =>
  http.post(`${API_URL}/saveInspectionProtocol`, data);

export const postDeleteInspectionProtocol = (data) =>
  http.post(`${API_URL}/deleteInspectionProtocol`, data);

export const getDevicesInventoryFilters = () =>
  http.get(`${API_URL}/getDevicesInventoryFilter`);

export const getDevicesInventoryNotificationsbyUser = (userId) =>
  http.get(`${API_URL}/getDevicesInventoryNotification/${userId}`);

export const getDevicesInventoryNotificationsApprover = () =>
  http.get(`${API_URL}/getDevicesInventoryNotificationsApprover`);

export const getCallInventoryFile = (year) =>
  http.get(`${API_URL}/getInventoryFile/${year}`, {
    responseType: "blob"
  });

export const getCallSelectYear = () => http.get(`${API_URL}/getSelectYear`);

export const postSaveInspectionRecord = (data) =>
  http.post(`${API_URL}/saveInspectionRecord`, data);

export const postRespondInspectionRecord = (data) =>
  http.post(`${API_URL}/respondInspectionRecord`, data);

export const getAdverseEventsBiomedicalEquipment = (biomedicalEquipmentId) =>
  http.get(
    `${API_URL}/getAdverseEventsBiomedicalEquipment/${biomedicalEquipmentId}`
  );

export const addOrUpdateAdverseEventBiomedicalEquipment = (data) =>
  http.post(`${API_URL}/addOrUpdateAdverseEventBiomedicalEquipment`, data);

export const getProtocolActivitySelect = () =>
  http.get(`${API_URL}/getProtocolActivities`);

export const addProtocolActivity = (data) =>
  http.post(`${API_URL}/addProtocolActivity`, data);

export const postSaveMacroStrategy = (data) =>
  http.post(`${API_URL}/saveMacroStrategy`, data);

export const getMacroStrategyData = (year, processId) =>
  http.get(`${API_URL}/getMacroStrategy/${year}/${processId}`);

export const getDofaStrategyData = (month, year, processId, state) =>
  http.get(`${API_URL}/getDofaStrategy/${year}/${processId}/${state}/${month}`);

export const getDofaRootAndMonth = (year, processId, month) =>
  http.get(`${API_URL}/getDofaRootAndMonth/${year}/${processId}/${month}`);

export const postDofaStrategy = (data) =>
  http.post(`${API_URL}/saveDofaStrategy`, data);

export const getProcessLeaders = (processId) =>
  http.get(`${API_URL}/getProcessLeaders/${processId}`);

export const getStrategiesDofaBanner = (processId) =>
  http.get(`${API_URL}/getStrategiesDofaBanner/${processId}`);

export const postSaveStrategiesDofaBanner = (data) =>
  http.post(`${API_URL}/saveStrategiesDofaBanner`, data);

export const getCallInternalClientSurvey = (userId) =>
  http.get(`${API_URL}/getInternalClientSurvey/${userId}`);

export const getHeaderBanner = (id) =>
  http.get(`${API_URL}/getHeaderBannerById/${id}`);

export const getAdminBannerData = () =>
  http.get(`${API_URL}/getAdminBannerData`);

export const postSaveAdminBanner = (data) =>
  http.post(`${API_URL}/postSaveAdminBanner`, data);

export const postSaveActivityEvidence = (data) =>
  http.post(`${API_URL}/postSaveActivityEvidence`, data);

export const getActivityEvidenceByUser = (user_id, activity_id) =>
  http.get(`${API_URL}/getActivityEvidenceByUser/${user_id}/${activity_id}`);

export const getPaiEvidencebyId = (id) =>
  http.get(`${API_URL}/getPaiEvidencebyId/${id}`);

export const postPaiEvidence = (data) =>
  http.post(`${API_URL}/postPaiEvidence`, data);

export const postVerificationRisk = (data) =>
  http.post(`${API_URL}/postVerificationRisk`, data);

export const postUpdateRiskAction = (data) =>
  http.post(`${API_URL}/postUpdateRiskAction`, data);

export const getRiskActionsByRiskMonthId = (risk_month_id) =>
  http.get(`${API_URL}/getRiskActionsByRiskMonthId/${risk_month_id}`);

export const postUpdateOpportunityAction = (data) =>
  http.post(`${API_URL}/postUpdateOpportunityAction`, data);

export const getOpportunityAction = (opportunity_month_id) =>
  http.get(`${API_URL}/getOpportunityAction/${opportunity_month_id}`);

export const postUpdateRiskUpdateDate = (data) =>
  http.post(`${API_URL}/updateRiskUpdateDate`, data);

export const postUpdateOportunityUpdateDate = (data) =>
  http.post(`${API_URL}/updateOportunityUpdateDate`, data);

export const getAccompaniment = (id) =>
  http.get(`${API_URL}/getAccompaniment/${id}`);

export const getAccompanimentSelects = () =>
  http.get(`${API_URL}/getAccompanimentSelects`);

export const getBusinessLineSelect = (userId) =>
  http.get(`${API_URL}/getBusinessLineSelect/${userId}`);

export const getPriorCommitment = (userId, lineId, accompanimentId) =>
  http.get(
    `${API_URL}/getPriorCommitment/${userId}/${lineId}/${accompanimentId}`
  );

export const getPlannedOrganized = (accompanimentId) =>
  http.get(`${API_URL}/getPlannedOrganized/${accompanimentId}`);

export const getSpinTechnique = (accompanimentId) =>
  http.get(`${API_URL}/getSpinTechnique/${accompanimentId}`);

export const getPromotionalAspects = (accompanimentId) =>
  http.get(`${API_URL}/getPromotionalAspects/${accompanimentId}`);

export const getAccompanimentList = (
  dateFilter,
  yearFilter,
  userExecutiveId,
  userLeaderId,
  keyAccompaniment,
  status
) =>
  http.get(
    `${API_URL}/getAccompanimentList/${dateFilter}/${yearFilter}/${userExecutiveId}/${userLeaderId}/${keyAccompaniment}/${status}`
  );

export const postSaveAccompaniment = (data) =>
  http.post(`${API_URL}/saveAccompaniment`, data);

export const postSavePromotionalAspects = (data) =>
  http.post(`${API_URL}/savePromotionalAspects`, data);

export const postSavePlannedOrganized = (data) =>
  http.post(`${API_URL}/savePlannedOrganized`, data);

export const postSaveSpinTechnique = (data) =>
  http.post(`${API_URL}/saveSpinTechnique`, data);

export const getFilterSelects = (userId) =>
  http.get(`${API_URL}/getFilterSelects/${userId}`);
