export const timeFrameState = {
  ACTIVE: 1,
  INACTIVE: 2
};

export const roleByProcess = {
  1: "ADMINISTRATIVE_MANAGEMENT_LEADER",
  2: "BIOMEDICAL_MANAGEMENT_LEADER",
  3: "COMMERCIAL_MANAGEMENT_LEADER",
  4: "HUMAN_RESOURCES_MANAGEMENT_LEADER",
  5: "HSQ_MANAGEMENT_LEADER",
  6: "MARKETING_AND_PROMOTION_MANAGEMENT_LEADER",
  7: "CUSTOMER_SERVICE_MANAGEMENT_LEADER",
  8: "FINANCIAL_AND_ACCOUNTING_MANAGEMENT_LEADER",
  9: "PHARMACEUTICAL_MANAGEMENT_LEADER",
  10: "MANAGEMENT_DIRECTOR_LEADER",
  11: "FOREIGN_TRADE_DIRECTOR_LEADER",
  12: "TECHNICAL_DIRECTION_TECHNICAL_SUPPORT_LEADER",
  13: "SAFETY_AND_HEALTH_AT_WORK_LEADER"
};

export const inspectionRecordProcess = {
  pending: 1,
  sent: 2,
  approved: 3
};

export const dofaTypeEnum = {
  weakness: 1,
  opportunity: 2,
  strengths: 3,
  threats: 4
};

export const strategiesTypeEnum = {
  growth: 1,
  redirect: 2,
  protection: 3,
  survive: 4
};

export const riskLevelState = {
  materialized: 1,
  closedWithoutMitigation: 2,
  accepted: 3,
  mitigated: 4,
  improved: 5,
  latent: 6
};

export const headerBannerType = {
  dofa: 1,
  riskAndOportunity: 2,
  accompaniment: 3
};

export const headerBannerState = {
  pending: 0,
  completed: 1
};

export const acompanimentStatus = {
  new: 0,
  inProcess: 1,
  ended: 2
}