import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import LaddaButton from "../buttons/laddaButton";
import { styled, useMediaQuery } from "@mui/material";

const ModalComponent = ({
  open,
  handleClose,
  title,
  children,
  actions,
  width,
  maxWidth
}) => {
  const isSmallScreen = useMediaQuery("(max-width:590px)");

  const DialogStyled = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper": {
      width: width || "auto",
      minWidth: "290px",
      maxWidth: maxWidth ? maxWidth : "100vw",
      margin: isSmallScreen ? "10px" : ""
    }
  }));

  return (
    <DialogStyled
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
    >
      {title && (
        <DialogTitle id="modal-title">
          <span className="gm-title">{title}</span>
        </DialogTitle>
      )}
      <DialogContent style={{ paddingTop: "10px" }}>{children}</DialogContent>
      <DialogActions>
        {actions ? (
          actions
        ) : (
          <LaddaButton variant="contained" onClick={handleClose}>
            <div className="HContainer align-center">
              <p className="button_text">Cerrar</p>
            </div>
          </LaddaButton>
        )}
      </DialogActions>
    </DialogStyled>
  );
};

export default ModalComponent;
