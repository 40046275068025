import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/header";
// ----------------------------------- LAYOUTS -----------------------------------
import MainLayout from "./layouts/mainLayout";
// ----------------------------------- SCREENS -----------------------------------
import Login from "./screens/login";
import Home from "./screens/home";
import Dashboard from "./screens/dashboard";
import Dofa from "./screens/dofa";
import GeneralManagement from "./screens/generalManagement";
import ProcessDetails from "./screens/processDetails";
import Clients from "./screens/clients";
import SelfAppraisal from "./screens/selfAppraisal/index.js";
import Survey from "./screens/survey/index.js";
import TopProducts from "./screens/topDetails/topProducts.js";
import TopClients from "./screens/topDetails/topClients.js";
import SuperiorRolDashboard from "./screens/superiorRolDashboard/index.js";
import Report_360 from "./screens/report_360/index.js";
import Pai_360 from "./screens/pai_360/index.js";
import BiomedicalManagementDashboard from "./screens/BiomedicalManagementDashboard/index.js";
import ProductDetails from "./screens/topDetails/productDetails.js";
import ManagementDashboardIndicator from "./screens/BiomedicalManagementDashboard/managementDashboardIndicator.js";
import ManageDevice from "./screens/BiomedicalManagementDashboard/manageDevice.js";
import BioMedicalRequest from "./screens/BiomedicalManagementDashboard/requestScreens/bioMedicalRequest.js";
import ServiceRecords from "./screens/BiomedicalManagementDashboard/serviceRecords.js";
import CreateRequest from "./screens/BiomedicalManagementDashboard/requestScreens/createRequest.js";
import MarketingPromotionManagement from "./screens/marketingPromotionManagement/index.js";
import PowerBiLayout from "./layouts/powerBiLayout/index.js";
import RiskAndOpportunityManagement from "./screens/riskAndOpportunityManagement/index.js";
import CreateRisk from "./screens/createRisk/index.js";
import CreateOpportunity from "./screens/createOpportunity/index.js";
import OpportunityDetails from "./screens/opportunityDetails/index.js";
import RiskDetails from "./screens/riskDetails/index.js";
import RiskAdminGestion from "./screens/riskAdminGestion/index.js";
import OpportunityAdminGestion from "./screens/opportunityAdminGestion/index.js";
import ProtectedRoute from "./components/protectedRoute/index.js";
import NotAuthorized from "./screens/NotAuthorized/index.js";
import RegisterBiomedicalEquipment from "./screens/BiomedicalManagementDashboard/registerBiomedicalEquipment.js";
import BiomedicalEquipmentResume from "./screens/BiomedicalManagementDashboard/biomedicalEquipmentResume.js";
import NewTechnicalInspectionAct from "./screens/BiomedicalManagementDashboard/newTechnicalInspectionAct.js";
import BannerAdmin from "./screens/managementDirection/banner_admin/index.js";
import AccompanimentModule from "./screens/marketingPromotionManagement/accompanimentModule/index.js";
import AccompanimentCreation from "./screens/marketingPromotionManagement/accompanimentModule/accompanimentCreation.js";
import AccompanimentPreview from "./screens/marketingPromotionManagement/accompanimentModule/accompanimentPreview.js";

function App() {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/unauthorized" element={<NotAuthorized />} />
          <Route
            path="/login"
            element={
              <MainLayout>
                <Login />
              </MainLayout>
            }
          />
          <Route
            path="/superior_rol_dashboard"
            element={
              <ProtectedRoute
                path="/superior_rol_dashboard"
                element={
                  <MainLayout>
                    <SuperiorRolDashboard />
                  </MainLayout>
                }
              />
            }
          />
          <Route
            path="/360_Report"
            element={
              <ProtectedRoute
                path="/360_Report"
                element={
                  <MainLayout>
                    <Report_360 />
                  </MainLayout>
                }
              />
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute
                path="/dashboard"
                element={
                  <MainLayout>
                    <Dashboard />
                  </MainLayout>
                }
              />
            }
          />
          <Route
            path="/dashboard/dofa/processDetails"
            element={
              <ProtectedRoute
                path="/dashboard/dofa/processDetails"
                element={
                  <MainLayout>
                    <ProcessDetails />
                  </MainLayout>
                }
              />
            }
          />
          <Route
            path="/self_appraisal"
            element={
              <ProtectedRoute
                path="/self_appraisal"
                element={
                  <MainLayout>
                    <SelfAppraisal />
                  </MainLayout>
                }
              />
            }
          />
          <Route
            path="/self_appraisal/survey"
            element={
              <ProtectedRoute
                path="/self_appraisal/survey"
                element={
                  <MainLayout>
                    <Survey />
                  </MainLayout>
                }
              />
            }
          />
          <Route
            path="/general_management"
            element={
              <ProtectedRoute
                path="/general_management"
                element={
                  <MainLayout>
                    <GeneralManagement />
                  </MainLayout>
                }
              />
            }
          />
          <Route
            path="/clients"
            element={
              <ProtectedRoute
                path="/clients"
                element={
                  <MainLayout>
                    <Clients />
                  </MainLayout>
                }
              />
            }
          />
          <Route
            path="/clients/topClients"
            element={
              <ProtectedRoute
                path="/clients/topClients"
                element={
                  <MainLayout>
                    <TopClients />
                  </MainLayout>
                }
              />
            }
          />
          <Route
            path="/clients/topProducts"
            element={
              <ProtectedRoute
                path="/clients/topProducts"
                element={
                  <MainLayout>
                    <TopProducts />
                  </MainLayout>
                }
              />
            }
          />
          <Route
            path="/clients/topProducts/details"
            element={
              <ProtectedRoute
                path="/clients/topProducts/details"
                element={
                  <MainLayout>
                    <ProductDetails />
                  </MainLayout>
                }
              />
            }
          />
          <Route
            path="/superior_rol_dashboard/Pai_360"
            element={
              <ProtectedRoute
                path="/superior_rol_dashboard/Pai_360"
                element={
                  <MainLayout>
                    <Pai_360 />
                  </MainLayout>
                }
              />
            }
          />
          <Route
            path="/biomedicalManagementDashboard"
            element={
              <ProtectedRoute
                path="/biomedicalManagementDashboard"
                element={
                  <MainLayout>
                    <BiomedicalManagementDashboard />
                  </MainLayout>
                }
              />
            }
          />
          <Route
            path="/biomedicalManagementDashboard/indicator"
            element={
              <ProtectedRoute
                path="/biomedicalManagementDashboard"
                element={
                  <MainLayout>
                    <ManagementDashboardIndicator />
                  </MainLayout>
                }
              />
            }
          />
          <Route
            path="/biomedicalManagementDashboard/manageDevice"
            element={
              <ProtectedRoute
                path="/biomedicalManagementDashboard"
                element={
                  <MainLayout>
                    <ManageDevice />
                  </MainLayout>
                }
              />
            }
          />
          <Route
            path="/biomedicalManagementDashboard/BioMedicalRequest"
            element={
              <ProtectedRoute
                path="/biomedicalManagementDashboard"
                element={
                  <MainLayout>
                    <BioMedicalRequest />
                  </MainLayout>
                }
              />
            }
          />
          <Route
            path="/biomedicalManagementDashboard/BioMedicalRequest/createRequest"
            element={
              <ProtectedRoute
                path="/biomedicalManagementDashboard"
                element={
                  <MainLayout>
                    <CreateRequest />
                  </MainLayout>
                }
              />
            }
          />
          <Route
            path="/biomedicalManagementDashboard/serviceRecords"
            element={
              <ProtectedRoute
                path="/biomedicalManagementDashboard"
                element={
                  <MainLayout>
                    <ServiceRecords />
                  </MainLayout>
                }
              />
            }
          />
          <Route
            path="/riskAndOpportunityManagement"
            element={
              <ProtectedRoute
                path="/riskAndOpportunityManagement"
                element={
                  <MainLayout>
                    <RiskAndOpportunityManagement />
                  </MainLayout>
                }
              />
            }
          />
          <Route
            path="/riskAndOpportunityManagement/dofa"
            element={
              <ProtectedRoute
                path="/riskAndOpportunityManagement/dofa"
                element={
                  <MainLayout>
                    <Dofa />
                  </MainLayout>
                }
              />
            }
          />
          <Route
            path="/dashboard/dofa"
            element={
              <ProtectedRoute
                path="/riskAndOpportunityManagement/dofa"
                element={
                  <MainLayout>
                    <Dofa />
                  </MainLayout>
                }
              />
            }
          />
          <Route
            path="/dashboard/dofa"
            element={
              <ProtectedRoute
                path="/riskAndOpportunityManagement/dofa"
                element={
                  <MainLayout>
                    <Dofa />
                  </MainLayout>
                }
              />
            }
          />
          <Route
            path="/riskAndOpportunityManagement/createRisk"
            element={
              <ProtectedRoute
                path="/riskAndOpportunityManagement/createRisk"
                element={
                  <MainLayout>
                    <CreateRisk />
                  </MainLayout>
                }
              />
            }
          />
          <Route
            path="/riskAndOpportunityManagement/createOpportunity"
            element={
              <ProtectedRoute
                path="/riskAndOpportunityManagement/createOpportunity"
                element={
                  <MainLayout>
                    <CreateOpportunity />
                  </MainLayout>
                }
              />
            }
          />
          <Route
            path="/riskAndOpportunityManagement/riskAdminGestion"
            element={
              <ProtectedRoute
                path="/riskAndOpportunityManagement/riskAdminGestion"
                element={
                  <MainLayout>
                    <RiskAdminGestion />
                  </MainLayout>
                }
              />
            }
          />
          <Route
            path="/riskAndOpportunityManagement/riskAdminGestion/riskDetails"
            element={
              <ProtectedRoute
                path="/riskAndOpportunityManagement/riskAdminGestion/riskDetails"
                element={
                  <MainLayout>
                    <RiskDetails />
                  </MainLayout>
                }
              />
            }
          />
          <Route
            path="/riskAndOpportunityManagement/opportunityAdminGestion"
            element={
              <ProtectedRoute
                path="/riskAndOpportunityManagement/opportunityAdminGestion"
                element={
                  <MainLayout>
                    <OpportunityAdminGestion />
                  </MainLayout>
                }
              />
            }
          />
          <Route
            path="/riskAndOpportunityManagement/opportunityAdminGestion/opportunityDetails"
            element={
              <ProtectedRoute
                path="/riskAndOpportunityManagement/opportunityAdminGestion/opportunityDetails"
                element={
                  <MainLayout>
                    <OpportunityDetails />
                  </MainLayout>
                }
              />
            }
          />
          <Route
            path="/marketingPromotionManagement/:powerBi?"
            element={
              <ProtectedRoute
                path="/marketingPromotionManagement"
                element={
                  <PowerBiLayout>
                    <MarketingPromotionManagement />
                  </PowerBiLayout>
                }
              />
            }
          />
          <Route
            path="/biomedicalManagementDashboard/manageDevice/registerBiomedicalEquipment"
            element={
              <ProtectedRoute
                path="/biomedicalManagementDashboard"
                element={
                  <MainLayout>
                    <RegisterBiomedicalEquipment />
                  </MainLayout>
                }
              />
            }
          />

          <Route
            path="/biomedicalManagementDashboard/manageDevice/NewTechnicalInspectionAct"
            element={
              <ProtectedRoute
                path="/biomedicalManagementDashboard"
                element={
                  <MainLayout>
                    <NewTechnicalInspectionAct />
                  </MainLayout>
                }
              />
            }
          />

          <Route
            path="/biomedicalManagementDashboard/manageDevice/BiomedicalEquipmentResume"
            element={
              <ProtectedRoute
                path="/biomedicalManagementDashboard"
                element={
                  <MainLayout>
                    <BiomedicalEquipmentResume />
                  </MainLayout>
                }
              />
            }
          />

          <Route
            path="/management_direction/banner_admin"
            element={
              <ProtectedRoute
                path="/management_direction"
                element={
                  <MainLayout>
                    <BannerAdmin />
                  </MainLayout>
                }
              />
            }
          />

          <Route
            path="/management_direction"
            element={
              <ProtectedRoute
                path="/management_direction"
                element={
                  <MainLayout>
                    <BannerAdmin />
                  </MainLayout>
                }
              />
            }
          />

          <Route
            path="/marketingPromotionManagement/accompaniment_Module"
            element={
              <ProtectedRoute
                path="/marketingPromotionManagement"
                element={
                  <MainLayout>
                    <AccompanimentModule />
                  </MainLayout>
                }
              />
            }
          />

          <Route
            path="/marketingPromotionManagement/accompaniment_Module/accompaniment"
            element={
              <ProtectedRoute
                path="/marketingPromotionManagement"
                element={
                  <MainLayout>
                    <AccompanimentCreation />
                  </MainLayout>
                }
              />
            }
          />
          <Route
            path="/marketingPromotionManagement/accompaniment_Module/accompanimentPreview"
            element={
              <ProtectedRoute
                path="/marketingPromotionManagement"
                element={
                  <MainLayout>
                    <AccompanimentPreview />
                  </MainLayout>
                }
              />
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
