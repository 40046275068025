import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; 
import navigationSlice from "./slices/navigationSlice";
import userSlice from "./slices/userSlice";
import utilsDataSlice from "./slices/utilsDataSlice";
import breadCrumbSlice from "./slices/breadCrumbSlice";
import evaluationSlice from "./slices/evaluationSlice";
import userDataSlice from "./slices/userDataSlice";
import reloadSurveysSlice from "./slices/reloadSurveysSlice";
import biomedicalSlice from "./slices/biomedicalSlice";
import acompanimentSlice from "./slices/accompanimentSlice"

const rootReducer = combineReducers({
  user: userSlice,
  navigation: navigationSlice,
  utilsData: utilsDataSlice,
  breadCrumbData: breadCrumbSlice,
  evaluationData: evaluationSlice,
  userData: userDataSlice,
  reload: reloadSurveysSlice,
  biomedical: biomedicalSlice,
  acompaniment: acompanimentSlice
});

const persistConfig = {
  key: "root",
  storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer
});

export const persistor = persistStore(store);
export default store;
