import React from "react";
import BreadCrumbComp from "../../../components/breadCrumb";
import InventoryAccompanimentImprovementTable from "./InventoryAccompanimentImprovementTable";

const AccompanimentModule = () => {
  return (
    <div className="App">
      <BreadCrumbComp />
      <div className="searchTitle">
        <h1>ACOMPAÑAMIENTO Y MEJORA</h1>
      </div>
      <InventoryAccompanimentImprovementTable />
    </div>
  );
};

export default AccompanimentModule;
