// FormRadioButton.jsx
import React from "react";
import { Controller } from "react-hook-form";
import { RadioButton } from "primereact/radiobutton";
import { styled } from "@mui/material/styles";
import "./styles.css";

const FormRadioButton = ({
  name,
  control,
  label,
  options,
  onChange,
  defaultValue,
  disabled,
  error,
  flexible = false
}) => {
  const StyledRadioButton = styled(RadioButton)(() => ({
    margin: "5px 5px 5px 5px"
  }));

  return (
    <div style={{ minWidth: 120 }}>
      <p className="detail-label">{label}</p>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue || ""}
        render={({ field }) => (
          <div className="flex flex-wrap ">
            <div className={`${flexible ? 'HContainer' :"IHContainer"}`}>
              {options.map((option, index) => (
                <div key={index} className="flex align-center">
                  <StyledRadioButton
                    inputId={`${name}-${option.value}`}
                    {...field}
                    value={option.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                      onChange && onChange(e.value);
                    }}
                    checked={field.value === option.value}
                    disabled={disabled}
                  />
                  <label className="w-max-content" htmlFor={`${name}-${option.value}`}>
                    {option.label}
                  </label>
                </div>
              ))}
            </div>
            {error && <p className="error-message">{error.message}</p>}
          </div>
        )}
      />
    </div>
  );
};

export default FormRadioButton;
