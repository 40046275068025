import React, { useEffect, useState } from "react";
import BreadCrumbComp from "../../components/breadCrumb";
import ManagmentBanner from "../../components/managmentBanner";
import TreatmentDetailsTab from "./treatmentDetails";
import MonthRegisterTab from "./monthRegister/index.js";
import RiskStatusTab from "./riskStatus/index.js";
import TabComponent from "../../components/tabComponent";
import { useLocation } from "react-router-dom";
import RiskDetailsTab from "./riskDetails/index.js";
import { formatDate } from "../../utils/dateUtils.js";
import { isActiveProcessByRole } from "./authorizeFxs.js";
import { useSelector } from "react-redux";
import {
  callApiAsync,
  getCallRisk,
  postUpdateRiskUpdateDate
} from "../../services/apiService.js";
import HeaderBanner from "../../components/headerBanner/index.js";
import { headerBannerType } from "../../constants/enums.js";

const RiskDetails = () => {
  const userData = useSelector((state) => state.userData.userData);
  const [risk, setRisk] = useState(null);
  const location = useLocation();
  const { rowData } = location.state || {};
  const [isEditionAvailable, setisEditionAvailable] = useState(false);

  const updateRiskupdateDate = async () => {
    const dataToSend = { riskId: risk.id };
    await callApiAsync(
      () => postUpdateRiskUpdateDate(dataToSend),
      () => {
        getRisk();
      }
    );
  };

  const getRisk = async () => {
    callApiAsync(
      () => getCallRisk(rowData.rmId),
      (data) => {
        setRisk(data);
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1;
        const currentYear = currentDate.getFullYear();
        if (data.month !== currentMonth || data.year !== currentYear) {
          setisEditionAvailable(false);
        } else {
          setisEditionAvailable(true);
          var isActiveByProcess = isActiveProcessByRole(
            userData.roleList,
            data.processId
          );
          setisEditionAvailable(isActiveByProcess);
        }
      }
    );
  };

  useEffect(() => {
    getRisk();
  }, []);

  const tabData = [
    {
      name: "Detalles del Riesgo",
      id: "0",
      content: (
        <RiskDetailsTab
          data={risk}
          getRisk={getRisk}
          isEditionAvailable={isEditionAvailable}
          updateRiskupdateDate={updateRiskupdateDate}
        />
      )
    },
    {
      name: "Detalles del Tratamiento",
      id: "1",
      content: (
        <TreatmentDetailsTab
          data={risk}
          getRisk={getRisk}
          isEditionAvailable={isEditionAvailable}
          updateRiskupdateDate={updateRiskupdateDate}
        />
      )
    },
    {
      name: "Estatus del Riesgo",
      id: "2",
      content: (
        <RiskStatusTab
          data={risk}
          getRisk={getRisk}
          isEditionAvailable={isEditionAvailable}
          updateRiskupdateDate={updateRiskupdateDate}
        />
      )
    },
    {
      name: "Registro por Mes",
      id: "3",
      content: (
        <MonthRegisterTab
          data={risk}
          getRisk={getRisk}
          isEditionAvailable={isEditionAvailable}
          updateRiskupdateDate={updateRiskupdateDate}
        />
      )
    }
  ];

  return (
    <div>
      <BreadCrumbComp />
      <HeaderBanner type={headerBannerType.riskAndOportunity} />
      <div className="gm-header">
        <div className="HContainer">
          <h1 className="gm-header-title-grey" style={{ marginRight: "15px" }}>
            N°: R-{risk?.risk_key} - {risk?.title}
          </h1>
          <h1 className="gm-header-title">{risk?.processName}</h1>
        </div>
        <div className="gm-header">
        <div className="j-space-between mb-10">
          <p className="lastC-text">Última Actualización:</p>
          <p className="li-item">{risk ? formatDate(risk?.updateDate) : ""}</p>
        </div>
      </div>
      </div>
      <TabComponent panels={tabData} />
    </div>
  );
};

export default RiskDetails;
