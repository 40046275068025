import React, { useEffect, useState } from "react";
import ModalComponent from "../../../components/modals";
import SimpleTable from "../../../components/tables/simpleTable";
import { commentsSurveyTable } from "./columns";
import { icons } from "../../../constants";
import { callApiAsync, getPriorCommitment } from "../../../services/apiService";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const CommentModal = ({ open, handleClose, tableData, ...otherProps }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const ToolTipDescription = (text) => {
    return (
      <div>
        <div>
          <p className="tooltip-text">{text}</p>
        </div>
      </div>
    );
  };

  return (
    <ModalComponent
      open={open}
      handleClose={handleClose}
      title="Observaciones y compromiso de mejora previos"
    >
      <div>
        <SimpleTable
          data={tableData || []}
          columns={commentsSurveyTable(dispatch)}
          text={""}
          tooltipFragment={ToolTipDescription}
        />
      </div>
    </ModalComponent>
  );
};

export default CommentModal;
