import React, { useEffect, useState } from "react";
import { accompanimentResumePageAndMenuColumns } from "./columns";
import SimpleTable from "../../../components/tables/simpleTable";
import { Grid, useMediaQuery } from "@mui/material";
import { icons } from "../../../constants";
import LaddaButton from "../../../components/buttons/laddaButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormTextField from "../../../components/textField/formTextField";
import SimpleSelect from "../../../components/selects/simpleSelect";
import FormCalendar from "../../../components/calendar/formCalendar";
import { acompanimentStatus } from "../../../constants/enums";
import { actualYearSelect } from "../../../services/dateService";
import { getCurrentYear } from "../../../utils/dateUtils";
import { useNavigate } from "react-router-dom";
import {
  callApiAsync,
  getAccompanimentList
} from "../../../services/apiService";
import { getFilterSelectsData } from "./acompanimentCreationApiCall";
import { useDispatch, useSelector } from "react-redux";
import { resetPreviewId } from "../../../redux/slices/accompanimentSlice";
import { isRoleUtil } from "../../../utils/AuthorizedUtils/360Report";

const InventoryAccompanimentImprovementTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userData = useSelector((state) => state.userData.userData);

  const [selectedData, setSelectedData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const validationSchema = {};

  var isAccompanimentViewver = isRoleUtil(
    "ACCOMPANIMENT_VIEWER",
    userData.roleList
  );

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const isMobile = useMediaQuery("(max-width:600px)");

  const handleFilter = () => {
    const filters = getValues();
    getTableData(
      filters.date || null,
      filters.year || getCurrentYear(),
      filters.executive || null,
      filters.manager || null,
      filters.id || null,
      isAccompanimentViewver ? acompanimentStatus.ended : null
    );
  };

  const handleClearFilters = () => {
    reset({
      date: null,
      year: getCurrentYear(),
      executive: "",
      manager: "",
      id: ""
    });
    handleFilter();
  };

  const getTableData = async (
    dateFilter,
    yearFilter,
    userExecutiveId,
    userLeaderId,
    keyAccompaniment,
    status
  ) => {
    await callApiAsync(
      () =>
        getAccompanimentList(
          dateFilter,
          yearFilter,
          userExecutiveId,
          userLeaderId,
          keyAccompaniment,
          status
        ),
      (data) => {
        setTableData(data);
      }
    );
  };

  useEffect(() => {
    dispatch(resetPreviewId());
    reset({ year: getCurrentYear() });
    getFilterSelectsData(
      setFilterData,
      isAccompanimentViewver ? userData.id : null
    );
    setValue("year", getCurrentYear());

    if (isAccompanimentViewver) {
      setValue("executive", userData.id);
      getTableData(
        null,
        null,
        userData.id,
        null,
        null,
        acompanimentStatus.ended
      );
    } else {
      getTableData(null, null, null, null, null, null);
    }
  }, []);

  return (
    <div className="table-container">
      <div class="HContainer j-space-between">
        <h1 class="title-bio-details-chard">
          Inventario de Acompañamiento y mejora
        </h1>

        {!isAccompanimentViewver && (
          <LaddaButton
            variant="contained"
            height={"32px"}
            onClick={() =>
              navigate(
                "/marketingPromotionManagement/accompaniment_Module/accompaniment",
                { state: { id: 0 } }
              )
            }
          >
            <div className="IHContainer align-center">
              <img
                className="btn-icon"
                src={icons.addPlus}
                alt="icon"
                style={{ marginRight: "10px" }}
              />
              <p className="button_text">Crear Acompañamiento</p>
            </div>
          </LaddaButton>
        )}
      </div>

      <div className="mv-25">
        <form className="mb-10 mt-25">
          <Grid container spacing={1}>
            <Grid item xs={12} md={2}>
              <FormCalendar
                name="date"
                control={control}
                label=""
                defaultValue={null}
                formLabel=""
                error={errors.date}
                margin={"0"}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <SimpleSelect
                name="executive"
                control={control}
                label="Ejectivo"
                options={filterData?.executiveList || []}
                margin={"0"}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <SimpleSelect
                name="manager"
                control={control}
                label="Gerente"
                options={filterData?.leaderList || []}
                margin={"0"}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <FormTextField
                styledlabel={"ID"}
                name="id"
                control={control}
                error={errors.module}
                margin={"0"}
              />
            </Grid>

            <Grid item xs={12} md={2}>
                <SimpleSelect
                  name="year"
                  control={control}
                  label="Año"
                  options={filterData?.yearList || []}
                margin={'0'}

                />
          
            </Grid>

            <Grid item xs={12} md={2}>
              <div
                className={`IHContainer `}
              >
                <LaddaButton
                  variant="contained"
                  onClick={handleFilter}
                  margin={"0"}
                >
                  <img
                    className="btn-icon"
                    src={icons.filterWhite}
                    alt="icon"
                  />
                </LaddaButton>
                <LaddaButton
                  variant="contained"
                  onClick={handleClearFilters}
                  margin={"0 0 0 10px"}
                >
                  <img
                    className="btn-icon"
                    src={icons.clearFilterWhite}
                    alt="icon"
                  />
                </LaddaButton>
              </div>
            </Grid>
          </Grid>
        </form>
      </div>

      <SimpleTable
        data={tableData}
        columns={accompanimentResumePageAndMenuColumns(navigate)}
        text={""}
      />
    </div>
  );
};

export default InventoryAccompanimentImprovementTable;
