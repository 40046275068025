import React, { useEffect, useState } from "react";
import FormTextField from "../../../components/textField/formTextField";
import { Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ModalComponent from "../../../components/modals";
import LaddaButton from "../../../components/buttons/laddaButton";
import ToastNotification from "../../../components/toastNotification";
import {
  callApiAsync,
  postUpdateEvidence,
  postUpdateRiskAction
} from "../../../services/apiService";

yup.addMethod(yup.string, "serverOrUrl", function (message) {
  return this.test("serverOrUrl", message, function (value) {
    const { path, createError } = this;

    if (!value) {
      return createError({ path, message: "Este campo es obligatorio" });
    }

    const combinedRegex =
      /^(https?:\/\/[\w\-]+(\.[\w\-]+)+[/#?]?.*)|(^\\\\[\w.\-]+(\\[^\\/:*?"<>|]+)+$)/u;

    if (combinedRegex.test(value)) {
      return true;
    }

    return createError({
      path,
      message:
        "Debe ser un enlace válido o una ruta UNC (\\Servidor\\Carpeta\\Archivo)"
    });
  });
});

const validationSchema = yup.object().shape({
  treatmentAction: yup.string().required("Este campo es obligatorio"),
  evidences: yup.string().serverOrUrl().required("Este campo es obligatorio")
});

const MonthRegisterModal = ({
  riskId,
  riskData,
  open,
  handleClose,
  registrationId,
  isEditable,
  monthSelected,
  registrationData,
  getRisk,
  selectedData,
  getRiskEvidence,
  updateRiskupdateDate
}) => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {}
  });

  useEffect(() => {
    if (registrationId == 0) return;
    loadData();
  }, [registrationData, open]);

  const handleCloseModal = () => {
    clearData();
    reset({
      treatmentAction: registrationData.treatmentAction || "",
      evidence: registrationData.evidence || ""
    });
    handleClose();
  };

  const loadData = () => {
    setValue(
      "treatmentAction",
      selectedData?.action ? selectedData.action : ""
    );
    setValue("evidences", registrationData.evidence);
  };

  const clearData = () => {
    setValue("treatmentAction", "");
    setValue("evidences", "");
  };

  const updateEvidence = async (formData) => {
    const objRequest = {
      id: selectedData?.id ? selectedData.id : 0,
      risk_month_id: riskData.riskMonthId,
      month: riskData.month,
      action: formData.treatmentAction,
      state: riskData.state,
      risk_level: riskData.riskLevel,
      evidence: formData.evidences
    };

    callApiAsync(
      () => postUpdateRiskAction(objRequest),
      () => {
        updateRiskupdateDate();
        getRisk();
        getRiskEvidence();
      }
    );
  };

  const onSubmit = async (formData) => {
    updateEvidence(formData);
    handleClose();
    return;
  };

  return (
    <div>
      <ToastNotification
        severity="success"
        summary={toastMessage}
        detail=""
        position="top-center"
        showToast={showToast}
        setShowToast={setShowToast}
      />
      <ModalComponent
        open={open}
        handleClose={handleCloseModal}
        title={""}
        actions={
          <div className="w-complete mv-20">
            <div className="j-center">
              {isEditable && (
                <LaddaButton
                  variant="contained"
                  onClick={handleSubmit(onSubmit)}
                >
                  <div className="HContainer align-center">
                    <p className="button_text">Guardar Cambios</p>
                  </div>
                </LaddaButton>
              )}
            </div>
          </div>
        }
      >
        <form>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <p className="detail-label mt-20">Acción de Tratamiento:</p>
              <FormTextField
                name="treatmentAction"
                control={control}
                isTextArea={true}
                error={errors.treatmentAction}
                maxLength={500}
                rows={1}
                padding={"6.5px"}
                disabled={!isEditable}
                margin={"0"}
              />
            </Grid>
            <Grid item xs={12}>
              <p className="detail-label mt-10">Evidencias:</p>
              <FormTextField
                name="evidences"
                control={control}
                isTextArea={true}
                error={errors.evidences}
                maxLength={500}
                rows={1}
                padding={"6.5px"}
                disabled={!isEditable}
                margin={"0"}
              />
            </Grid>
          </Grid>
        </form>
      </ModalComponent>
    </div>
  );
};

export default MonthRegisterModal;
