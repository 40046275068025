import React, { useEffect, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import LaddaButton from "../../components/buttons/laddaButton";
import { Grid, useMediaQuery } from "@mui/material";
import { icons } from "../../constants";
import MainLayout from "../../layouts/mainLayout/index";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import PowerBiTab from "./tabs/powerBiTab";

const MarketingPromotionManagement = () => {
  const [powerBiTab, setPowerBiTab] = useState("1");

  const isSmallScreen = useMediaQuery("(max-width:590px)");
  const isMediumScreen = useMediaQuery("(max-width:900px)");
  const location = useLocation();
  const { powerBi } = useParams();
  const [searchParams] = useSearchParams();

  const powerBiFromURL = searchParams.get("powerBi");
  const powerBiFromState = location.state?.powerBi;

  useEffect(() => {
    if (powerBiFromURL) {
      setPowerBiTab(powerBiFromURL);
    } else if (powerBiFromState) {
      setPowerBiTab(powerBiFromState.toString());
    } else if (powerBi) {
      setPowerBiTab(powerBi);
    } else {
      setPowerBiTab("1");
    }
  }, [powerBiFromURL, powerBiFromState, powerBi]);

  const powerbiList = [
    { id: 1, name: "Urología" },
    { id: 2, name: "SISMED" },
    { id: 3, name: "Plataforma Médicos" }
  ];

  let buttonHeight = "60px";

  if (isMediumScreen) {
    buttonHeight = "40px";
  } else if (isSmallScreen) {
    buttonHeight = "35px";
  }

  return (
    <div className="VContainer j-center">
      <MainLayout>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4}>
            <LaddaButton
              variant="contained"
              onClick={() => {
                setPowerBiTab("1");
              }}
              height={buttonHeight}
              borderRadius={"15px"}
              width={"100%"}
            >
              <div className="IHContainer align-center">
                <img
                  className="btn-icon"
                  src={icons.pcMonitor}
                  alt="icon"
                  style={{ marginRight: "10px" }}
                />
                <p className="button_text">Urología</p>
              </div>
            </LaddaButton>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <LaddaButton
              variant="contained"
              color={"#404040"}
              backgroundColor={"#F4D658"}
              onClick={() => {
                setPowerBiTab("2");
              }}
              width={"100%"}
              borderRadius={"15px"}
              height={buttonHeight}
            >
              <div className="IHContainer align-center">
                <img
                  className="btn-icon"
                  src={icons.verificationList}
                  alt="icon"
                  style={{ marginRight: "10px" }}
                />
                <p className="button_text">SISMED</p>
              </div>
            </LaddaButton>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <LaddaButton
              variant="contained"
              onClick={() => {
                setPowerBiTab("3");
              }}
              height={buttonHeight}
              borderRadius={"15px"}
              width={"100%"}
            >
              <div className="IHContainer align-center">
                <img
                  className="btn-icon"
                  src={icons.contract}
                  alt="icon"
                  style={{ marginRight: "10px" }}
                />
                <p className="button_text">Plataforma Médicos</p>
              </div>
            </LaddaButton>
          </Grid>
        </Grid>
      </MainLayout>

      <TabContext value={powerBiTab}>
        {powerbiList.map((item, index) => {
          return (
            <TabPanel value={(index + 1).toString()}>
              <PowerBiTab powerBiData={item} />
            </TabPanel>
          );
        })}
      </TabContext>
    </div>
  );
};

export default MarketingPromotionManagement;
