import React, { useEffect, useState } from "react";
import ModalComponent from "../../../components/modals";
import LaddaButton from "../../../components/buttons/laddaButton";
import { Grid } from "@mui/material";
import FormTextField from "../../../components/textField/formTextField";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { callApiAsync, postUpdateReport360Observations } from "../../../services/apiService";

const validationSchema = yup.object().shape({
  observations: yup.string().required("Este campo es obligatorio"),
});

const ObservationsModal = ({
  open,
  handleClose,
  observationsData,
  getObservationsData,
  userIdSelected,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {},
  });

  useEffect(() => {
    if (!open) return;
    loadData();
  }, [open]);

  const handleCloseModal = () => {
    clearData();
    reset();
    handleClose();
  };

  const loadData = () => {
    setValue("observations", observationsData?.observations);
  };

  const clearData = () => {
    setValue("observations", "");
  };

  const updateForm = async (formData) => {
    const objRequest = {
      id: observationsData.id,
      userId: userIdSelected,
      observations: formData.observations,
    };

    callApiAsync(
      () => postUpdateReport360Observations(objRequest),
      () => {
        getObservationsData();
        handleClose();
      }
    );
  };

  return (
    <div>
      <ModalComponent
        open={open}
        handleClose={handleCloseModal}
        title={""}
        width={'600px'}
        actions={
          <div className="j-center w-complete">
            <LaddaButton width={'200px'} variant="contained" onClick={handleSubmit(updateForm)}>
              <div className="HContainer align-center">
                <p className="button_text">Guardar Cambios</p>
              </div>
            </LaddaButton>
          </div>
        }
      >
        <form>
          <Grid container justifyContent="center" spacing={2}>
            <div style={{ marginTop: "25px", width: "90%" }}>
              <Grid item xs={12}>
                <p className="detail-label mt-25">Observaciones:</p>
                <FormTextField
                  name="observations"
                  control={control}
                  isTextArea={true}
                  error={errors.observations}
                  maxLength={500}
                  rows={5}
                  margin={0}
                />
              </Grid>
            </div>
          </Grid>
        </form>
      </ModalComponent>
    </div>
  );
};

export default ObservationsModal;
