import React, { useState } from "react";
import ModalComponent from "../../../components/modals";
import LaddaButton from "../../../components/buttons/laddaButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Grid } from "@mui/material";
import FormTextField from "../../../components/textField/formTextField";
import { callApiAsync, postAddAccessory } from "../../../services/apiService";
import ToastNotification from "../../../components/toastNotification";

const validationSchema = yup.object().shape({
  newAccessory: yup
    .string()
    .required("Este campo es obligatorio")
    .min(1, "Este campo no puede estar vacío")
});

const AddAccessory = ({
  open,
  close,
  setAccessoryOptions,
  brandIdSelected,
  accessoryOptions
}) => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const {
    control,
    getValues,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const handleClose = () => {
    reset();
    close();
  };

  const onSubmit = (formData) => {
    const newAccessoryName = formData.newAccessory.trim().toLowerCase();

    if (!accessoryOptions || accessoryOptions.length === 0) {
      setToastMessage("Error: no hay opciones de accesorios disponibles.");
      setShowToast(true);
      return;
    }

    const isDuplicate = accessoryOptions.some(
      (accessory) => accessory.name.trim().toLowerCase() === newAccessoryName
    );

    if (isDuplicate) {
      setToastMessage("Ya existe un accesorio con este nombre.");
      setShowToast(true);
      return;
    }

    callApiAsync(
      () =>
        postAddAccessory({
          name: formData.newAccessory,
          brandId: brandIdSelected
        }),
      (data) => {
        if (data.answer.result === true) {
          setAccessoryOptions(data.answer.list);
          handleClose();
        } else {
          setToastMessage("Error al agregar el accesorio.");
          setShowToast(true);
        }
      }
    );
  };

  return (
    <>
      <ToastNotification
        severity="warn"
        summary={toastMessage}
        detail=""
        showToast={showToast}
        setShowToast={setShowToast}
      />
      <ModalComponent
        open={open}
        title="Agregar Nuevo Accesorio"
        handleClose={handleClose}
        actions={<></>}
      >
        <form>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <p className="create-label ml-0">Nuevo accesorio:</p>
              <FormTextField
                name="newAccessory"
                control={control}
                error={errors.newAccessory}
                margin={"0"}
              />
            </Grid>
          </Grid>
          <div className="j-center">
            <LaddaButton
              fontSize={"12px"}
              height={"32px"}
              variant="contained"
              margin={"20px 0"}
              onClick={handleSubmit(onSubmit)}
            >
              <p className="button_text">Crear Accesorio</p>
            </LaddaButton>
          </div>
        </form>
      </ModalComponent>
    </>
  );
};

export default AddAccessory;
