import React, { useEffect, useState } from "react";
import BreadCrumbComp from "../../components/breadCrumb";
import SimpleSelect from "../../components/selects/simpleSelect";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Grid, useMediaQuery } from "@mui/material";
import FormTextField from "../../components/textField/formTextField";
import LaddaButton from "../../components/buttons/laddaButton";
import GestionProccessBiomedicalBanner from "../../components/topBanners/gestionProccessBiomedical";
import FormCalendar from "../../components/calendar/formCalendar";
import AccesorySection from "./equipementResumeSection/accesorySection";
import TechnicalFeaturesSection from "./equipementResumeSection/technicalFeaturesSection";
import {
  callApiAsync,
  getCallInstitutionById,
  getCallInstitutionList,
  getCallResumeByBioId,
  getCallResumeSelects,
  postEquipementResume
} from "../../services/apiService";
import { useSelector } from "react-redux";
import ImageUploadButton from "../../components/ImageUploadBtn";
import InstitutionSection from "./equipementResumeSection/institutionSection";
import { icons } from "../../constants";
import AddInstitution from "./modals/addInstitution";
import ToastNotification from "../../components/toastNotification";
import { useNavigate } from "react-router-dom";
import { formatCurrentDate } from "../../components/tables/columns";

const urlApi = process.env.REACT_APP_API_URL;

const validationSchema = yup.object().shape({
  equipmentName: yup.string(),
  brand: yup.string(),
  model: yup.string(),
  riskClassification: yup.string()
});

const defaultValues = {
  equipmentName: "",
  brand: "",
  model: "",
  producer: "",
  riskClassification: "",
  deliveryDate: formatCurrentDate()
};

const BiomedicalEquipmentResume = () => {
  const navigate = useNavigate();

  const [openAddAccessory, setOpenAddAccessory] = useState(false);
  const [technicalFeaturesData, setTechnicalFeaturesData] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);
  const [institutionOptions, setInstitutionOptions] = useState([]);
  const [institutionData, setInstitutionData] = useState(null);
  const [accessoriesBrandOptions, setAccessoriesBrandOptions] = useState([]);
  const [classificationRiskOptions, setClassificationRiskOptions] = useState(
    []
  );
  const [producerOptions, setProducerOptions] = useState([]);
  const [importerOptions, setImporterOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [brandIdSelected, setBrandIdSelected] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    severity: "success",
    summary: "",
    detail: ""
  });
  const [institutionId, setInstitutionId] = useState(null);
  const [isnew, setNew] = useState(null);

  const biomedicalId = useSelector((state) => state.biomedical.biomedicalId);
  const isSmallScreen = useMediaQuery("(max-width:590px)");

  const onChangeBrand = () => {
    var brandIdCurrent = getValues("brand");
    setBrandIdSelected(brandIdCurrent);
  };
  const handleCloseAddInstitution = () => {
    setOpenAddAccessory(false);
  };

  const getEquipmentDataById = () => {
    callApiAsync(
      () => getCallResumeByBioId(biomedicalId),
      (data) => {
        loadData(data);
        institutionById();
      }
    );
  };

  async function blobUrlToFile(blobUrl) {
    const response = await fetch(blobUrl);
    const blob = await response.blob();
    return new File([blob], "image.jpg", { type: blob.type });
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
    defaultValues
  });

  const institutionValue = watch("institution");

  const onSubmit = async (formData) => {
    const formDataToSend = new FormData();
    formDataToSend.append("institutionId", formData.institution);
    formDataToSend.append("bioId", biomedicalId);
    formDataToSend.append("bioName", formData.equipmentName);
    formDataToSend.append("bioBrandId", formData.brand);
    formDataToSend.append("bioModel", formData.model);
    formDataToSend.append("bioOriginPlace", formData.originPlace);
    formDataToSend.append("bioProducerId", formData.producer);
    formDataToSend.append(
      "bioRiskClassificationId",
      formData.riskClassification
    );
    formDataToSend.append("bioRecommendations", formData.recommendations);
    formDataToSend.append("bioWarranty", formData.warranty);
    formDataToSend.append("bioMainTechnology", formData.predominantTechnology);
    formDataToSend.append("deliveryDate", formData.deliveryDate);

    if (selectedImage) {
      try {
        const imageFile = await blobUrlToFile(selectedImage);
        formDataToSend.append("image", imageFile);
      } catch (error) {
        console.error("Error converting image to file:", error);
      }
    }

    callApiAsync(
      () => postEquipementResume(formDataToSend),
      (data) => {
        setToastConfig({
          severity: "success",
          summary: "Hoja de vida",
          detail: "Se ha guardado con éxito."
        });
        setShowToast(true);
        navigate("/biomedicalManagementDashboard/manageDevice");
      }
    );
  };

  const handleTechnicalFeaturesChange = (updatedFeatures) => {
    setTechnicalFeaturesData(updatedFeatures);
  };

  const getResumeSelects = () => {
    callApiAsync(
      () => getCallResumeSelects(),
      (data) => {
        setBrandOptions(data.brands);
        setClassificationRiskOptions(data.riskClassifications);
        setProducerOptions(data.producers);
        setImporterOptions(data.importers);
        setAccessoriesBrandOptions(data.accessoriesBrand);
        setDepartmentOptions(data.departments);
      }
    );
  };

  const getInstitutionSelect = () => {
    callApiAsync(
      () => getCallInstitutionList(),
      (data) => {
        setInstitutionOptions(data.institutions);
      }
    );
  };

  const institutionById = () => {
    if (getValues("institution") != null) {
      callApiAsync(
        () => getCallInstitutionById(getValues("institution")),
        (data) => {
          setInstitutionData(data.institution);
        }
      );
    }
  };

  useEffect(() => {
    setInstitutionId(institutionValue);
  }, [institutionValue]);

  useEffect(() => {
    getEquipmentDataById();
  }, [biomedicalId]);

  useEffect(() => {
    getResumeSelects();
    getInstitutionSelect();
  }, []);

  const handleImageChange = (file) => {
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
    }
  };

  const loadData = (resumeData) => {
    setValue("serieName", resumeData.serieName);
    setValue("institution", resumeData.institutionId);
    setInstitutionData(resumeData.institution);
    setValue(
      "deliveryDate",
      resumeData.deliveryDate ? new Date(resumeData.deliveryDate) : null
    );

    setValue("equipmentName", resumeData.name);
    setValue("producer", resumeData.producerId);
    setValue("brand", resumeData.brandId);
    setBrandIdSelected(resumeData.brandId);
    setValue("model", resumeData.model);
    setValue("originPlace", resumeData.originPlace);
    setValue("warranty", resumeData.warranty);
    setValue("riskClassification", resumeData.riskClassificationId);
    setValue("predominantTechnology", resumeData.mainTechnology);
    setValue("recommendations", resumeData.recommendations);
    if (resumeData.imagePath) {
      const correctedImagePath = resumeData.imagePath.replace(/\\/g, "/");
      const newImagePath = correctedImagePath.replace(
        "biomedicalEquipment/",
        ""
      );
      const finalImageUrl = `${urlApi}${newImagePath}`;
      setSelectedImage(finalImageUrl);
    } else {
      setSelectedImage("");
    }
  };

  const handleInstitutionSave = (newInstitution) => {
    setInstitutionOptions((prevOptions) => {
      const updatedOptions = prevOptions.filter(
        (option) => option.value !== newInstitution.id
      );
      return [
        ...updatedOptions,
        { value: newInstitution.id, label: newInstitution.name }
      ];
    });
    setInstitutionId(newInstitution.id);
    setInstitutionData(newInstitution);
  };

  return (
    <div>
      <BreadCrumbComp />
      <div className="searchTitle">
        <h1>HOJA DE VIDA EQUIPO BIOMÉDICO</h1>
      </div>
      <GestionProccessBiomedicalBanner />
      <div className="create-form-container">
        <p className="createRist-title ml-0">HOJA DE VIDA EQUIPO BIOMÉDICO</p>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12} md={6}>
              <p className="create-label ml-0">Número de Serie:</p>
              <FormTextField
                name="serieName"
                control={control}
                label=""
                disabled
                margin={'0'}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="form-group">
                <p className="create-label m-0">Imagen del equipo biomédico</p>
                <ImageUploadButton
                  onImageChange={handleImageChange}
                  buttonText="Agregar Imagen"
                  path={selectedImage}
                />
              </div>
            </Grid>
            <Grid item xs={6} md={6}></Grid>
          </Grid>
          <p className="createRist-title margin20-10 ml-0">
            1. Identificación de la Institución
          </p>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12} md={6}>
              <p className="create-label ml-0">Institución:</p>
              <SimpleSelect
                name="institution"
                control={control}
                options={institutionOptions}
                hideLabel={true}
                onChange={institutionById}
                error={errors.institution}
                margin={'0'}
              />
            </Grid>
            <Grid item xs={12} md={6} className="align-end no-print">
              <LaddaButton
                width={"40px"}
                fontSize={"12px"}
                height={"40px"}
                variant="contained"
                margin={"0"}
                onClick={() => {
                  setNew(true);

                  setOpenAddAccessory(true);
                }}
              >
                <img className="btn-icon" src={icons.plusIcon} alt="icon" />
              </LaddaButton>
              {institutionValue && (
                <LaddaButton
                  width={"40px"}
                  fontSize={"12px"}
                  height={"40px"}
                  variant="contained"
                  margin={"0 5px"}
                  onClick={() => {
                    setNew(false);
                    setInstitutionId(institutionValue);
                    setOpenAddAccessory(true);
                  }}
                >
                  <img className="btn-icon" src={icons.editWhite} alt="icon" />
                </LaddaButton>
              )}
            </Grid>
          </Grid>
          <InstitutionSection
            departmentOptions={departmentOptions}
            institutionData={institutionData}
          />

          <div className="page-break"></div>
          <br />
          <p className="createRist-title margin20-10 print-top-margin ml-0">
            2. Datos del Equipo
          </p>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12} md={6}>
              <p className="create-label ml-0">Nombre del Equipo:</p>
              <FormTextField
                name="equipmentName"
                control={control}
                label=""
                error={errors.equipmentName}
                margin={'0'}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <p className="create-label ml-0">Fabricante:</p>
              <SimpleSelect
                name="producer"
                control={control}
                options={producerOptions}
                error={errors.producer}
                hideLabel={true}
                margin={'0'}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <p className="create-label ml-0">Marca:</p>
              <SimpleSelect
                name="brand"
                control={control}
                options={brandOptions}
                hideLabel={true}
                onChange={onChangeBrand}
                error={errors.brand}
                margin={'0'}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <p className="create-label ml-0">Lugar de Origen:</p>
              <FormTextField
                name="originPlace"
                control={control}
                label=""
                error={errors.originPlace}
                margin={'0'}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <p className="create-label ml-0">Modelo:</p>
              <FormTextField
                name="model"
                control={control}
                label=""
                error={errors.model}
                margin={'0'}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <p className="create-label ml-0">Fecha de Entrega:</p>
              <FormCalendar
                name="deliveryDate"
                control={control}
                label=""
                defaultValue={null}
                formLabel=""
                error={errors.deliveryDate}
                margin={'0'}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <p className="create-label ml-0">Serie:</p>
              <FormTextField
                name="serieName"
                control={control}
                label=""
                error={errors.serie}
                disabled
                margin={'0'}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <p className="create-label ml-0">Garantía:</p>
              <FormTextField
                name="warranty"
                control={control}
                label=""
                error={errors.warranty}
                margin={'0'}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <p className="create-label ml-0">Clasificación por Riesgo:</p>
              <SimpleSelect
                name="riskClassification"
                control={control}
                options={classificationRiskOptions}
                hideLabel={true}
                error={errors.riskClassification}
                margin={'0'}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <p className="create-label ml-0">Tecnología Predominante:</p>
              <FormTextField
                name="predominantTechnology"
                control={control}
                label=""
                error={errors.predominantTechnology}
                margin={'0'}
              />
            </Grid>
          </Grid>
          <p className="createRist-title margin20-10 ml-0">
            3. Accesorios de la Tecnología Biomédica
          </p>
          <AccesorySection brandIdSelected={brandIdSelected} />
          <div className="page-break"></div>

          <TechnicalFeaturesSection />
          <p className="createRist-title margin20-10 ml-0">
            5. Requisitos y Recomendaciones del Fabricante
          </p>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12} md={12}>
              <FormTextField
                name="recommendations"
                control={control}
                label=""
                error={errors.recommendations}
                isTextArea
                margin={'0'}
              />
            </Grid>
          </Grid>
          <div className="Hcontainer j-center white-space-l no-print">
            <LaddaButton variant="contained" type="submit">
              <p className="button_text">Crear Hoja de Vida</p>
            </LaddaButton>

            <LaddaButton
              variant="contained"
              color={"#404040"}
              backgroundColor={"#F4D658"}
              onClick={() => {
                window.print();
              }}
            >
              <p className="button_text">Crear PDF</p>
            </LaddaButton>
          </div>
        </form>
      </div>
      <AddInstitution
        open={openAddAccessory}
        close={handleCloseAddInstitution}
        departmentOptions={departmentOptions}
        getInstitutionSelect={getInstitutionSelect}
        institutionId={institutionId}
        setInstitutionId={setInstitutionId}
        isNew={isnew}
        onSave={handleInstitutionSave}
      />
      <ToastNotification
        severity={toastConfig.severity}
        summary={toastConfig.summary}
        detail={toastConfig.detail}
        showToast={showToast}
        setShowToast={setShowToast}
        position="top-right"
      />
    </div>
  );
};

export default BiomedicalEquipmentResume;
