import React, { useEffect, useState } from "react";
import LaddaButton from "../../../components/buttons/laddaButton";
import { icons } from "../../../constants";
import { CustomTabPanel } from "../../../components/tabComponent";
import ObservationPaiModal from "./observationPaiModal";
import SimpleTable from "../../../components/tables/simpleTable";
import ModalComponent from "../../../components/modals";
import { formatTextWithLineBreaks } from "../../../utils/utilsFunctions";
import {
  callApiAsync,
  getCallPaiObservationData,
} from "../../../services/apiService";

const OdservationsPaiTab = ({ data, getPaiData }) => {
  const [editModal, setEditModal] = useState(false);

  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [detailsData, setDetailsData] = useState([]);
  const [detailData, setDetailData] = useState({});

  const getPaiObservationData = async () => {
    callApiAsync(
      () => getCallPaiObservationData(data.id),
      (data) => {
        setDetailsData(data);
      }
    );
  };
  useEffect(() => {
    getPaiObservationData();
  }, []);

  const handleClose = () => {
    setEditModal(false);
    setOpenDetailsModal(false);
    setEditModal();
  };

  const handleOpenModal = (data) => {
    setEditModal(true);
    setDetailData(data ?? {});
  };

  const handleOpenDetailsModal = (data) => {
    setOpenDetailsModal(true);
    setDetailData(data);
  };

  const tableColumns = [
    { field: "name", header: "Nombre" },
    { field: "id", header: "Id", columnType: "hide" },
    {
      field: "competenciaPaiId",
      header: "competenciaPaiId",
      columnType: "hide",
    },
    { field: "comment", header: "Comentario" },
    { field: "date", header: "Fecha" },
    { field: "actions", header: "", columnType: "actionButtons" },
  ];

  return (
    <div>
      <CustomTabPanel value="3">
        <SimpleTable
          data={detailsData}
          columns={tableColumns}
          buttons={[
            {
              type: "icon",
              icon: icons.eye,
              onClick: handleOpenDetailsModal,
            },
            {
              type: "dateHideButton",
              icon: icons.editBlue,
              onClick: handleOpenModal,
            },
          ]}
        />

        <div className="j-center">
          <LaddaButton
            variant="contained"
            onClick={() => {
              handleOpenModal();
            }}
          >
            <div className="HContainer align-rigth">
              <p className="button_text">Nuevo Comentario</p>
            </div>
          </LaddaButton>
        </div>
      </CustomTabPanel>

      <ModalComponent
        open={openDetailsModal}
        handleClose={handleClose}
        title={""}
      >
        <div>
          <p className="detail-label mt-25">Nombre:</p>
          <p className="content-text pv-10">{detailData.name}</p>
          <p className="detail-label mt-25">Comentario:</p>
          <p className="content-text pv-10">
            {formatTextWithLineBreaks(detailData.comment)}
          </p>
        </div>
      </ModalComponent>
      <ObservationPaiModal
        open={editModal}
        handleClose={handleClose}
        data={detailData}
        actionNumber={1}
        competenciaPaiId={data.id}
        getPaiObservationData={getPaiObservationData}
        getPaiData={getPaiData}
      />
    </div>
  );
};

export default OdservationsPaiTab;
