import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import SimpleTable from "../../../components/tables/simpleTable";
import LaddaButton from "../../../components/buttons/laddaButton";
import ToastNotification from "../../../components/toastNotification";
import {
  callApiAsync,
  getCallResumeFeatureData
} from "../../../services/apiService";
import { technicalFeaturesColumns } from "../columns";
import { useDispatch, useSelector } from "react-redux";
import { setBiomedicalId } from "../../../redux/slices/biomedicalSlice";
import { icons } from "../../../constants";
import SelectFeatureModal from "../modals/selectFeatureModal";

const TechnicalFeaturesSection = () => {
  const dispatch = useDispatch();

  const [featuresData, setFeaturesData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);

  const biomedicalId = useSelector((state) => state.biomedical.biomedicalId);

  const getResumeFeatureData = () => {
    callApiAsync(
      () => getCallResumeFeatureData(biomedicalId),
      (data) => {
        setFeaturesData(data.table);
      }
    );
  };

  useEffect(() => {
    getResumeFeatureData();
  }, []);

  const hadleClose = () => {
    setOpenModal(false);
  };

  return (
    <div>
      <ToastNotification
        severity="warn"
        summary={toastMessage}
        detail=""
        showToast={showToast}
        setShowToast={setShowToast}
      />
   <br />
      <p className="createRist-title margin20-10 ml-0">
        4. Características Técnicas
      </p>

      <div className="mt-15">
        <div className="j-end no-print">
          <LaddaButton
            width={"40px"}
            fontSize={"12px"}
            height={"40px"}
            variant="contained"
            margin={"0px 0px 0px 25px"}
            onClick={() => {
              setOpenModal(true);
            }}
          >
            <img className="btn-icon" src={icons.plusIcon} alt="icon" />
          </LaddaButton>
        </div>

        <div className="mt-15">
          <SimpleTable
            data={featuresData}
            columns={technicalFeaturesColumns}
            scrollHeight={"300px"}
            text={"Características Técnicas Agregadas"}
            margin={'0'}
            buttons={[
              {
                type: "icon",
                icon: icons.editYellow,
                onClick: (data) => {
                  setSelectedRow(data);
                  setOpenModal(true);
                }
              }
            ]}
          />
        </div>
      </div>
      <SelectFeatureModal
        open={openModal}
        close={hadleClose}
        featuresData={featuresData}
        getResumeFeatureData={getResumeFeatureData}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        biomedicalId={biomedicalId}
      />
    </div>
  );
};

export default TechnicalFeaturesSection;
