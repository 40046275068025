import React, { useEffect, useState } from "react";
import BreadCrumbComp from "../../components/breadCrumb";
import SimpleTable from "../../components/tables/simpleTable";
import {
  superior_rol_A_BurnData,
  superior_rol_B_BurnData,
  superior_rol_C_BurnData
} from "../../utils/burnData";
import LaddaButton from "../../components/buttons/laddaButton";
import { icons } from "../../constants";
import { API_URL } from "../../config";
import SearchBar from "../../components/searchBar";
import { useForm } from "react-hook-form";
import SimpleSelect from "../../components/selects/simpleSelect";
import { Link, useNavigate } from "react-router-dom";
import { getCookie } from "../../utils/getCookie";
import axios from "axios";
import StudyMaterialsModal from "./studyMaterialsModal";
import { getDateObj } from "../../utils/dateUtils";
import ModalComponent from "../../components/modals";
import "./styles.css";
import {
  callApiAsync,
  getActivityEvidenceByUser,
  getCallActivitiesByCompetency,
  getCallAllEmployeesInMonitoring,
  getCallEmployeesInMonitoring,
  getCallPositionList,
  getCallSelfAppraisal,
  postSaveActivityEvidence
} from "../../services/apiService";
import { isAdmin, isGeneralManagment } from "./authorizeFunctions";
import { useDispatch, useSelector } from "react-redux";
import ModalEditComment from "./employeesMonitored/commentModal";
import { actualYearSelect } from "../../services/dateService";
import { changeDirectorGestionBtnState } from "../../redux/slices/navigationSlice";
import FormTextField from "../../components/textField/formTextField";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Grid } from "@mui/material";

const SuperiorRolDashboard = () => {
  const userData = useSelector((state) => state.userData.userData);
  const navigate = useNavigate();
  const userSesion = getCookie("sesion");
  const currentUserId = userSesion.id_usuario;
  const { control, getValues, setValue } = useForm();
  const [userInfo, setUserInfo] = useState("");
  const [seftTable, setSeftTable] = useState([]);
  const [allSeftTable, setAllSeftTable] = useState([]);
  const [allSeftTableFiltered, setAllSeftTableFiltered] = useState([]);
  const [openStudyMaterialsModal, setOpenStudyMaterialsModal] = useState(false);
  const [modalTableData, setModalTableData] = useState({});
  const [openModalEditComment, setOpenModalEditComment] = useState(false);
  const [currentCommentRow, setCurrentCommentRow] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [activitiesTable, setActivitiesTable] = useState([]);
  const [mediaModal, setMediaModal] = useState(false);
  const [mediaData, setMediaData] = useState({});
  const [positionList, setPositionList] = useState([]);
  const [evidenceData, setEvidenceData] = useState({});

  yup.addMethod(yup.string, "serverOrUrl", function (message) {
    return this.test("serverOrUrl", message, function (value) {
      const { path, createError } = this;

      if (!value) {
        return createError({ path, message: "Este campo es obligatorio" });
      }

      const combinedRegex =
        /^(https?:\/\/[\w\-]+(\.[\w\-]+)+[/#?]?.*)|(^\\\\[\w.\-]+(\\[^\\/:*?"<>|]+)+$)/u;

      if (combinedRegex.test(value)) {
        return true;
      }

      return createError({
        path,
        message:
          "Debe ser un enlace válido o una ruta UNC (\\Servidor\\Carpeta\\Archivo)"
      });
    });
  });

  const validationSchema = yup.object().shape({
    evidence: yup
      .string()
      .serverOrUrl("Por favor ingresa una URL válida")
      .required("Este campo es obligatorio")
  });

  const {
    control: controlForm,
    getValues: getValuesForm,
    setValue: setValueForm,
    handleSubmit: handleSubmitForm,
    formState: { errors: errorsForm },
    reset
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const handleChangeFilter = () => {
    var positionSelected = getValues().position;
    var searchTextSelected = getValues().searchText;
    const filteredData = allSeftTable.filter((item) => {
      const matchesPosition =
        positionSelected == 0 || item.positionId == positionSelected;
      const matchesSearchText =
        !searchTextSelected ||
        (item.employee &&
          item.employee
            .toLowerCase()
            .includes(searchTextSelected.toLowerCase()));
      return matchesPosition && matchesSearchText;
    });

    setAllSeftTableFiltered(filteredData);
  };

  const dispatch = useDispatch();

  const handleChangeYear = () => {
    getEmployeesInMonitoring();
  };

  const getUserInfo = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/getCurrentUser/${currentUserId}`
      );
      setUserInfo(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const getEmployeesInMonitoring = async () => {
    const { year, position, searchText } = getValues();
    callApiAsync(
      () => getCallEmployeesInMonitoring(currentUserId, year),
      (data) => {
        const responseData = data;
        const filteredData = responseData.filter((item) => {
          const matchesPosition =
            position === 0 || item.positionId === position;
          const matchesSearchText =
            !searchText ||
            (item.employee &&
              item.employee.toLowerCase().includes(searchText.toLowerCase()));

          return matchesPosition && matchesSearchText;
        });
        setAllSeftTableFiltered(filteredData);
        setAllSeftTable(responseData);
      }
    );
  };

  const getAllEmployeesInMonitoring = async () => {
    const { year, position, searchText } = getValues();
    callApiAsync(
      () => getCallAllEmployeesInMonitoring(currentUserId),
      (data) => {
        const responseData = data;
        const filteredData = responseData.filter((item) => {
          const matchesPosition =
            position === 0 || item.positionId === position;
          const matchesSearchText =
            !searchText ||
            (item.employee &&
              item.employee.toLowerCase().includes(searchText.toLowerCase()));

          return matchesPosition && matchesSearchText;
        });
        setAllSeftTableFiltered(filteredData);
        setAllSeftTable(responseData);
      }
    );
  };

  const getSelfAppraisal = async () => {
    callApiAsync(
      () => getCallSelfAppraisal(currentUserId),
      (data) => {
        setSeftTable(data);
      }
    );
  };

  const getActivitiesByCompetency = async () => {
    callApiAsync(
      () => getCallActivitiesByCompetency(currentUserId),
      (data) => {
        setActivitiesTable(data);
      }
    );
  };

  const loadDataSelects = async () => {
    setValue("year", getDateObj().year);
    setValue("position", 0);
  };

  const loadDataEvidence = async (data) => {
    setValueForm("evidence", data.evidence[0]?.url || "");
  };

  const getPositionFx = () => {
    callApiAsync(
      () => getCallPositionList(),
      (data) => {
        setPositionList([{ name: "Todos", value: 0 }, ...data]);
      }
    );
  };

  const getUsersInMonitoringFx = () => {
    if (isAdmin(userData.roleList) || isGeneralManagment(userData.roleList)) {
      getAllEmployeesInMonitoring();
    } else {
      getEmployeesInMonitoring();
    }
  };

  const getEvidence = async () => {
    await callApiAsync(
      () => getActivityEvidenceByUser(userData.id, mediaData.id),
      async (response) => {
        setValueForm("evidence", response.evidence_url);
        setEvidenceData(response);
      }
    );
  };

  useEffect(() => {
    loadDataSelects();
    getUserInfo();
    getActivitiesByCompetency();
    getSelfAppraisal();
    getUsersInMonitoringFx();
    getPositionFx();
    dispatch(changeDirectorGestionBtnState(false));
  }, []);

  const openMediaModal = (data) => {
    // console.log('evidenceData', data.evidence[0].id)
    setEvidenceData(data);
    loadDataEvidence(data);
    setMediaData(data);
    setMediaModal(true);
  };

  const hadleClose = () => {
    setMediaData({});
    reset();
    setMediaModal(false);
  };

  const expansionTemplate = (data) => {
    return (
      <div className="HContainer j-space-between">
        <div className="title-container-study-materials"></div>
        <div className="HContainer">
          {data.evidence.map((item) => (
            <a
              key={item.url}
              href={item.url}
              className="url-column"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LaddaButton
                color={"#22C55E"}
                backgroundColor={"#D3FFE2"}
                variant="contained"
                onClick={() => console.log("url", item.url)}
              >
                <div className="HContainer align-center">
                  <img className="btn-icon" src={icons.archivo} alt="icon" />
                </div>
              </LaddaButton>
            </a>
          ))}
          {/* {data.evidence.length > 0 ? (
            <></>
          ) : ( */}
          <LaddaButton
            variant="contained"
            onClick={() => openMediaModal(data)}
            width={"200px"}
          >
            <div>
              <p className="button_text">Evidencia de la actividad</p>
            </div>
          </LaddaButton>
          {/* // )} */}
        </div>
      </div>
    );
  };

  const openMaterialModal = (data) => {
    setModalTableData(data);
    setTimeout(() => {
      setOpenStudyMaterialsModal(true);
    }, 500);
  };

  const handleClose = () => {
    setMediaModal(false);
    setMediaData({});
  };

  const onSubmitEvidence = async (formData) => {
    console.log("evidenceData", evidenceData);
    const dataToSend = {
      id: evidenceData.evidence.length === 0 ? 0 : evidenceData.evidence[0].id,
      user_id: userData.id,
      activity_id: mediaData.id,
      evidence_url: formData.evidence
    };
    console.log("dataToSend", dataToSend);
    await callApiAsync(
      () => postSaveActivityEvidence(dataToSend),
      async (response) => {
        hadleClose();
        getActivitiesByCompetency();
      }
    );
  };

  return (
    <div className="App">
      <BreadCrumbComp />
      <div className="searchTitle">
        <h1 className="page-title">DASHBOARD - {userInfo.nombre}</h1>
      </div>
      <div className="-">
        {isAdmin(userData.roleList) === false ? (
          <>
            <div className="table-container">
              <div className="header-data-container">
                <p className="info-label">
                  Empleado:
                  <span className="label_header_text">{userInfo.nombre}</span>
                </p>
                <p className="info-label">
                  Cargo:
                  <span className="label_header_text">{userInfo.cargo}</span>
                </p>
                <p className="info-label">
                  Proceso:
                  <span className="label_header_text">{userInfo.area}</span>
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "unset",
                  marginBottom: "20px"
                }}
              ></div>
              <SimpleTable
                data={seftTable}
                columns={superior_rol_A_BurnData.columns}
                scrollHeight={"300px"}
                // text={"Realizar Autoevaluación"}
                buttons={[
                  {
                    type: "redirectData",
                    icon: icons.lineChard,
                    url: "/360_Report"
                  }
                ]}
              />
            </div>
            <div className="table-container">
              <h1 className="progress-label-title">Actividades</h1>
              <SimpleTable
                data={activitiesTable}
                columns={superior_rol_B_BurnData.columns}
                scrollHeight={"500px"}
                rowExpansionTemplate={expansionTemplate}
                text={"Realizar Autoevaluación"}
                buttons={[
                  {
                    type: "icon",
                    icon: icons.eye,
                    onClick: openMaterialModal
                  }
                ]}
              />
            </div>
            <div className="table-container">
              <h1 className="progress-label-title">
                Plan de Acción Individual - PAI 360 grados
              </h1>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Link to="/superior_rol_dashboard/Pai_360" state={{ paiId: 1 }}>
                  <LaddaButton
                    variant="contained"
                    color={"black"}
                    backgroundColor={"#F4D658"}
                    width={"230px"}
                  >
                    <div className="HContainer align-center">
                      <p className="button_text">PAI</p>
                    </div>
                  </LaddaButton>
                </Link>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        <div className="table-container">
          <h1 className="progress-label-title">Empleados en Seguimiento</h1>
          <div className="filter-container">
            <form>
              <Grid justifyContent="center" container spacing={1}>
                <Grid item xs={12} md={4}>
                  <SearchBar
                    name="searchText"
                    control={control}
                    onSearch={handleChangeFilter}
                    width={"100%"}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <SimpleSelect
                    name="position"
                    control={control}
                    label="Cargo"
                    options={positionList}
                    onChange={handleChangeFilter}
                    margin={"0"}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <SimpleSelect
                    name="year"
                    control={control}
                    label="Año"
                    options={actualYearSelect}
                    onChange={handleChangeYear}
                    margin={"0"}
                  />
                </Grid>
              </Grid>
            </form>
          </div>
          <SimpleTable
            data={allSeftTableFiltered}
            columns={superior_rol_C_BurnData.columns}
            text={"Realizar Autoevaluación"}
            isPaginated={true}
            sortable
            buttons={[
              {
                type: "icon",
                icon: icons.editBlue,
                onClick: (rowData) => {
                  setCurrentCommentRow(rowData);
                  setOpenModalEditComment(true);
                }
              },
              {
                type: "redirectData",
                icon: icons.lineChard,
                url: "/360_Report"
              }
            ]}
          />
        </div>
      </div>
      <ModalEditComment
        open={openModalEditComment}
        handleClose={() => setOpenModalEditComment(false)}
        currentCommentRow={currentCommentRow}
        getUsersInMonitoringFx={getUsersInMonitoringFx}
      />
      <ModalComponent
        open={mediaModal}
        handleClose={() => hadleClose()}
        title="Evidencia de la actividad"
        width="500px"
        actions={<></>}
      >
        <>
          <form>
            <FormTextField
              name="evidence"
              control={controlForm}
              formLabel=""
              label="Agregar URL de evidencia"
              error={errorsForm.evidence}
              margin={"0"}
            />
            <div className="HContainer j-center mt-20">
              <LaddaButton
                variant="contained"
                onClick={handleSubmitForm(onSubmitEvidence)}
              >
                <div className="HContainer align-center">
                  <p className="button_text">Guardar</p>
                </div>
              </LaddaButton>
            </div>
          </form>
        </>
      </ModalComponent>

      <StudyMaterialsModal
        open={openStudyMaterialsModal}
        handleClose={() => setOpenStudyMaterialsModal(false)}
        data={modalTableData}
      />
    </div>
  );
};

export default SuperiorRolDashboard;
