import React from "react";
import Box from "@mui/material/Box";
import { Controller } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { InputLabel, useMediaQuery } from "@mui/material";

const FormTextField = ({
  name,
  control,
  label,
  defaultValue,
  formLabel,
  isTextArea,
  rows,
  error,
  maxLength,
  disabled,
  styledlabel,
  width,
  onChange,
  margin,
  maxWidth,
  marginTop,
  onBlur,
  ...othersProps
}) => {
  const isSmallScreen = useMediaQuery("(max-width:590px)");

  const StyledTextField = styled(TextField)(({ theme }) => ({
    width: width ? width : "100%",
    maxWidth: maxWidth ? maxWidth : "",
    height: isTextArea ? "auto" : "40px",
    margin: margin
      ? margin
      : isTextArea
      ? rows
        ? `0 10px`
        : "0 10px"
      : "0 10px",
    borderRadius: "6px",
    "& .MuiInputBase-input": {
      padding: "4.5px"
    },
    "& .MuiInputBase-root": {
      padding: "4px 7px"
    },
    "& .MuiFormHelperText-root": {
      fontSize: "10.5px",
      margin: "3px 0 0 0"
    }
  }));

  const StyledInputLabel = styled(InputLabel)(() => ({
    top: "-10px",
    color: "rgba(0, 0, 0, 0.6)",
    margin: "-10px 0px -15px 15px",
    marginTop: marginTop ? marginTop : isSmallScreen ? "8px" : ""
  }));

  return (
    <Box>
      {label ? <p className="text-form-label">{label}</p> : null}
      {styledlabel ? (
        <StyledInputLabel shrink={true} id={`${name}-label`}>
          {styledlabel}
        </StyledInputLabel>
      ) : null}
      <FormControl fullWidth error={Boolean(error)} className="formController">
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue || ""}
          render={({ field }) => (
            <StyledTextField
              {...field}
              inputProps={{
                maxLength: maxLength
              }}
              helperText={
                maxLength &&
                `Este campo solo permite hasta ${maxLength} caracteres.`
              }
              label={formLabel}
              variant="outlined"
              multiline={isTextArea}
              rows={isTextArea ? rows || 4 : 1}
              onChange={(event) => {
                field.onChange(event);
                if (onChange) onChange(event);
              }}
              disabled={disabled}
              onBlur={(event) => {
                field.onBlur();
                if (onBlur) onBlur(event);
              }}
              {...othersProps}
            />
          )}
        />
        {error && (
          <p className="error-message formTexfield-error">{error.message}</p>
        )}
      </FormControl>
    </Box>
  );
};

export default FormTextField;
