import React, { useState, useEffect } from "react";
import ModalComponent from "../../../components/modals";
import LaddaButton from "../../../components/buttons/laddaButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Grid, useMediaQuery } from "@mui/material";
import {
  callApiAsync,
  getCallAccessoryData
} from "../../../services/apiService";
import { icons } from "../../../constants";
import SimpleSelect from "../../../components/selects/simpleSelect";
import AddAccessory from "./addAccessory";

const validationSchema = yup.object().shape({
  accessory: yup
    .string()
    .required("Este campo es obligatorio")
    .min(1, "Este campo no puede estar vacío")
});

const AddAccessoryBase = ({
  open,
  close,
  brandIdSelected,
  selectedRow,
  setSelectedRow,
  setAccessoryTable,
  accessoryTable
}) => {
  const [accessoryOptions, setAccessoryOptions] = useState([]);
  const [openAddAccessoryModal, setOpenAddAccessoryModal] = useState(false);

  const isSmallScreen = useMediaQuery("(max-width:590px)");
  const isMediumScreen = useMediaQuery("(max-width:900px)");

  const hadleCloseAddAccessory = () => {
    setOpenAddAccessoryModal(false);
  };

  const {
    control,
    reset,
    getValues,
    setValue,
    formState: { errors, isValid }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  useEffect(() => {
    getAccessoryDataM();
  }, [brandIdSelected]);

  const getAccessoryDataM = () => {
    if (brandIdSelected == null) return;
    callApiAsync(
      () => getCallAccessoryData(brandIdSelected, 0),
      (data) => {
        setAccessoryOptions(data.list);
      }
    );
  };

  const loadData = (data) => {
    setValue("accessory", data.id);
  };
  const cleanData = () => {
    setValue("accessory", "");
  };

  useEffect(() => {
    if (!open) return;
    if (selectedRow) {
      loadData(selectedRow);
    } else {
      cleanData();
    }
  }, [open]);

  const handleClose = () => {
    cleanData();
    setSelectedRow(null);
    reset();
    close();
  };

  const handleAddAccessory = () => {
    let updatedAccessoryTable = [...accessoryTable];

    const selectedAccessoryId = getValues("accessory");

    const selectedAccessory = accessoryOptions.find(
      (option) => option.value === selectedAccessoryId
    );

    if (!selectedAccessory) {
      console.error("Accesorio no encontrado");
      return;
    }

    const newAccessory = {
      id: selectedAccessory.value,
      accessory: selectedAccessory.name
    };

    const isDuplicate = updatedAccessoryTable.some(
      (item) => item.id === newAccessory.id
    );

    if (!isDuplicate) {
      updatedAccessoryTable.push(newAccessory);
    } else {
      console.warn("Accesorio duplicado, no se añadirá a la tabla.");
    }

    if (selectedRow != null && selectedRow.id !== selectedAccessoryId) {
      updatedAccessoryTable = updatedAccessoryTable.filter(
        (item) => item.id !== selectedRow.id
      );
    }

    setAccessoryTable(updatedAccessoryTable);
    handleClose();
  };

  return (
    <>
      <ModalComponent
        open={open}
        handleClose={() => {
          handleClose();
        }}
        title={"Agregar Accesorio"}
        actions={<div></div>}
        width={"500px"}
      >
        <div>
          <div className="j-center align-center">
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12} md={10}>
                <SimpleSelect
                  name="accessory"
                  control={control}
                  options={accessoryOptions}
                  error={errors.accessory}
                  hideLabel={true}
                  margin={"0"}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <div className="j-end minus-mt-5">
                  <LaddaButton
                    width={"40px"}
                    fontSize={"12px"}
                    height={"40px"}
                    variant="contained"
                    onClick={() => setOpenAddAccessoryModal(true)}
                  >
                    <img className="btn-icon" src={icons.plusIcon} alt="icon" />
                  </LaddaButton>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="j-center">
            <LaddaButton
              width={"180px"}
              fontSize={"12px"}
              height={"32px"}
              variant="contained"
              margin={"20px 0"}
              disabled={!isValid}
              onClick={handleAddAccessory}
            >
              <p className="button_text">Agregar Accesorio</p>
            </LaddaButton>
          </div>
        </div>
      </ModalComponent>
      <AddAccessory
        open={openAddAccessoryModal}
        close={hadleCloseAddAccessory}
        setAccessoryOptions={setAccessoryOptions}
        brandIdSelected={brandIdSelected}
        accessoryOptions={accessoryOptions}
      />
    </>
  );
};

export default AddAccessoryBase;
