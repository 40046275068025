import React, { useEffect, useState } from "react";
import LaddaButton from "../../../components/buttons/laddaButton";
import FormTextField from "../../../components/textField/formTextField";
import ModalComponent from "../../../components/modals";
import ToastNotification from "../../../components/toastNotification";
import { Grid } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import FormRadioButton from "../../../components/formRadioButton";
import { YesNoOptions } from "../../../constants/selectData";
import { useSelector } from "react-redux";
import {
  callApiAsync,
  postVerificationRisk
} from "../../../services/apiService";

const validationSchema = yup.object().shape({
  verification: yup.string().required("Este campo es obligatorio"),
  effective: yup.string().required("Este campo es obligatorio")
});

const RiskVerificationModal = ({ open, handleClose, data, getRisk }) => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);

  const userData = useSelector((state) => state.userData.userData);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {}
  });

  useEffect(() => {
    if (data) {
      setValue("verification", data.verification || "");
      setValue(
        "effective",
        data.effective === 1 ? true : data.effective === 0 ? false : null
      );
    }
  }, [data, setValue]);

  const handleCloseModal = () => {
    reset();
    handleClose();
  };

  const onSubmitVerification = async (formData) => {
    const dataToSend = {
      risk_id: data.riskMonthId,
      verificator_user: userData.id,
      verification: formData.verification,
      effective: formData.effective
    };

    await callApiAsync(
      () => postVerificationRisk(dataToSend),
      () => {
        setToastMessage("Verificación guardada con éxito");
        setShowToast(true);
        getRisk();
        handleClose();
      }
    );
  };

  return (
    <div>
      <ToastNotification
        severity="success"
        summary={toastMessage}
        detail=""
        position="top-center"
        showToast={showToast}
        setShowToast={setShowToast}
      />
      <ModalComponent
        open={open}
        handleClose={handleCloseModal}
        title={"Verificación de Riesgo"}
        width={"600px"}
        actions={
          <div className="w-complete mv-20">
            <div className="j-center">
              <LaddaButton
                variant="contained"
                onClick={handleSubmit(onSubmitVerification)}
              >
                <div className="HContainer align-center">
                  <p className="button_text">Guardar Verificación</p>
                </div>
              </LaddaButton>
            </div>
          </div>
        }
      >
        <form>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <FormRadioButton
                name="effective"
                control={control}
                label="Eficaz:"
                options={YesNoOptions}
                error={errors.effective}
                margin={'0'}
              />
            </Grid>
            <Grid item xs={12}>
              <p className="detail-label ml-0">Verificación:</p>
              <FormTextField
                name="verification"
                control={control}
                isTextArea={true}
                error={errors.verification}
                maxLength={500}
                rows={3}
                margin={'0'}
              />
            </Grid>
          </Grid>
        </form>
      </ModalComponent>
    </div>
  );
};

export default RiskVerificationModal;
