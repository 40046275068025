import React, { useEffect, useState } from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import LaddaButton from "../buttons/laddaButton";
import { Link, useNavigate } from "react-router-dom";
import { icons } from "../../constants";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import ModalComponent from "../modals";
import TextAreaForm from "../textArea";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "./styles.css";
import SimpleSelect from "../selects/simpleSelect";
import { stateDofa } from "../../constants/selectData";
import { getCookie } from "../../utils/getCookie";
import { Grid } from "@mui/material";
import FormTextField from "../textField/formTextField";
import {
  callApiAsync,
  postDofaStrategy,
  postUpdateDofa
} from "../../services/apiService";
import ToastNotification from "../toastNotification";
import MultiSelect from "../selects/multiSelect";
import { dofaTypeEnum, strategiesTypeEnum } from "../../constants/enums";

const validationSchema = yup.object().shape({
  description: yup.string().required("Este campo es obligatorio")
});

const StrategiesComponent = ({
  data,
  title,
  color,
  processId,
  month,
  year,
  isAvailableToAdd,
  Debilidades = [],
  Oportunidades = [],
  Fortalezas = [],
  Amenazas = [],
  getStrategiesByUser,
  filterdata
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [currentDofaStrategie, setCurrentDofaStrategie] = useState(null);

  const [select1, setSelect1] = useState([]);
  const [select2, setSelect2] = useState([]);
  const [nameStrategies, setNameStrategies] = useState({
    select1: "",
    select2: ""
  });
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const userSesion = getCookie("sesion");
  const navigate = useNavigate();
  const userId = userSesion.id_usuario;
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  const typeStrategie = [
    { name: "FO - Crecimiento", value: 1 },
    { name: "DO - Redireccionar", value: 2 },
    { name: "FA - Protección", value: 3 },
    { name: "DA - Sobrevivir", value: 4 }
  ];

  const filteredType = typeStrategie.find((type) => type.name === title);

  const dynamicValidationSchema = yup.object().shape({
    description: yup.string().required("Este campo es obligatorio"),
    state: yup.string().required("El estado es obligatorio"),
    select1: yup
      .array()
      .min(1, "Debes seleccionar al menos una fortaleza")
      .required("Fortalezas es obligatorio"),
    select2: yup
      .array()
      .min(1, "Debes seleccionar al menos una opción")
      .required("Fortalezas es obligatorio")
  });

  const getSelect = (type) => {
    switch (type) {
      case 1:
        setNameStrategies({ select1: "Fortalezas", select2: "Oportunidades" });
        setSelect1(Fortalezas);
        setSelect2(Oportunidades);
        break;
      case 2:
        setNameStrategies({ select1: "Debilidades", select2: "Oportunidades" });
        setSelect1(Debilidades);
        setSelect2(Oportunidades);
        break;

      case 3:
        setNameStrategies({ select1: "Fortalezas", select2: "Amenazas" });
        setSelect1(Fortalezas);
        setSelect2(Amenazas);
        break;

      case 4:
        setNameStrategies({ select1: "Debilidades", select2: "Amenazas" });
        setSelect1(Debilidades);
        setSelect2(Amenazas);
        break;

      default:
        return {};
    }
  };

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(dynamicValidationSchema),
    defaultValues: {
      select1: "",
      select2: "",
      description: "",
      state: "",
      type: filteredType.value || ""
    }
  });

  const handleClose = () => {
    setOpenModal(false);
    setCurrentDofaStrategie(null);
    reset();
  };

  const groupIdsByTypeSelects = (dofaMonths = [], strategiesType) => {
    let data1 = [];
    let data2 = [];

    switch (strategiesType) {
      case strategiesTypeEnum.growth:
        data1 = dofaMonths
          .filter((item) => item.type === dofaTypeEnum.strengths)
          .map((item) => item.id);
        data2 = dofaMonths
          .filter((item) => item.type === dofaTypeEnum.opportunity)
          .map((item) => item.id);
        break;

      case strategiesTypeEnum.redirect:
        data1 = dofaMonths
          .filter((item) => item.type === dofaTypeEnum.weakness)
          .map((item) => item.id);
        data2 = dofaMonths
          .filter((item) => item.type === dofaTypeEnum.opportunity)
          .map((item) => item.id);
        break;

      case strategiesTypeEnum.protection:
        data1 = dofaMonths
          .filter((item) => item.type === dofaTypeEnum.strengths)
          .map((item) => item.id);
        data2 = dofaMonths
          .filter((item) => item.type === dofaTypeEnum.threats)
          .map((item) => item.id);
        break;

      case strategiesTypeEnum.survive:
        data1 = dofaMonths
          .filter((item) => item.type === dofaTypeEnum.weakness)
          .map((item) => item.id);
        data2 = dofaMonths
          .filter((item) => item.type === dofaTypeEnum.threats)
          .map((item) => item.id);
        break;

      default:
        console.warn("Estrategia no reconocida:", strategiesType);
    }

    return { data1, data2 };
  };

  const handleOpenModal = (data = null) => {
    setCurrentDofaStrategie(data);
    const selectsData = groupIdsByTypeSelects(data?.dofaMonths, data?.type);
    setOpenModal(true);
    setValue("select1", selectsData.data1 || "");
    setValue("select2", selectsData.data2 || "");
    setValue("description", data.description || "");
    setValue("state", data.state || "");
    setValue("type", filteredType.value || "");
  };

  const getButtons = (data) => (
    <div className="HContainer">
      {isAvailableToAdd && currentMonth === month && currentYear === year && (
        <div className="action-btn" onClick={() => handleOpenModal(data)}>
          <img className="btn-icon" src={icons.editBlack} alt="icon" />
        </div>
      )}
    </div>
  );

  const createStrategies = async (formData) => {
    let objRequest;
    let message;

    if (currentDofaStrategie?.id) {
      message = "Estrategia actualizada exitosamente.";
      objRequest = {
        id: currentDofaStrategie.id,
        processId: processId,
        type: formData.type,
        state: formData.state,
        description: formData.description,
        year: year,
        month: month,
        dofaId1: formData.select1,
        dofaId2: formData.select2,
        dofaIds: [...formData.select1, ...formData.select2]
      };
    } else {
      message = "Estrategia creada exitosamente.";
      objRequest = {
        id: 0,
        processId: processId,
        type: filteredType.value,
        state: formData.state,
        description: formData.description,
        year: year,
        month: month,
        dofaId1: formData.select1,
        dofaId2: formData.select2,
        dofaIds: [...formData.select1, ...formData.select2]
      };
    }

    callApiAsync(
      () => postDofaStrategy(objRequest),
      () => {
        getStrategiesByUser(
          filterdata.year,
          filterdata.month,
          filterdata.state
        );
        setToastMessage(message);
        setShowToast(true);
        handleClose();
      }
    );
  };

  const onSubmit = (formData) => {
    createStrategies(formData);
  };

  const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "white",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
      color: "#333399",
      fontWeight: 700,
      padding: "15px 20px",
      fontSize: theme.typography.pxToRem(12),
      borderRadius: "10px",
      fontFamily: "Lato"
    }
  }));

  useEffect(() => {
    getSelect(filteredType.value);
  }, [openModal]);

  const groupIdsByType = (dofaMonths = [], strategiesType) => {
    let data1 = [];
    let data2 = [];
    switch (strategiesType) {
      case strategiesTypeEnum.growth:
        data1 = dofaMonths
          .filter((item) => item.type === dofaTypeEnum.strengths)
          .map((item) => item.dofaKey);
        data2 = dofaMonths
          .filter((item) => item.type === dofaTypeEnum.opportunity)
          .map((item) => item.dofaKey);
        break;

      case strategiesTypeEnum.redirect:
        data1 = dofaMonths
          .filter((item) => item.type === dofaTypeEnum.weakness)
          .map((item) => item.dofaKey);
        data2 = dofaMonths
          .filter((item) => item.type === dofaTypeEnum.opportunity)
          .map((item) => item.dofaKey);
        break;

      case strategiesTypeEnum.protection:
        data1 = dofaMonths
          .filter((item) => item.type === dofaTypeEnum.strengths)
          .map((item) => item.dofaKey);
        data2 = dofaMonths
          .filter((item) => item.type === dofaTypeEnum.threats)
          .map((item) => item.dofaKey);
        break;

      case strategiesTypeEnum.survive:
        data1 = dofaMonths
          .filter((item) => item.type === dofaTypeEnum.weakness)
          .map((item) => item.dofaKey);
        data2 = dofaMonths
          .filter((item) => item.type === dofaTypeEnum.threats)
          .map((item) => item.dofaKey);
        break;

      default:
        console.warn("Estrategia no reconocida:", strategiesType);
    }

    return { data1, data2 };
  };

  return (
    <div className="dofa_container">
      <ToastNotification
        severity={toastMessage.includes("existe") ? "warn" : "success"}
        summary={toastMessage}
        detail=""
        showToast={showToast}
        setShowToast={setShowToast}
      />

      <div className="dofa_header">
        <p className="dofa_title">{title}</p>
        {isAvailableToAdd && (
          <LaddaButton variant="contained" onClick={() => handleOpenModal()}>
            <div className="HContainer align-center">
              <img
                className="btn-icon"
                src={icons.addPlus}
                alt="icon"
                style={{ marginRight: "10px" }}
              />
              <p className="button_text">Agregar</p>
            </div>
          </LaddaButton>
        )}
      </div>
      <div className="dofa_body">
        <div className="dofa_items_container">
          {data.map((item) => {
            const groupedIds = groupIdsByType(item.dofaMonths, item.type);

            return (
              <StyledTooltip
                key={item.id}
                title={
                  <>
                    <p className="toolkit-title">{nameStrategies.select1}:</p>
                    <p className="toolkit-content">
                      {groupedIds.data1.map((x, index) => (
                        <span key={index}>
                          {nameStrategies.select1[0] + x}
                          {index !== groupedIds.data1.length - 1 ? ", " : ""}
                        </span>
                      ))}
                    </p>

                    <p className="toolkit-title">{nameStrategies.select2}:</p>
                    <p className="toolkit-content">
                      {groupedIds.data2.map((x, index) => (
                        <span key={index}>
                          {nameStrategies.select2[0] + x}
                          {index !== groupedIds.data2.length - 1 ? ", " : ""}
                        </span>
                      ))}
                    </p>

                    <p className="toolkit-title">Descripción:</p>
                    <p className="toolkit-content">{item.description}</p>
                  </>
                }
                followCursor
              >
                <div
                  className="dofa_item"
                  style={{ backgroundColor: color ?? "white" }}
                >
                  <div
                    className="text-dofa-container "
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p className="comment_text" style={{ fontWeight: "bold" }}>
                      {item.typeLetter + " - "}
                    </p>
                    <p className="comment_text" style={{ marginLeft: "5px" }}>
                      {item.description}
                    </p>
                  </div>
                  <div>{getButtons(item)}</div>
                </div>
              </StyledTooltip>
            );
          })}
          <ModalComponent
            open={openModal}
            handleClose={handleClose}
            title={""}
            actions={
              <div className="HContainer j-center w-complete">
                <LaddaButton
                  variant="contained"
                  onClick={handleSubmit(onSubmit)}
                >
                  <div className="HContainer align-center">
                    <p className="button_text">
                      {currentDofaStrategie === null
                        ? "Guardar"
                        : "Guardar Cambios"}
                    </p>
                  </div>
                </LaddaButton>
              </div>
            }
          >
            <form className="VContainer form-modal m-20">
              <p style={{ marginLeft: "0" }} className="blueLabelForm">
                {filteredType.name}:
              </p>
              <Grid justifyContent="center" container spacing={1}>
                <Grid justifyContent="center" container spacing={1.5}>
                  <Grid item xs={12} md={6}>
                    <p className="blueLabelForm">{nameStrategies.select1}:</p>
                    <MultiSelect
                      name="select1"
                      control={control}
                      label=""
                      options={select1}
                      error={errors.select1}
                      disabled={false}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <p className="blueLabelForm">{nameStrategies.select2}:</p>
                    <MultiSelect
                      name="select2"
                      control={control}
                      label=""
                      options={select2}
                      error={errors.select2}
                      disabled={false}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} md={12}>
                  <div style={{ marginLeft: "-10px" }}>
                    <p className="blueLabelForm">Descripción:</p>
                    <FormTextField
                      name="description"
                      control={control}
                      isTextArea={true}
                      error={errors.description}
                      maxLength={500}
                      rows={2}
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={12}>
                  <div style={{ marginLeft: "-10px" }}>
                    <p className="blueLabelForm">Estado:</p>
                    <SimpleSelect
                      name="state"
                      control={control}
                      label=""
                      options={stateDofa}
                      error={errors.state}
                    />
                  </div>
                </Grid>
              </Grid>
            </form>
          </ModalComponent>
        </div>
      </div>
    </div>
  );
};

export default StrategiesComponent;
