import React from "react";
import "./styles.css";

const AcompanimentPillComponent = ({ text, color, textColor }) => {
    return (
      <div
        style={{
          backgroundColor: color,
          height: "25px",
          borderRadius: "9px",
          padding: "10px 15px",
          width: "max-content",
          display: "flex",
          alignItems: "center"
        }}
      >
        <p
          style={{
            fontWeight: "700",
            fontSize: "10px",
            lineHeight: "13.62px",
            letterSpacing: "0%",
            textAlign: "center",
            color: textColor
          }}
        >
          {text}
        </p>
      </div>
    );
};

export default AcompanimentPillComponent;
