import React, { useEffect, useState } from "react";
import { Pagination, Stack, Radio, useMediaQuery } from "@mui/material";
import LaddaButton from "../../components/buttons/laddaButton";
import "./styles.css";
import BreadCrumbComp from "../../components/breadCrumb";
import { useLocation, useNavigate } from "react-router-dom";
import { getCookie } from "../../utils/getCookie";
import { icons } from "../../constants";
import ModalComponent from "../../components/modals";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import { setTotalAnswer } from "../../redux/slices/evaluationSlice";
import {
  callApiAsync,
  getCallCurrentUser,
  getCallSurvey,
  postAddAnswers
} from "../../services/apiService";
import { changeReload } from "../../redux/slices/reloadSurveysSlice";

const SurveyComponent = () => {
  const userSesion = getCookie("sesion");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();
  const { surveyId, surveyUserId, type, userId } = location.state || {};
  const totalAnswers = useSelector(
    (state) => state.evaluationData.evaluationData.totalAnswers
  );
  const reloadPage = useSelector((state) => state.reload.reload);

  const isSmallScreen = useMediaQuery("(max-width:590px)");
  const isMediumScreen = useMediaQuery("(max-width:900px)");

  var user = userId;
  const evaluationType = type;

  const [page, setPage] = useState(1);
  const [respuestas, setRespuestas] = useState({ responseArray: [] });
  const [userInfo, setUserInfo] = useState("");
  const [dataToSend, setDataToSend] = useState([]);
  const [error, setError] = useState(false);
  const [questionList, setQuestionList] = useState([]);
  const [breadCrumb, setbreadCrumb] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [answerSelected, setAnswerSelected] = useState(false);

  // ======================== survey pagination ========================
  const itemsPerPage = 1;

  const getTitle = () => {
    switch (evaluationType) {
      case "self_appraisal":
        return <span>AUTOEVALUACIÓN</span>;
      case "peer_evaluation":
        return (
          <span>
            EVALUACIÓN DE PARES -
            <span style={{ color: "#EA727D" }}> USTED ESTA EVALUANDO A :</span>
          </span>
        );
      case "collaborators_evaluation":
        return (
          <span>
            EVALUACIÓN DE COLABORADORES -
            <span style={{ color: "#EA727D" }}> USTED ESTA EVALUANDO A : </span>
          </span>
        );
      case "superiors_evaluation":
        return (
          <span>
            EVALUACIÓN DE SUPERIORES -
            <span style={{ color: "#EA727D" }}> USTED ESTA EVALUANDO A :</span>
          </span>
        );
      case "internal_clients_evaluation":
        return (
          <span>
            EVALUACIÓN DE CLIENTES INTERNOS -
            <span style={{ color: "#EA727D" }}> USTED ESTA EVALUANDO A :</span>
          </span>
        );
      case "appraisal":
        return <span>EVALUACIÓN</span>;
      default:
        return "";
    }
  };

  const handleChange = (event, value) => {
    if (value > page) {
      setPage(value);
    }
  };
  const handleNext = () => {
    if (!answerSelected) {
      setError(true);
      return;
    }
    var responseData = respuestas.responseArray[page - 1];
    if (page > respuestas.responseArray.length) {
      responseData =
        respuestas.responseArray[respuestas.responseArray.length - 1];
    }
    if (responseData == undefined) {
      setError(true);
      return;
    }
    setError(false);
    saveSurvey(responseData);
    setPage(page + 1);
  };

  const sendResults = () => {
    if (!answerSelected) {
      setError(true);
      return;
    }
    var responseData = respuestas.responseArray[page - 1];
    if (page > respuestas.responseArray.length) {
      responseData =
        respuestas.responseArray[respuestas.responseArray.length - 1];
    }
    if (responseData == undefined) {
      setError(true);
      return;
    }
    saveSurvey(responseData);
    dispatch(changeReload(!reloadPage));
    navigate(`/self_appraisal`);
  };

  const displayedItems = questionList.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );
  // ======================== endpoints ========================

  const getUserInfo = async () => {
    callApiAsync(
      () => getCallCurrentUser(user),
      (data) => {
        setUserInfo(data);
      }
    );
  };

  const getSurvey = async () => {
    callApiAsync(
      () => getCallSurvey(surveyId),
      (data) => {
        setQuestionList(data);
      }
    );
  };

  const saveSurvey = async (data) => {
    const objRequest = JSON.stringify(data);
    callApiAsync(
      () => postAddAnswers({ answers: objRequest }),
      () => {
        dispatch(setTotalAnswer(totalAnswers + 1));
        setAnswerSelected(false);
      }
    );
  };

  useEffect(() => {
    switch (evaluationType) {
      case "self_appraisal":
        setbreadCrumb("Autoevaluación");
        break;
      case "peer_evaluation":
        setbreadCrumb("Evaluación de pares");
        break;
      case "collaborators_evaluation":
        setbreadCrumb("Evaluación de colaboradores");
        break;
      case "superiors_evaluation":
        setbreadCrumb("Evaluación de superiores");
        break;
      case "internal_clients_evaluation":
        setbreadCrumb("Evaluación de clientes internos");
        break;
      case "appraisal":
        setbreadCrumb("Evaluación");
        break;
      default:
        setbreadCrumb("");
    }
  }, [evaluationType]);

  useEffect(() => {
    setAnswerSelected(false);
  }, [page]);

  useEffect(() => {
    getUserInfo();
    getSurvey();
    setAnswerSelected(false);
  }, []);

  const handleRadioChange = (questionId, optionValue) => {
    setError(false);
    setAnswerSelected(true);
    const data = {
      userId: userSesion.id_usuario,
      surveyUserId: surveyUserId,
      questionId: questionId,
      optionId: optionValue
    };
    setRespuestas((prevState) => {
      const existingResponseIndex = prevState.responseArray.findIndex(
        (response) => response.questionId === questionId
      );
      const newResponseArray = [...prevState.responseArray];
      if (existingResponseIndex !== -1) {
        newResponseArray[existingResponseIndex] = data;
      } else {
        newResponseArray.push(data);
      }
      setDataToSend(newResponseArray);
      return {
        ...prevState,
        [questionId]: optionValue,
        responseArray: newResponseArray
      };
    });
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if (totalAnswers) {
      setPage(totalAnswers + 1);
    }
    getUserInfo();
    getSurvey();
  }, []);
  return (
    <div>
      <BreadCrumbComp personalized={breadCrumb} />
      <div className="searchTitle">
        <h1 className="page-title">{getTitle()}</h1>
      </div>
      {evaluationType !== "self_appraisal" && (
        <div className="survey-target-info">
          <p style={{ color: "#EA727D", fontWeight: "700" }}>
            Empleado:{" "}
            <span
              style={{ color: "#404040", marginLeft: 10, fontWeight: "400" }}
            >
              {userInfo.nombre}
            </span>
          </p>

          <p style={{ color: "#EA727D", fontWeight: "700" }}>
            Cargo:{" "}
            <span
              style={{ color: "#404040", marginLeft: 10, fontWeight: "400" }}
            >
              {userInfo?.cargo || ""}
            </span>
          </p>

          <p style={{ color: "#EA727D", fontWeight: "700" }}>
            Proceso :{" "}
            <span
              style={{ color: "#404040", marginLeft: 10, fontWeight: "400" }}
            >
              {userInfo.area}
            </span>
          </p>
        </div>
      )}
      <Stack spacing={2}>
        <div>
          {displayedItems.map((item) => (
            <div className="table-container" key={item.questionId}>
              <div className="table-container-competency IHContainer align-center">
                <p className="competency-label">Competencia: </p>
                <p className="competency-name">{item.name}</p>
                <img
                  className="btn-infoExclamation pointer"
                  src={icons.infoExclamation}
                  alt="eye"
                  onClick={() => setOpenModal(true)}
                />
              </div>

              <p className="question-text">{item.question}</p>
              <div
                className="HContainer j-space-between align-center"
                style={{ marginLeft: "-10px" }}
              >
                {item.options.map((option) => (
                  <div className="IHContainer">
                    <Radio
                      onChange={() =>
                        handleRadioChange(item.questionId, option.value)
                      }
                      checked={respuestas[item.questionId] === option.value}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <p className="option-question-text">{option.text}</p>
                  </div>
                ))}
              </div>
              <ModalComponent
                open={openModal}
                handleClose={handleClose}
                title={""}
                actions={<></>}
              >
                <div className="IHContainer">
                  <p className="competency-label">Competencia Especifica: </p>
                  <p className="competency-name">{item.name}</p>
                </div>
                <p className="competency-description">{item.description}</p>
              </ModalComponent>
            </div>
          ))}
        </div>
        {error && (
          <p style={{ color: "red", textAlign: "center" }}>
            La pregunta es obligatoria.
          </p>
        )}
        <div className="VContainer align-center m-0">
          {page < questionList.length ? (
            <LaddaButton
              variant="contained"
              width={"200px"}
              onClick={() => {
                handleNext();
              }}
            >
              <div className="HContainer align-center">
                <p className="button_text">Siguiente</p>
              </div>
            </LaddaButton>
          ) : (
            <LaddaButton
              variant="contained"
              width={"200px"}
              onClick={() => {
                sendResults();
              }}
            >
              <div className="HContainer align-center">
                <p className="button_text">Finalizar</p>
              </div>
            </LaddaButton>
          )}

          <div className="page-question-text">
            {`( ${page} de ${questionList.length})`}
          </div>
          <Pagination
            count={0}
            page={page}
            onChange={handleChange}
            hidePrevButton={true}
            hideNextButton={true}
          />
        </div>
      </Stack>
    </div>
  );
};

export default SurveyComponent;
