import React, { useState, useMemo } from "react";
import SimpleTable from "../../../components/tables/simpleTable";
import { acompanimentSurveyTable } from "./columns";
import { icons } from "../../../constants";
import "./styles.css";
import AcompanimentPillComponent from "../../../components/pill/acompanimentPill";
import { Grid } from "@mui/material";
import SurveyTableModal from "./surveyTableModal";
import { acompanimentStatus } from "../../../constants/enums";

const SurveyTable = ({
  data,
  onlyRead = false,
  total,
  accompanimentStatus,
  getTable,
  saveData,
  setTable,
  accompanimentDate,
  accompanimentId,
  setAccompanimentId
}) => {
  const [open, setOpen] = useState(false);
  const [selectedData, setselectedData] = useState(null);

  const calculateWeights = (data) => {
    const totalWeightedWeight = data.reduce(
      (sum, item) => sum + item.weightedWeight,
      0
    );

    const sumWeightedWhenNotPresented = data
      .filter((item) => item.presentedOnTime === 1)
      .reduce((sum, item) => sum + item.weightedWeight, 0);

    return {
      totalWeightedWeight,
      sumWeightedWhenNotPresented
    };
  };

  const calculateWeightsResult = useMemo(() => calculateWeights(data), [data]);

  // useEffect(() => {
  //   calculateWeightsResult = calculateWeights(data);
  // }, [data]);

  return (
    <div>
      <SimpleTable
        data={data}
        columns={acompanimentSurveyTable}
        text={""}
        buttons={[
          {
            type: "icon",
            icon:
              accompanimentStatus !== acompanimentStatus.ended
                ? icons.editBlue
                : icons.eye,
            onClick: (rowData) => {
              setselectedData(rowData);
              setOpen(true);
            }
          }
        ]}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={9} md={6}>
          <div className="result_container">
            <p className="result_text">Resultado:</p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
              }}
            >
              <AcompanimentPillComponent
                text={`${calculateWeightsResult.sumWeightedWhenNotPresented} %`}
                color={"#BBF7D0"}
                textColor={"#404040"}
              />
              <span
                style={{
                  fontWeight: "700",
                  fontSize: "10px",
                  lineHeight: "13.62px",
                  letterSpacing: "0%",
                  textAlign: "center",
                  color: "#404040",
                  marginLeft: "3px"
                }}
              >
                {"  de " + calculateWeightsResult.totalWeightedWeight + "%"}
              </span>
            </div>
          </div>
        </Grid>
      </Grid>

      <SurveyTableModal
        selectedData={selectedData}
        open={open}
        handleClose={() => setOpen(false)}
        onlyRead={onlyRead}
        saveData={saveData}
        setTable={setTable}
        getTable={getTable}
        accompanimentDate={accompanimentDate}
        accompanimentId={accompanimentId}
        setAccompanimentId={setAccompanimentId}
        accompanimentStatusData={accompanimentStatus}
      />
    </div>
  );
};

export default SurveyTable;
