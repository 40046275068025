import {
  callApiAsync,
  getAccompanimentSelects,
  getBusinessLineSelect,
  getPlannedOrganized,
  getPromotionalAspects,
  getSpinTechnique,
  postSaveAccompaniment,
  getFilterSelects,
  postSaveSpinTechnique,
  postSavePlannedOrganized,
  postSavePromotionalAspects
} from "../../../services/apiService";

export const getAccompanimentSelectsData = async (setData) => {
  await callApiAsync(
    () => getAccompanimentSelects(),
    (data) => {
      setData(data);
    }
  );
};

export const getAccompanimentBusinessSelect = async (id, setData) => {
  await callApiAsync(
    () => getBusinessLineSelect(id),
    (data) => {
      setData(data.data);
    }
  );
};

export const getPromotionalAspectsTable = async (accompanimentId, setData) => {
  await callApiAsync(
    () => getPromotionalAspects(accompanimentId),
    (data) => {
      setData(data);
    }
  );
};

export const getPlannedOrganizedTable = async (accompanimentId, setData) => {
  await callApiAsync(
    () => getPlannedOrganized(accompanimentId),
    (data) => {
      setData(data);
    }
  );
};

export const getSpinTechniqueTable = async (accompanimentId, setData) => {
  await callApiAsync(
    () => getSpinTechnique(accompanimentId),
    (data) => {
      setData(data);
    }
  );
};

export const saveAccompaniment = async (dataToSend, navigate) => {
  await callApiAsync(
    () => postSaveAccompaniment(dataToSend),
    (data) => {
      navigate("/marketingPromotionManagement/accompaniment_Module");
    }
  );
};

export const getFilterSelectsData = async (setData, userId = null) => {
  await callApiAsync(
    () => getFilterSelects(userId),
    (data) => {
      setData(data);
    }
  );
};

export const savePromotionalAspects = async (
  dataToSend,
  setAccompanimentId,
  getTable,
  setTable
) => {
  await callApiAsync(
    () => postSavePromotionalAspects(dataToSend),
    (data) => {
      setAccompanimentId(data.accompanimentId);
      getTable(data.accompanimentId, setTable);
    }
  );
};

export const savePlannedOrganized = async (
  dataToSend,
  setAccompanimentId,
  getTable,
  setTable
) => {
  await callApiAsync(
    () => postSavePlannedOrganized(dataToSend),
    (data) => {
      setAccompanimentId(data.accompanimentId);
      getTable(data.accompanimentId, setTable);
    }
  );
};

export const saveSpinTechnique = async (
  dataToSend,
  setAccompanimentId,
  getTable,
  setTable
) => {
  await callApiAsync(
    () => postSaveSpinTechnique(dataToSend),
    (data) => {
      setAccompanimentId(data.accompanimentId);
      getTable(data.accompanimentId, setTable);
    }
  );
};
