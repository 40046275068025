import React, { useEffect, useState } from "react";
import SimpleTable from "../../components/tables/simpleTable";
import LaddaButton from "../../components/buttons/laddaButton";
import { icons } from "../../constants";
import ModalComponent from "../../components/modals";
import SimpleSelect from "../../components/selects/simpleSelect";
import FormTextField from "../../components/textField/formTextField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Grid } from "@mui/material";

const validationSchema = yup.object().shape({
  type_event_id: yup.string().required("El tipo de suceso es obligatorio"),
  gravity_level_id: yup.string().required("La gravedad es obligatoria"),
  amount: yup
    .number()
    .typeError("La cantidad debe ser un número")
    .required("La cantidad es obligatoria")
    .positive("Debe ser mayor a 0")
    .integer("Debe ser un número entero")
});

const eventTypeOptions = [
  { name: "Evento", value: 1 },
  { name: "Incidente", value: 2 }
];
const gravityOptions = [
  { name: "Serio", value: 1 },
  { name: "No Serio", value: 2 }
];

const AdverseEventsTable = ({
  adverseEventsDataTable,
  setAdverseEventsDataTable
}) => {
  const [open, setOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { type_event_id: "", gravity_level_id: "", amount: "" }
  });

  useEffect(() => {
    if (selectedEvent) {
      setValue("type_event_id", selectedEvent.type_event_id);
      setValue("gravity_level_id", selectedEvent.gravity_level_id);
      setValue("amount", selectedEvent.amount);
    } else {
      reset();
    }
  }, [selectedEvent, setValue, reset]);

  const openModal = () => {
    reset();
    setOpen(true);
  };

  const closeModal = () => {
    reset();
    setSelectedEvent(null);
    setOpen(false);
  };

  const handleAddOrEditEvent = handleSubmit((data) => {
    const isEditing = selectedEvent && selectedEvent.id !== 0;

    const formattedData = {
      ...data,
      id: isEditing ? selectedEvent.id : 0,
      rowTableId: isEditing ? selectedEvent.rowTableId : null,
      type_event_id: parseInt(data.type_event_id, 10),
      gravity_level_id: parseInt(data.gravity_level_id, 10)
    };

    if (isEditing) {
      setAdverseEventsDataTable((prevData) =>
        prevData.map((event) =>
          event.id === selectedEvent.id ||
          event.rowTableId === selectedEvent.rowTableId
            ? formattedData
            : event
        )
      );
    } else {
      setAdverseEventsDataTable((prevData) => [...prevData, formattedData]);
    }

    closeModal();
  });

  const handleEditRow = (row) => {
    setSelectedEvent(row);
    openModal();
  };

  const adverseEventsColumns = [
    {
      field: "type_event_id",
      header: "Tipo de Suceso",
      columnType: "custom",
      render: (rowData) => (
        <p className="table-default-column">
          {rowData.type_event_id === 1 ? "Evento" : "Incidente"}
        </p>
      )
    },
    {
      field: "gravity_level_id",
      header: "Gravedad",
      columnType: "custom",
      render: (rowData) => (
        <p className="table-default-column">
          {rowData.gravity_level_id === 1 ? "Serio" : "No Serio"}
        </p>
      )
    },
    { field: "amount", header: "Cantidad" },
    { field: "actions", header: "", columnType: "actionButtons" }
  ];

  return (
    <div>
      <div className="IHContainer j-end">
        <LaddaButton
          width={"40px"}
          fontSize={"12px"}
          height={"40px"}
          variant="contained"
          margin={"0 5px"}
          onClick={() => {
            setSelectedEvent(null);
            openModal();
          }}
        >
          <img className="btn-icon" src={icons.plusIcon} alt="icon" />
        </LaddaButton>
      </div>

      <div className="mt-15">
        <SimpleTable
          data={adverseEventsDataTable}
          columns={adverseEventsColumns}
          buttons={[
            {
              type: "icon",
              icon: icons.editBlue,
              onClick: (row) => handleEditRow(row)
            }
          ]}
        />
      </div>

      <ModalComponent
        open={open}
        handleClose={closeModal}
        title={
          selectedEvent ? "Editar Evento Adverso" : "Agregar Evento Adverso"
        }
        actions={<div className="HContainer j-center w-complete"></div>}
      >
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <p className="create-label ml-0">Tipo de Suceso:</p>
              <SimpleSelect
                name="type_event_id"
                control={control}
                options={eventTypeOptions}
                label=""
                error={errors.type_event_id}
                margin={"0"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <p className="create-label ml-0">Gravedad:</p>
              <SimpleSelect
                name="gravity_level_id"
                control={control}
                options={gravityOptions}
                label=""
                error={errors.gravity_level_id}
                margin={"0"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <p className="create-label ml-0">Cantidad:</p>
              <FormTextField
                name="amount"
                control={control}
                label=""
                error={errors.amount}
                margin={"0"}
              />
            </Grid>
          </Grid>
          <div className="HContainer j-center white-space">
            <LaddaButton
              width={"160px"}
              fontSize={"12px"}
              height={"40px"}
              variant="contained"
              onClick={handleAddOrEditEvent}
            >
              {selectedEvent ? "Guardar Cambios" : "Agregar Evento"}
            </LaddaButton>
          </div>
        </form>
      </ModalComponent>
    </div>
  );
};

export default AdverseEventsTable;
