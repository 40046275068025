import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { callApiAsync, getCallCities } from "../../../services/apiService";
import { useForm } from "react-hook-form";
import FormTextField from "../../../components/textField/formTextField";
import SimpleSelect from "../../../components/selects/simpleSelect";
import { technologyModalityOption } from "../../../constants/selectData";

const InstitutionSection = ({ departmentOptions, institutionData }) => {
  const [cityOptions, setCityOptions] = useState([]);

  const {
    control,
    formState: { errors },
    getValues,
    setValue
  } = useForm({
    mode: "all"
  });

  const loadData = (data) => {
    setValue("institutionName", data.name || "");
    setValue("technologyModality", data.technologyModality || "");
    setValue("department", data.departmentId || "");
    setValue("city", data.cityId || "");
    setValue("phone", data.phone || "");
    setValue("address", data.address || "");
    if (data.departmentId) {
      callApiAsync(
        () => getCallCities(data.departmentId),
        (cityData) => {
          setCityOptions(cityData);
        }
      );
    }
  };

  useEffect(() => {
    if (institutionData != null) {
      loadData(institutionData);
    }
  }, [institutionData]);

  const getCities = () => {
    const departmentId = getValues("department");
    if (departmentId != undefined) {
      callApiAsync(
        () => getCallCities(departmentId),
        (data) => {
          setCityOptions(data);
        }
      );
    }
  };

  return (
    <div>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} md={6}>
          <p className="create-label ml-0">Nombre de la Institución:</p>
          <FormTextField
            name="institutionName"
            control={control}
            label=""
            error={errors.institutionName}
            disabled
            margin={"0"}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <p className="create-label ml-0">Modalidad de la Tecnología:</p>
          <SimpleSelect
            name="technologyModality"
            control={control}
            options={technologyModalityOption}
            disabled
            margin={"0"}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <p className="create-label ml-0">Departamento:</p>
          <SimpleSelect
            name="department"
            control={control}
            options={departmentOptions}
            hideLabel={true}
            onChange={getCities}
            disabled
            margin={"0"}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <p className="create-label ml-0">Ciudad:</p>
          <SimpleSelect
            name="city"
            control={control}
            options={cityOptions}
            hideLabel={true}
            disabled
            margin={"0"}
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <p className="create-label ml-0">Teléfono:</p>
          <FormTextField
            name="phone"
            control={control}
            label=""
            error={errors.phone}
            disabled
            margin={"0"}
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <p className="create-label ml-0">Dirección:</p>
          <FormTextField
            name="address"
            control={control}
            label=""
            error={errors.address}
            disabled
            margin={"0"}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default InstitutionSection;
