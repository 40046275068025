import React from "react";

import { useLocation } from "react-router-dom";
import AccompanimentPage from "./accompanimentPage";

const AccompanimentCreation = () => {
  const location = useLocation();

  const accompanimentdata = location.state || {};

  return (
    <AccompanimentPage
      onlyRead={accompanimentdata?.onlyRead}
      accompanimentItemId={accompanimentdata?.id || 0}
    />
  );
};

export default AccompanimentCreation;
