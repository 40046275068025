import React, { useState } from "react";
import { CustomTabPanel } from "../../../components/tabComponent";
import { icons } from "../../../constants";
import { riskStatus } from "../../../constants/selectData";
import LaddaButton from "../../../components/buttons/laddaButton";
import RiskStatusModal from "./riskStatusModal";
import { StyledTooltip } from "../../../components/toolltipComponent";
import RiskVerificationModal from "../riskDetails/riskVerificationModal";
import { isRoleUtil } from "../../../utils/AuthorizedUtils/360Report";
import { useSelector } from "react-redux";

const RiskStatusTab = ({ data, getRisk, isEditionAvailable, updateRiskupdateDate }) => {
  const [riskStatusModal, setRiskStatusModal] = useState(false);
  const [riskVerificationModal, setRiskVerificationModal] = useState(false);

  const userData = useSelector((state) => state.userData.userData);

  var isVerificator = isRoleUtil(
    "RISK_STATUS_VERIFIER",
    userData.roleList
  );

  const handleClose = () => {
    setRiskStatusModal(false);
    setRiskVerificationModal(false);
  };

  const openModalVerification = () => {
    setRiskVerificationModal(true);
  };

  const getResidualLevel = (residualRiskLevel) => {
    switch (residualRiskLevel) {
      case 0:
      case 1:
        return {
          class: "low-level",
          textClass: "low-level-text",
          text: "Tolerable"
        };
      case 2:
        return {
          class: "medium-level",
          textClass: "medium-level-text",
          text: "Moderado"
        };
      case 3:
        return {
          class: "high-level",
          textClass: "high-level-text",
          text: "Importante"
        };
      case 4:
        return {
          class: "extreme-level",
          textClass: "extreme-level-text",
          text: "Inaceptable"
        };
      default:
        return {
          class: "default-level",
          textClass: "default-level-text",
          text: "Sin Nivel"
        };
    }
  };

  const getRiskLevel = (levelItem) => {
    switch (levelItem) {
      case 1:
        return {
          class: "low-level",
          textClass: "low-level-text",
          text: "Nivel Bajo"
        };
      case 2:
        return {
          class: "medium-level",
          textClass: "medium-level-text",
          text: "Medio"
        };
      case 3:
        return {
          class: "high-level",
          textClass: "high-level-text",
          text: "Nivel Alto"
        };
      case 4:
        return {
          class: "extreme-level",
          textClass: "extreme-level-text",
          text: "Nivel Extremo"
        };
      default:
        return {
          class: "default-level",
          textClass: "default-level-text",
          text: "Sin Nivel"
        };
    }
  };

  const getFrecuency = (residualRiskLevel) => {
    switch (residualRiskLevel) {
      case 1:
        return "Permanente";
      case 2:
        return "Periodico";
      case 3:
        return "Ocasional";
      default:
        return "";
    }
  };

  const RiskLevelComponent = ({ levelItem }) => {
    const { class: levelClass, textClass, text } = getRiskLevel(levelItem);

    return (
      <div className={`level ${levelClass}`}>
        <p className={textClass}>{text}</p>
      </div>
    );
  };

  const controlEffectivenessLevel = (levelItem) => {
    switch (levelItem) {
      case 1:
        return {
          class: "extreme-level",
          textClass: "extreme-level-text",
          text: "Inexsistente"
        };
      case 2:
      case 3:
      case 4:
        return {
          class: "low-level",
          textClass: "low-level-text",
          text: "Bajo"
        };
      case 5:
      case 6:
      case 7:
      case 8:
        return {
          class: "medium-level",
          textClass: "medium-level-text",
          text: "Medio"
        };
      case 9:
      case 10:
      case 11:
      case 12:
        return {
          class: "high-level",
          textClass: "high-level-text",
          text: "Alto"
        };
      default:
        return {
          class: "default-level",
          textClass: "default-level-text",
          text: "Sin Nivel"
        };
    }
  };

  const ControlEffectivenessComponent = ({ levelItem }) => {
    const {
      class: levelClass,
      textClass,
      text
    } = controlEffectivenessLevel(levelItem);

    return (
      <div className={`level ${levelClass}`}>
        <p className={textClass}>{text}</p>
      </div>
    );
  };

  const ResidualLevelComponent = ({ levelItem }) => {
    const { class: levelClass, textClass, text } = getResidualLevel(levelItem);

    return (
      <StyledTooltip
        title={
          <>
            <div className="HContainer">
              <p className="toolkit-title">Nivel de Riesgo:</p>
              <RiskLevelComponent levelItem={data.riskLevel} />
            </div>
            <div className="HContainer">
              <p className="toolkit-title">Eficacia de Control:</p>
              <ControlEffectivenessComponent
                levelItem={data.eficiencyControl}
              />
            </div>
          </>
        }
        followCursor
      >
        <div className={`level ${levelClass}`}>
          <p className={textClass}>{text}</p>
        </div>
      </StyledTooltip>
    );
  };

  const riskStatusDetailModal = () => {
    setRiskStatusModal(true);
  };

  return (
    <div>
      <CustomTabPanel value="2">
        <div className="IHContainer align-center">
          <h2 className="gm-title">Estado del Riesgo:</h2>
          <p style={{ marginLeft: "20px" }}>
            {data?.state
              ? riskStatus.find((item) => item.value === data.state)?.name
              : "" || "Creado"}
          </p>
        </div>

        <h2 className="gm-title">Estatus del Riesgo:</h2>
        <p className="content-text">{data?.statusRisk}</p>

        <div className="IHContainer align-center">
          <h2 className="gm-title">Riesgo Residual:</h2>
          <ResidualLevelComponent levelItem={data?.residualRisk} />
        </div>

        <h2 className="gm-title">Evidencia del Riesgo Residual:</h2>
        <p className="content-text">{data?.evidenceResidualRisk}</p>

        <div className="IHContainer">
          <h2 className="gm-title">Eficaz:</h2>
          <p>{data?.effective == 1 ? "Sí" : data.effective == 0 ? "No" : ""}</p>
        </div>

        <h2 className="gm-title">Verificación:</h2>
        <p className="content-text">{data?.verification}</p>

        <div className="j-center">
          {isEditionAvailable && (
            <LaddaButton variant="contained" onClick={riskStatusDetailModal}>
              <div className="IHContainer align-right">
                <img
                  className="btn-icon"
                  src={icons.editWhite}
                  alt="icon"
                  style={{ marginRight: "10px" }}
                />
                <p className="button_text">Editar</p>
              </div>
            </LaddaButton>
          )}

          {isVerificator && (
            <LaddaButton
              variant="contained"
              onClick={() => {
                openModalVerification();
              }}
            >
              <div className="IHContainer align-rigth">
                <img
                  className="btn-icon"
                  src={icons.editWhite}
                  alt="icon"
                  style={{ marginRight: "10px" }}
                />
                <p className="button_text">Verificación</p>
              </div>
            </LaddaButton>
          )}
        </div>
      </CustomTabPanel>

      <RiskStatusModal
        open={riskStatusModal}
        handleClose={handleClose}
        data={data}
        getRisk={getRisk}
        isEditionAvailable={isEditionAvailable}
        updateRiskupdateDate={updateRiskupdateDate}
      />

      <RiskVerificationModal
        open={riskVerificationModal}
        handleClose={handleClose}
        data={data}
        getRisk={getRisk}
      />
    </div>
  );
};

export default RiskStatusTab;
