import React from "react";
import { Grid } from "@mui/material";
import "./styles.css";

const HomePageCards = (title, list, content) => {
  const HomeCard = ({ title, subtitle, list, content, color, listType, flexible= false }) => {
    return (
      <div>
        <h1 className={`${color} font-18 cardTitle`}>{title}</h1>
        <h1 className={`${color} font-18`}>{subtitle ? subtitle : ""}</h1>
        {list ? (
          <ul
            style={{
              margin: " 0 0 0 -20px",
              fontSize: "14px",
              lineHeight: "22px",
            }}
            className={`${color} font-14 text-aling-initial `}
          >
            {list.map((x, index) => (
              <li
                style={{
                  listStyleType: listType ? listType : "disc",
                }}
                key={index}
              >
                {x}
              </li>
            ))}
          </ul>
        ) : (
          <div className={`${color} text-aling-initial `}>{content}</div>
        )}
      </div>
    );
  };

  return (
    <div className="vm-margin">
      <div>
        <Grid className="homeCard homeCard-blue" item xs={12} sm={12} md={12}>
          <HomeCard
            color={"font-blue"}
            title={"OBJETIVO ESTRATÉGICO"}
            list={[
              "Capacidad Financiera: 3",
              "Clientes: 3",
              "Capacidad Interna: 2",
              "Aprendizaje y Crecimiento: 2",
            ]}
          />
        </Grid>
      </div>
      <div className="horizontal-grid">
        <Grid className="homeCard homeCard-green" item xs={12} sm={12} md={6}>
          <HomeCard
            color={"font-green"}
            title={"ESTRATEGIAS"}
            list={[
              "Financieras: 3",
              "Clientes: 4",
              "Capacidad Interna: 2",
              "Aprendizaje y Crecimiento: 3",
            ]}
          />
        </Grid>
        <Grid className="homeCard homeCard-green" item xs={12} sm={12} md={6}>
          <HomeCard
            color={"font-green"}
            title={"OBJETIVO SISTEMA INTEGRADO"}
            list={[
              "Procesos Estratégicos: 2",
              "Procesos Misionales: 3",
              "Procesos de Soporte: 3",
            ]}
          />
        </Grid>
      </div>
      <div className="horizontal-grid">
        <Grid className="homeCard homeCard-yellow" item xs={12} sm={6} md={4}>
          <HomeCard
            color={"font-yellow"}
            title={"INDICADORES DE CALIDAD"}
            content={
              <ol
                style={{
                  margin: " 0 0 0 -20px",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <li style={{ listStyleType: "decimal" }}>
                  Gestión Dirección (1)
                </li>
                <li style={{ listStyleType: "decimal" }}>Gestión HSQ (8)</li>
                <li style={{ listStyleType: "decimal" }}>
                  Gestión Comercial (13)
                </li>
                <li style={{ listStyleType: "decimal" }}>
                  Gestión Servicio al Cliente (5)
                </li>
                <li style={{ listStyleType: "decimal" }}>
                  Gestión Logística y Proveedores (4)
                </li>
                <li style={{ listStyleType: "decimal" }}>Gestión Humana (3)</li>
                <li style={{ listStyleType: "decimal" }}>
                  Gestión Dirección Técnica (2)
                </li>
                <li style={{ listStyleType: "decimal" }}>
                  Gestión Administrativa y Financiera
                </li>
              </ol>
            }
          />
        </Grid>
        <Grid className="homeCard homeCard-blue" item xs={12} sm={6} md={4}>
          <HomeCard color={"font-blue"} title={"PARTES INTERESADAS"} />
        </Grid>
        <Grid className="homeCard homeCard-red" item xs={12} sm={12} md={4}>
          <HomeCard
            color={"font-red"}
            title={"INDICADORES DE CALIDAD"}
            subtitle={
              <div>
                <p className={"cardSubtitles"}>AMENAZAS (19)</p>
                <p className={"cardSubtitles"}>OPORTUNIDADES (3)</p>
              </div>
            }
            listType="decimal"
            content={
              <ol
                style={{
                  margin: " 0 0 0 -20px",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <li style={{ listStyleType: "decimal" }}>
                  Gestión Dirección (A: 7) (O: 1)
                </li>
                <li style={{ listStyleType: "decimal" }}>Gestión HSQ</li>
                <li style={{ listStyleType: "decimal" }}>
                  Gestión Comercial (A: 3) (O: 1)
                </li>
                <li style={{ listStyleType: "decimal" }}>
                  Gestión Servicio al Cliente (A: 3)
                </li>
                <li style={{ listStyleType: "decimal" }}>
                  Gestión Logística y Proveedores (A: 2)
                </li>
                <li style={{ listStyleType: "decimal" }}>
                  Gestión Humana (A: 3)
                </li>
                <li style={{ listStyleType: "decimal" }}>
                  Gestión Dirección Técnica (O: 1)
                </li>
                <li style={{ listStyleType: "decimal" }}>
                  {" "}
                  Gestión Administrativa y Financiera (A: 1)
                </li>
              </ol>
            }
          />
        </Grid>
      </div>

      <Grid className="homeCard homeCard-grey" item xs={12} sm={12} md={12}>
        <HomeCard
          color={"font-grey"}
          heigth={"100px"}
          title={
            "SISTEMA INTEGRADO DE GESTIÓN DE CALIDAD ISO 9001: 2015 - ISO 45001"
          }
          flexible={true}
        />
      </Grid>
    </div>
  );
};

export default HomePageCards;
